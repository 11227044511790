import {InputField, InputProps} from '../InputField';
import {noop} from 'lodash/fp';
import React from 'react';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';
import styled, {css} from 'styled-components';
import {StyledFC, ValidationType} from '../../types';
import {
  BoxedInputCombined,
  BoxedInputWrapper,
  BoxedInputProps,
  BoxedInputType,
  FormValidationMessageExtended,
  ValidationDecorationBorder,
  SelectBox
} from '../BoxedInput';
import {
  Dropdown,
  DropdownContent,
  DropdownContext,
  DropdownTrigger
} from '../Dropdown';
import {
  OptionItem,
  OptionList,
  OptionListItem,
  OptionListProps
} from '../OptionList';

export interface BoxedDropdownProps extends BoxedInputProps {
  /**
   * Shown before any selection.
   */
  placeholder: string;
  optionListProps: OptionListProps;
  onSelectionChange?: (item: OptionListItem) => void;
  validationType?: ValidationType;
  stickToViewPort?: boolean;
  track?: boolean;
  /**
   * For pre selection of an item. The item needs to have value property matching defaultValue.
   * If there is no match, placeholder is shown.
   */

  defaultValue?: string;
}

const ThinOptionList = styled(OptionList)`
  ${p =>
    p.items.length &&
    css`
      border: ${dimensions.borderWidthS} solid ${colors.cGray500};
    `}
  ${OptionItem} {
    /** here we add 1px to account for the top border and avoid misalignment */
    min-height: 51px;
    padding-left: ${dimensions.spaceS};
  }
  border-radius: ${dimensions.borderRadiusS};
  background: ${colors.cWhite};
  box-shadow: 0 0 ${dimensions.shadowBlurMedium} rgba(0, 0, 0, 0.2);
`;

interface BoxedOptionListProps extends OptionListProps {
  setSelected: React.Dispatch<OptionListItem>;
  onSelectionChange?: (item: OptionListItem) => void;
  validationType?: ValidationType;
}

export const BoxedOptionList: StyledFC<BoxedOptionListProps> = ({
  setSelected,
  onSelectionChange,
  items,
  validationType,
  ...optionListProps
}) => {
  const {toggle} = React.useContext(DropdownContext);

  const select = (item: OptionListItem) => {
    setSelected(item);
    onSelectionChange && onSelectionChange(item);
    toggle();
  };

  const clickableItems = items.map(item => ({
    ...item,
    onClick: () => {
      item.onClick && item.onClick();
      select(item);
    }
  }));
  return (
    <ThinOptionList
      items={clickableItems}
      validationType={validationType}
      {...optionListProps}
    />
  );
};

const _BoxedDropdown: StyledFC<BoxedDropdownProps> = ({
  className,
  dataCy,
  placeholder,
  optionListProps,
  onSelectionChange,
  validationType,
  defaultValue,
  ...boxedInputProps
}) => {
  const _defaultItem = optionListProps.items.find(
    item => defaultValue && item.value === defaultValue
  );
  const [selectedItem, setSelectedItem] = React.useState(_defaultItem || {});
  const [options, setOptions] =
    React.useState<OptionListProps>(optionListProps);

  React.useEffect(() => {
    setOptions(prev => ({
      ...prev,
      items: prev.items.map((item, idx) => ({
        ...item,
        active: item.label === selectedItem.label,
        ...(item.dataCy
          ? {dataCy: item.dataCy}
          : dataCy
          ? {dataCy: `${dataCy}-${idx}`}
          : {})
      }))
    }));
  }, [selectedItem]);
  return (
    <Dropdown
      zIndex={20}
      className={className}
      dataCy={dataCy}
      overlapTrigger
      track
      stickToViewport
    >
      <DropdownTrigger>
        <BoxedInputCombined
          {...boxedInputProps}
          dataCy={dataCy && `${dataCy}-Dropdown-Input`}
          type={BoxedInputType.select}
          value={selectedItem.label || placeholder}
          onChange={noop}
          validationType={validationType}
          iconSrc={selectedItem.iconSrc}
          IconComponent={selectedItem.IconComponent}
        />
      </DropdownTrigger>
      <DropdownContent>
        <BoxedOptionList
          {...options}
          dataCy={dataCy && `${dataCy}-Dropdown-OptionList`}
          setSelected={setSelectedItem}
          onSelectionChange={onSelectionChange}
          validationType={validationType}
        />
      </DropdownContent>
    </Dropdown>
  );
};

export const BoxedDropdown = styled(_BoxedDropdown)<Pick<InputProps, 'label'>>`
  input {
    color: ${p => p.validationType === ValidationType.error && colors.cRed700};
    background: transparent;
    padding: ${p =>
      p.label
        ? `25px ${dimensions.spaceS} ${dimensions.spaceXs}`
        : `0 ${dimensions.spaceS}`};
  }

  ${FormValidationMessageExtended} {
    display: none;
  }

  ${ValidationDecorationBorder} {
    transition: background-color 0.25s ease-in-out;
    background: ${p =>
      p.validationType === ValidationType.error && colors.cErrorBackground};
  }

  ${p =>
    p.validationType === ValidationType.error
      ? `
    &:hover ${ValidationDecorationBorder} {
      background: ${colors.cErrorBackgroundHover}`
      : `@media (hover:hover) {
        &:hover {
          background: ${colors.cGray100}
        }
  }`}

  ${BoxedInputWrapper} {
    background: transparent;
  }
  ${InputField} {
    /* to account for text misalignment when the dropdown is opened, 
    only needed for chrome and safari, in firefox it doesn't happen. */
    margin-top: -0.5px;
    @-moz-document url-prefix() {
      margin-top: 0;
    }
  }
  ${SelectBox} {
    border-color: ${p =>
      p.validationType === ValidationType.error && colors.cRed700};
    & > svg > path {
      fill: ${p => p.validationType === ValidationType.error && colors.cRed700};
    }
  }

  ${DropdownContent} {
    margin-top: ${p => p.stickToViewPort && '25px'};
  }
`;

BoxedDropdown.displayName = 'BoxedDropdown';
