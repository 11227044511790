import {IconBadge, ImageFit, Shape} from '../IconBadge';
import {OptionIcon} from '../OptionList';
import React, {useState} from 'react';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';
import styled, {css} from 'styled-components';
import {StyledFC} from '../../types';
import {addDataCy} from '../../utils';
import {
  CaretDownIcon,
  HidePasswordIcon,
  ResetIcon,
  SearchIcon,
  ShowPasswordIcon
} from '../Icons';
import {Input, InputField, InputFieldProps, Label} from '../InputField';
import {InputSpinner} from '../Spinner';
import {typeBoxBorderColorMap} from './styleMaps';
import {BoxedInputType} from './types';

export const StyledIconToggle = styled.button.attrs({type: 'button'})`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  flex: 0 0 50px;
`;

const _PasswordInput: StyledFC<InputFieldProps> = ({className, ...props}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = React.useCallback(() => {
    setShowPassword(v => !v);
  }, []);

  return (
    <div className={className}>
      <InputField type={showPassword ? 'text' : 'password'} {...props} />
      <StyledIconToggle onClick={toggleShowPassword}>
        {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
      </StyledIconToggle>
    </div>
  );
};

const PasswordInput = styled(_PasswordInput)`
  color: ${p =>
    p.validationType
      ? typeBoxBorderColorMap[p.validationType]
      : colors.cGray800};
  input[type='password'] {
    letter-spacing: 1.2px;
  }
`;

const LoadingIcon = <StyledIconToggle>{InputSpinner}</StyledIconToggle>;

const _SearchInput: StyledFC<InputFieldProps> = ({
  className,
  loading,
  value,
  onChange,
  onResetInput,
  ...props
}) => {
  const inputFieldRef = React.useRef<HTMLInputElement>(null);

  const handleSearchIconClick = () => {
    if (value) {
      if (inputFieldRef && inputFieldRef.current) {
        inputFieldRef.current.value = '';
        onChange &&
          onChange({
            target: inputFieldRef.current
          } as React.ChangeEvent<HTMLInputElement>);
        inputFieldRef.current.focus();
        onResetInput && onResetInput();
      }
    }
  };

  const SearchIconToggle = (
    <StyledIconToggle onClick={handleSearchIconClick}>
      {value ? <ResetIcon /> : <SearchIcon />}
    </StyledIconToggle>
  );

  return (
    <div className={className}>
      <InputField
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          onChange && onChange(evt);
        }}
        inputRef={inputFieldRef}
        value={value}
        {...props}
      />
      {loading ? LoadingIcon : SearchIconToggle}
    </div>
  );
};

const SearchInput = styled(_SearchInput)`
  color: ${p =>
    p.validationType
      ? typeBoxBorderColorMap[p.validationType]
      : colors.cGray800};
`;

export const SelectBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: solid 1px ${colors.cGray600};
  flex: 0 0 30px;
`;

const SelectInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;

  ${InputField} {
    flex-grow: 1;
    pointer-events: none;

    ${Input} {
      cursor: pointer;
    }

    ${Label} {
      top: 24px;
      left: ${dimensions.spaceS};
      margin-bottom: 0;
    }
  }
`;

const _SelectInput: StyledFC<InputFieldProps> = ({className, ...props}) => {
  return (
    <SelectInputWrapper className={className}>
      <InputField {...props} />
      <SelectBox>
        <CaretDownIcon />
      </SelectBox>
    </SelectInputWrapper>
  );
};

const SelectInput = styled(_SelectInput)`
  display: flex;
  height: 100%;
`;

const minifiedLabel = css`
  transform: translateY(-18px);
  font-size: ${dimensions.fontSizeXxs};
`;

export const BoxedInputWrapper = styled.div.attrs(addDataCy)<
  Pick<InputFieldProps, 'validationType'> & {hasValue: boolean}
>`
  height: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${colors.cWhite};
  ${Label} {
    font-size: ${dimensions.fontSizeM};
    line-height: ${dimensions.lineHeightXxs};
    color: ${colors.cGray600};
    font-weight: normal;
    position: absolute;
    left: ${dimensions.spaceS};
    right: initial;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    transition: 0.2s ease all;

    ${p => p.hasValue && minifiedLabel}
  }

  input {
    border: none;
    align-self: flex-end;
    width: 100%;
    height: 100%;
    padding: ${dimensions.spaceM} ${dimensions.spaceS} ${dimensions.spaceXs};
    color: ${p =>
      p.validationType
        ? typeBoxBorderColorMap[p.validationType]
        : colors.cGray800};
    &:focus ~ ${Label} {
      ${minifiedLabel}
    }
  }

  ${InputField}, ${PasswordInput} {
    height: 100%;
  }

  ${InputField} {
    flex: 1 1 auto;
    position: relative;
  }

  ${PasswordInput}, ${SearchInput} {
    display: flex;
    flex: 1;
  }
`;

export interface BoxedInputProps extends InputFieldProps {
  type?: BoxedInputType;
  iconSrc?: string;
  IconComponent?: React.FC;
}

export const BoxedInputLayoutWithIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  ${OptionIcon} {
    margin: 0 0 0 12px;
  }
  ${InputField}, ${PasswordInput}, ${SearchInput}, ${SelectInput} {
    flex: 1;
  }
`;

export const BoxedInput: StyledFC<BoxedInputProps> = ({
  type = BoxedInputType.text,
  label,
  iconSrc,
  IconComponent,
  ...rest
}) => {
  /**
   * TODO: NOTE for possible improvement:
   * we can avoid using this enum by providing input component from the outside.
   * It'd allow us to use any input component needed e.g. number or datepicker...
   */
  let InputComponent: StyledFC = () => <span>Unkown input type {type}</span>;
  if (type === BoxedInputType.text) {
    InputComponent = InputField;
  } else if (type === BoxedInputType.password) {
    InputComponent = PasswordInput;
  } else if (type === BoxedInputType.search) {
    InputComponent = SearchInput;
  } else if (type === BoxedInputType.select) {
    InputComponent = SelectInput;
  }
  return (
    <BoxedInputWrapper hasValue={!!rest.value}>
      {iconSrc || IconComponent ? (
        <BoxedInputLayoutWithIcon>
          <OptionIcon>
            <IconBadge
              shape={Shape.circular}
              imageFit={ImageFit.cover}
              imgSrc={iconSrc}
            >
              {IconComponent && !iconSrc && <IconComponent />}
            </IconBadge>
          </OptionIcon>
          <InputComponent {...rest}>
            {label && <Label>{label}</Label>}
          </InputComponent>
        </BoxedInputLayoutWithIcon>
      ) : (
        <InputComponent {...rest}>
          {label && <Label>{label}</Label>}
        </InputComponent>
      )}
    </BoxedInputWrapper>
  );
};

BoxedInput.displayName = 'BoxedInput';
