import React from 'react';
import debounce from 'lodash/debounce';

export enum StepperErrorTypes {
  wrongFormat = 'wrongFormat',
  minExceeded = 'minExceeded',
  maxExceeded = 'maxExceeded'
}

export const useStepper = (initial: number, min: number, max: number) => {
  const [value, setValue] = React.useState(initial);
  const [inputValue, setInputValue] = React.useState<string | number>(initial);
  const [error, setError] = React.useState<StepperErrorTypes | null>(null);

  const increase = () => {
    setError(null);
    const candidate = value + 1;
    const next = candidate <= max ? candidate : value;
    setValue(next);
    setInputValue(next);
  };

  const decrease = () => {
    setError(null);
    const candidate = value - 1;
    const next = candidate >= min ? candidate : value;
    setValue(next);
    setInputValue(next);
  };

  const reset = () => {
    setError(null);
    setValue(initial);
  };

  const safeSetValue = (val: string) => {
    const num = parseInt(val, 10);

    if (val.length && isNaN(num)) {
      setError(StepperErrorTypes.wrongFormat);
      return;
    }

    setError(null);
    setInputValue(val);

    debounce(v => {
      if (v < min) {
        setError(StepperErrorTypes.minExceeded);
        setValue(min);
      } else if (v > max) {
        setError(StepperErrorTypes.maxExceeded);
        setValue(max);
      } else {
        setValue(v || min);
      }
    }, 300)(num);
  };

  return {
    value,
    inputValue,
    increase,
    decrease,
    reset,
    setValue: safeSetValue,
    error
  };
};
