import React from 'react';
import styled, {css} from 'styled-components';
import {StyledFC} from 'types';
import hexToRgba from 'hex-to-rgba';
import {TileTextItem} from '../TopicTile';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';
import {addDataCy} from '../../utils';

interface CommonProps {
  context?: 'module' | 'modal';
}

export interface ModuleHeaderProps extends CommonProps {
  color: any;
  title: string;
  margin?: string;
  image?: string;
  imageSize?: number;
  objectives?: TileTextItem[];
  preknowledge?: TileTextItem[];
  tooltipHeader?: boolean;
}

const ModuleImage = styled.span<Pick<ModuleHeaderProps, 'context'>>`
  img {
    max-width: 100%;
  }
  ${p =>
    p.context === 'module'
      ? css`
          flex: 0 0 150px;
          width: 150px;
          height: 150px;
        `
      : p.context === 'modal' &&
        css`
          flex: 0 0 80px;
          width: 80px;
          height: 80px;
        `}

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${p => p.color && hexToRgba(p.color, 0.2)};
`;

export interface ModuleKnowledgeIntroProps extends CommonProps {
  objectives?: TileTextItem[];
  preknowledge?: TileTextItem[];
}

const Section = styled.section`
  display: flex;
  width: 100%;
`;

const _ModuleKnowledgeIntro: StyledFC<ModuleKnowledgeIntroProps> = ({
  className,
  objectives,
  preknowledge
}) => (
  <Section className={className}>
    {objectives && !!objectives.length && (
      <Col>
        <ModuleHeading as='h5' margin={dimensions.spaceS}>
          Das lernst du hier:
        </ModuleHeading>
        <List>
          {objectives?.map((item: any, i: number) => (
            <Item key={i}>{item.label}</Item>
          ))}
        </List>
      </Col>
    )}
    {preknowledge && !!preknowledge.length && (
      <Col>
        <ModuleHeading as='h5' margin={dimensions.spaceS}>
          Das kannst du schon:
        </ModuleHeading>
        <List>
          {preknowledge?.map((item: any, i: number) => (
            <Item key={i}>{item.label}</Item>
          ))}
        </List>
      </Col>
    )}
  </Section>
);

export const ModuleHeading = styled.span.attrs(addDataCy)<{
  border?: boolean;
  margin?: string;
}>`
  ${p =>
    p.as === 'h1'
      ? css`
          font-size: 32px;
          line-height: 36px;
          font-weight: 400;
        `
      : p.as === 'h2'
      ? css`
          font-size: 28px;
          line-height: 32px;
          font-weight: 400;
        `
      : p.as === 'h3'
      ? css`
          font-size: 24px;
          line-height: 28px;
          font-weight: 400;
        `
      : p.as === 'h4'
      ? css`
          font-size: 20px;
          line-height: 24px;
          font-weight: 400;
        `
      : p.as === 'h5'
      ? css`
          font-size: ${dimensions.fontSizeM};
          line-height: 20px;
          font-weight: 500;
        `
      : p.as === 'h6' &&
        css`
          font-size: ${dimensions.fontSizeM};
          line-height: 20px;
          font-weight: 400;
        `}

  margin: 0 0 ${p => p.margin || '0'};

  ${p =>
    p.border &&
    css`
      border-bottom: 1px solid #ccc;
      padding-bottom: 12px;
    `}
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const List = styled.ul`
  margin: 0;
  padding: 0 0 0 ${dimensions.spaceL};
  line-height: 26px;
`;
const Item = styled.li``;

const HeaderSection = styled(Section)`
  align-items: center;

  @media (min-width: 576px) {
    ${ModuleImage} + ${ModuleHeading} {
      margin: 0 0 0 20px;
    }
  }
  @media (max-width: 575px) {
    flex-direction: column;
    ${ModuleImage} + ${ModuleHeading} {
      margin: 20px 0 0;
    }
  }
`;

export const ModuleKnowledgeIntro = styled(_ModuleKnowledgeIntro)`
  ${p =>
    p.context === 'module'
      ? css`
          ${Col} + ${Col} {
            @media (min-width: 768px) {
              margin: 0 0 0 20px;
            }
            @media (max-width: 767px) {
              margin: 20px 0 0;
            }
          }
        `
      : p.context === 'modal' &&
        css`
          flex-direction: column;
          ${Col} + ${Col} {
            margin: 20px 0 0;
          }
        `};

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const _ModuleHeader: StyledFC<ModuleHeaderProps> = ({
  className,
  color,
  image,
  title,
  objectives,
  preknowledge,
  context
}) => {
  return (
    <header className={className}>
      <HeaderSection>
        {image && (
          <ModuleImage context={context} color={color}>
            <img src={image} alt={title} />
          </ModuleImage>
        )}
        <ModuleHeading as={(context === 'modal' && 'h4') || 'h1'}>
          {title}
        </ModuleHeading>
      </HeaderSection>

      {(objectives || preknowledge) && (
        <ModuleKnowledgeIntro
          objectives={objectives}
          preknowledge={preknowledge}
          context={context}
        />
      )}
    </header>
  );
};

export const ModuleHeader = styled(_ModuleHeader)`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  ${p =>
    p.margin &&
    css`
      margin-bottom: ${p.margin};
    `}
  ${p =>
    p.tooltipHeader &&
    css`
      ${ModuleHeading} {
        font-size: 16px;
        line-height: 22px;
      }
    `};

  section + section {
    margin-top: ${dimensions.spaceXl};
  }

  padding: ${p =>
    p.context === 'module' ? `${dimensions.spaceXl} ${dimensions.spaceM}` : 0};

  ${p =>
    p.context === 'module' &&
    css`
      padding: ${dimensions.spaceXl} ${dimensions.spaceM};
      box-shadow: inset 0px -2px 0px ${colors.cGray400};
    `};
`;
