import styled from 'styled-components';
import dimensions from '../../sass/dimensions';
import {IconPosition} from './types';

export const LinkIcon = styled.span<{iconPosition: IconPosition}>`
  display: inline-block;
  margin-right: ${p =>
    p.iconPosition == IconPosition.left ? dimensions.spaceXs : 0};
  margin-left: ${p =>
    p.iconPosition == IconPosition.right ? dimensions.spaceXs : 0};
`;
