import React from 'react';
import styled from 'styled-components';
import dimensions from '../../sass/dimensions';
import {StyledButton, StyledButtonProps} from './Button';
import {ButtonKind, ButtonStretch} from './types';
import {StyledFC} from '../../types';

export interface ButtonWrapperProps {
  /**
   * When set, the button uses the full available width.
   * @default false
   */
  stretch?: ButtonStretch;
  /**
   * If true, the size is determined by the size of the label or icon.
   * The `size` is only used for the clickable area (absolute positioning!).
   * @default false
   */
  collapseSpace?: boolean;
  buttonRef?: any;
}

const applyStretchStyling = ({stretch}: ButtonWrapperProps) =>
  stretch === ButtonStretch.stretch
    ? `
    display: flex;
    ${StyledButton} {
      flex-grow: 1;
    }
  `
    : stretch === ButtonStretch.responsive
    ? `
    display: flex;
    ${StyledButton} {
      flex-grow: 1;
    }
    
    @media (min-width: ${dimensions.verySmallScreenWidth}) {
      display: inline-flex;
      
      ${StyledButton} {
        flex-grow: 0;
      }
    }
  `
    : '';

const applySpaceStyling = ({collapseSpace}: ButtonWrapperProps) =>
  collapseSpace
    ? `
  margin: 0;
  ${StyledButton}:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    margin: 0;
    height: ${dimensions.minClickableHeight};
    transform: translateY(-50%);
    pointer-events: all;
  }
  `
    : `
  min-height: ${dimensions.minClickableHeight};
  align-items: center;
  `;

export type ButtonProps = StyledButtonProps & ButtonWrapperProps;

/**
 * Implementation of the disabling behavior for buttons:
 * You can be in full control using the `disabled` property: it overrides everything else.
 * If you don't set `disabled` the state is derived from the values of `kind` and `onClick`:
 * Not passing `onClick` (or passing false) renders the button as disabled,
 * explicitly setting `kind` to `ButtonKind.disabled` of course always wins.
 */
export const isDisabled = (
  props: Readonly<Pick<ButtonProps, 'disabled' | 'kind' | 'onClick'>>
): boolean => {
  if (props.disabled !== undefined) return props.disabled;
  if (props.kind === ButtonKind.disabled) return true;
  return !props.onClick;
};

const ButtonBase: StyledFC<ButtonProps> = props => {
  const disabled = isDisabled(props);
  const {
    className,
    buttonRef,
    onClick,
    id,
    dataCy,
    type,
    size,
    background,
    children,
    useExtendedPadding,
    useButtonTransition,
    disableFocusOutline = true
  } = props;

  return (
    <div ref={buttonRef} className={className}>
      <StyledButton
        disabled={disabled}
        onClick={onClick}
        kind={disabled ? ButtonKind.disabled : props.kind}
        id={id}
        dataCy={dataCy}
        type={type}
        size={size}
        background={background}
        useExtendedPadding={useExtendedPadding}
        useButtonTransition={useButtonTransition}
        disableFocusOutline={disableFocusOutline}
      >
        {children}
      </StyledButton>
    </div>
  );
};

export const Button = styled(ButtonBase)`
  display: inline-flex;
  cursor: pointer;
  vertical-align: middle;
  position: relative;

  ${applyStretchStyling}
  ${applySpaceStyling}
`;
