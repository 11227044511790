import {FlattenInterpolation} from 'styled-components';
import {Stretch} from '../types';

export const applyStyleIf =
  (condition: boolean) =>
  (strings: TemplateStringsArray, ...values: Array<any>): string =>
    condition
      ? strings.reduce(
          (result, string, i) => `${result}${string}${values[i]}`,
          ''
        )
      : '';

export const handleHover = (
  content: string | FlattenInterpolation<any>,
  component: any = ''
) => `
  cursor: pointer;
  @media (hover: hover) {
    &:hover ${component} {
      ${content}
    }
  }
`;

export const handleActive = (content: string, component: any = '') => `
  @media (hover: hover) {
    &:active ${component} {
      ${content}
    }
  }
`;

export const getSafeStyling =
  (propName: string) =>
  (value?: any): string =>
    value === undefined ? '' : `${propName}: ${value};`;

// calling this function means the enum is used as well, so reexport to make it convenient to import
export {Stretch} from '../types';
export const getStretchCssValue = (value: Stretch | number) => {
  switch (value) {
    case Stretch.auto:
      return 'auto';
    case Stretch.stretched:
      return '100%';
    default:
      return `${value}px`;
  }
};
