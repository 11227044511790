import {css, FlattenInterpolation} from 'styled-components';
import {compose} from 'lodash/fp';
import colors from '../../../sass/colors';
import {ButtonKind} from '../types';
import {StyledButtonProps} from '../Button';
import {
  getBackgroundType,
  isBorder,
  isNotTransparent,
  isTransparent,
  or,
  orEmpty
} from './utils';

const applyTransparentCss = compose(
  orEmpty(`background-color: ${colors.btnTransparencyHover};`),
  isTransparent,
  getBackgroundType
);

const borderAndBackGroundCss = css`
  border-color: var(--border-and-background-color);
  background-color: var(--border-and-background-color);
`;

const handleHover = (
  inner: FlattenInterpolation<any>
) => css<StyledButtonProps>`
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      ${inner}
    }
  }
`;

const applyCssForBorderType = (css: string) =>
  compose(orEmpty(css), isBorder, getBackgroundType);

interface ColorSchema {
  color: string;
  borderBackground: string;
  filledBackground?: string;
}

const getDecorationForColorSchema = ({
  color,
  borderBackground,
  filledBackground = borderBackground
}: ColorSchema) =>
  handleHover(css<StyledButtonProps>`
    --border-and-background-color: ${compose(
      or(borderBackground, filledBackground),
      isBorder,
      getBackgroundType
    )};

    ${compose(
      orEmpty(borderAndBackGroundCss),
      isNotTransparent,
      getBackgroundType
    )}
    ${applyCssForBorderType(`color: ${color};`)}
    ${applyCssForBorderType(`fill: ${color};`)}
    ${applyTransparentCss}
  `);

const decorationMap: Record<
  ButtonKind,
  FlattenInterpolation<StyledButtonProps>
> = {
  [ButtonKind.abort]: getDecorationForColorSchema({
    color: colors.cWhite,
    borderBackground: colors.cRed600,
    filledBackground: colors.cRed700
  }),
  [ButtonKind.action]: getDecorationForColorSchema({
    color: colors.cWhite,
    borderBackground: colors.cBlue600,
    filledBackground: colors.cBlue700
  }),
  [ButtonKind.confirm]: getDecorationForColorSchema({
    color: colors.cWhite,
    borderBackground: colors.cGreen600,
    filledBackground: colors.cGreen700
  }),
  [ButtonKind.disabled]: css`
    cursor: default;
  `,
  [ButtonKind.final]: getDecorationForColorSchema({
    color: colors.cGray800,
    borderBackground: colors.cOrange600,
    filledBackground: colors.cOrange700
  }),
  [ButtonKind.view]: getDecorationForColorSchema({
    color: colors.cWhite,
    borderBackground: colors.cGray600,
    filledBackground: colors.cGray700
  }),
  [ButtonKind.warning]: getDecorationForColorSchema({
    color: colors.cGray800,
    borderBackground: colors.cYellow600
  })
};

export const applyDecoration = ({kind}: StyledButtonProps) =>
  decorationMap[kind];
