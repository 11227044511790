import styled from 'styled-components';
import colors from '../../sass/colors';
import {addDataCy} from '../../utils';
import dimensions from '../../sass/dimensions';

export const CodeBox = styled.div.attrs(addDataCy)`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: ${dimensions.fontWeightBold};
  font-size: ${dimensions.fontSizeXxl};
  color: ${colors.cGray800};
  letter-spacing: 2.29px;
  text-align: center;
  line-height: 25px;
  border-radius: ${dimensions.borderRadiusM};
  background-color: ${colors.cGray200};
  display: inline-block;
  padding: ${dimensions.spaceS};
  margin-right: ${dimensions.spaceS};
`;
