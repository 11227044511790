import isFunction from 'lodash/isFunction';
import React from 'react';
import dimensions from '../../sass/dimensions';
import styled, {css} from 'styled-components';
import {StyledFC} from '../../types';
import {
  applyActiveDecoration,
  applyColorDecoration,
  applyHoverDecoration,
  applySizeDecoration
} from './decorations';
import {ButtonBackground, ButtonKind, ButtonSize} from './types';

interface CommonButtonProps {
  /**
   * Coloring of button that indicates the kind of user action.
   * Can be overridden to be `ButtonKind.disabled`,
   * depending on the values of `disabled` and `onClick`.
   *
   * @see CommonButtonProps.disabled
   * @see StyledButtonProps.onClick
   */
  kind: ButtonKind;
  /**
   * Works as expected from HTML:
   * - Overrides the `kind` prop to be `ButtonKind.disabled` if set to true.
   * - Prevents the `onClick` handler from being attached
   *
   * When this property is not set, there is the following behavior:
   * If there is no `onClick` handler configured,
   * the button is being rendered with 'ButtonKind.disabled`.
   *
   * @see CommonButtonProps.kind
   * @see StyledButtonProps.onClick
   */
  disabled?: boolean;
  /**
   * Button Id
   */
  id?: string;
  /**
   * Determines the size and spacing of the button.
   * @default 'm'
   */
  size?: ButtonSize;
  /**
   * Where to apply button area color.
   * @default 'filled'
   */
  background?: ButtonBackground;
  /**
   * CR: add option to have an extended padding of 12px on the left & right
   */
  useExtendedPadding?: boolean;
  /**
   * CR: add option for button transitions
   */
  useButtonTransition?: boolean;
}

export interface StyledButtonProps extends CommonButtonProps {
  /**
   * Event handler (the button is disabled when none is passed).
   * `false` is here to support short circuit boolean expressions like the following:
   * `<Button onClick={isCond && handleClick} />`
   *
   * @see CommonButtonProps.disabled
   * @see CommonButtonProps.kind
   */
  onClick?: false | ((evt: React.MouseEvent<any>) => void) | (() => void);

  type?: 'button' | 'submit' | 'reset';

  disableFocusOutline?: boolean;

  dialog?: boolean;
}

export interface LinkButtonProps extends CommonButtonProps {
  /**
   * for the href attribute
   */
  href: string;
  /**
   * whether the url should open a new window/tab or not
   */
  external?: boolean;
}

export const ChildrenWrapper = styled.span`
  padding: 0 ${dimensions.btnPaddingTiny};

  svg {
    vertical-align: middle;
  }
`;

const Button: StyledFC<StyledButtonProps> = ({
  children,
  className,
  dataCy,
  disabled,
  id,
  onClick,
  type
}) => (
  <button
    id={id}
    data-cy={dataCy}
    className={className}
    onClick={onClick ? onClick : undefined}
    type={type}
    disabled={disabled}
  >
    {React.isValidElement(children) || !isFunction(children) ? (
      <ChildrenWrapper>{children}</ChildrenWrapper>
    ) : (
      children(ChildrenWrapper)
    )}
  </button>
);

const Link: StyledFC<LinkButtonProps> = ({
  href,
  external,
  id,
  dataCy,
  className,
  children
}) => (
  // eslint-disable-next-line react/jsx-no-target-blank
  <a
    href={href}
    target={external ? '_blank' : undefined}
    id={id}
    data-cy={dataCy}
    className={className}
    rel={external ? 'noreferrer external' : undefined}
  >
    {React.isValidElement(children) || !isFunction(children) ? (
      <ChildrenWrapper>{children}</ChildrenWrapper>
    ) : (
      children(ChildrenWrapper)
    )}
  </a>
);

const BUTTON_STYLES = css`
  box-sizing: border-box;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: ${dimensions.borderWidthM} solid transparent;
  border-radius: ${dimensions.borderRadiusM};
  background-color: transparent;
  cursor: inherit;
  white-space: nowrap;
  ${applyColorDecoration}
  ${applyHoverDecoration}
  ${applyActiveDecoration}
  ${applySizeDecoration(ChildrenWrapper)}
  ${p =>
    p.useExtendedPadding &&
    css`
      padding-left: ${dimensions.btnPaddingExtended};
      padding-right: ${dimensions.btnPaddingExtended};
    `}
    
  ${p =>
    p.useButtonTransition &&
    css`
      transition: background-color 0.25s ease-in-out, border 0.25s ease-in-out;
    `}
`;

export const StyledButton = styled(Button)`
  ${BUTTON_STYLES};
  min-width: ${props =>
    props.dialog
      ? dimensions.dialogButtonMinWidth
      : dimensions.minClickableWidth};

  ${p => (p.disableFocusOutline ? ':focus { outline: none; }' : '')}
`;

export const LinkButton: StyledFC<LinkButtonProps> = styled(Link)`
  ${BUTTON_STYLES};
  text-decoration: none;
`;
