export const getContentOffsetX = (
  triggerRect?: ClientRect,
  contentRect?: ClientRect
): number => {
  if (!triggerRect || !contentRect) {
    return 0;
  }

  if (triggerRect.width > contentRect.width) {
    return (triggerRect.width - contentRect.width) / 2;
  } else {
    return 0;
  }
};

export const getContentOffsetY = (
  triggerRect?: ClientRect,
  contentRect?: ClientRect,
  parentElement?: HTMLElement | null,
  scrollContainerElement?: HTMLElement | null
): number => {
  if (!triggerRect || !contentRect) {
    return 0;
  }

  const triggerTop =
    triggerRect.top -
    (scrollContainerElement?.getBoundingClientRect().top ?? 0);

  const triggerBottom = Math.abs(
    triggerRect.bottom -
      (scrollContainerElement?.getBoundingClientRect().bottom ?? 0)
  );

  const neededSpace = (contentRect.height - triggerRect.height) / 2;

  if (neededSpace > triggerTop) {
    return 0;
  } else if (neededSpace > triggerBottom) {
    return -(contentRect.height - triggerRect.height);
  } else {
    return -neededSpace;
  }
};
