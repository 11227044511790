import React from 'react';
import colors from '../../sass/colors';

export const DocumentPreviewIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  fill
}) => (
  <svg
    width='23'
    height='28'
    viewBox='0 0 23 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 0H15.1667L22.1667 7V28H0V0ZM20.5158 7L15.1667 1.65083V7H20.5158ZM1.16667 26.8333H21V8.16667H14V1.16667H1.16667V26.8333ZM15.2585 14.9275C15.411 16.2429 15.0619 17.5674 14.2808 18.6367L18.7568 23.1127L17.593 24.2715L13.1171 19.7955C12.0444 20.572 10.7184 20.9154 9.40362 20.7574C8.08888 20.5993 6.88209 19.9513 6.02405 18.9427C5.16601 17.934 4.71982 16.639 4.77451 15.3159C4.82921 13.9928 5.38077 12.739 6.31913 11.8047C7.25749 10.8703 8.51365 10.3241 9.83696 10.2751C11.1603 10.2261 12.4534 10.6778 13.4583 11.5401C14.4633 12.4025 15.1061 13.6121 15.2585 14.9275ZM7.79611 18.9466C8.4643 19.3931 9.24988 19.6314 10.0535 19.6314C10.5871 19.6314 11.1154 19.5263 11.6084 19.3221C12.1014 19.1179 12.5493 18.8186 12.9266 18.4413C13.3039 18.064 13.6032 17.6161 13.8074 17.1231C14.0116 16.6302 14.1167 16.1018 14.1167 15.5682C14.1167 14.7646 13.8784 13.979 13.4319 13.3108C12.9854 12.6426 12.3509 12.1219 11.6084 11.8143C10.866 11.5068 10.049 11.4263 9.26081 11.5831C8.47263 11.7399 7.74864 12.1269 7.18039 12.6951C6.61215 13.2634 6.22517 13.9873 6.06839 14.7755C5.91161 15.5637 5.99207 16.3807 6.29961 17.1231C6.60714 17.8656 7.12793 18.5002 7.79611 18.9466Z'
      fill={fill || `${colors.cBlue700}`}
    />
  </svg>
);
