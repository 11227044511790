import {AnimationVariants} from '../../types';

export const variants = {
  [AnimationVariants.hidden]: {
    opacity: 0,
    height: 0
  },
  [AnimationVariants.visible]: {
    opacity: 1,
    height: 'auto'
  }
};

export const transition = {duration: 0.5};
