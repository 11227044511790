import React from 'react';
import {getContentOffsetX, getContentOffsetY} from './utils';

export enum StickDirection {
  left = 'left',
  right = 'right',
  bottom = 'bottom',
  top = 'top',
  middle = 'middle'
}

interface UseTooltipLogicParameters {
  isActive?: boolean;
  toggleCallback?: () => void;
  parentRef?: React.RefObject<HTMLElement>;
  scrollContainerRef?: React.RefObject<HTMLElement>;
  triggerRef: React.RefObject<HTMLDivElement>;
  contentRef: React.RefObject<HTMLDivElement>;
  contentStickTo: StickDirection;
}

interface UseTooltipLogicParametersResult {
  contentOffsetX: number;
  contentOffsetY: number;
  toggle: () => void;
  isContentOpen: boolean;
  triggerWidth: number;
}

export const useTooltipHelper = ({
  toggleCallback,
  triggerRef,
  contentRef,
  parentRef,
  scrollContainerRef,
  contentStickTo,
  isActive = false
}: UseTooltipLogicParameters): UseTooltipLogicParametersResult => {
  const [contentOffsetX, setContentOffsetX] = React.useState(0);
  const [contentOffsetY, setContentOffsetY] = React.useState(0);
  const [triggerWidth, setTriggerWidth] = React.useState(0);
  const [isContentOpen, setIsContentOpen] = React.useState(isActive);

  React.useEffect(() => {
    if (!isActive) {
      setIsContentOpen(false);
    }
  }, [isActive]);

  const toggleCalculation = React.useCallback(async () => {
    const triggerRect = triggerRef.current?.getBoundingClientRect();
    setTimeout(() => {
      const newContentOffsetX = getContentOffsetX(
        triggerRect,
        contentRef.current?.getBoundingClientRect()
      );
      setTriggerWidth(triggerRect ? triggerRect.width : 0);
      setContentOffsetX(newContentOffsetX);

      setContentOffsetY(
        contentStickTo === StickDirection.middle
          ? getContentOffsetY(
              triggerRef.current?.getBoundingClientRect(),
              contentRef.current?.getBoundingClientRect(),
              parentRef?.current,
              scrollContainerRef?.current
            )
          : 0
      );
    }, 100);
    setIsContentOpen(v => !v);
    if (toggleCallback) {
      toggleCallback();
    }
  }, [toggleCallback]);

  return {
    contentOffsetX,
    contentOffsetY,
    toggle: toggleCalculation,
    isContentOpen,
    triggerWidth
  };
};
