export * from './animation';
export * from './react-ext';

export enum Stretch {
  auto = 'auto',
  stretched = 'stretched'
}

export enum FlexDirection {
  row = 'row',
  column = 'column'
}

export enum FlexWrap {
  WRAP = 'wrap',
  NOWRAP = 'nowrap'
}

export enum FlexAlign {
  flexStart = 'flex-start',
  center = 'center',
  flexEnd = 'flex-end'
}

export enum JustifyContent {
  spaceAround = 'space-around',
  spaceBetween = 'space-between',
  flexStart = 'flex-start',
  flexEnd = 'flex-end',
  center = 'center'
}

export interface ZIndexProps {
  zIndex?: number;
}
export enum ValidationType {
  action = 'action',
  success = 'success',
  warning = 'warning',
  error = 'error'
}

export enum ValidationSize {
  s = 's',
  m = 'm',
  l = 'l'
}

export enum ModalSize {
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl'
}

export enum BorderDirection {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom'
}
