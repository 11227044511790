export enum BorderThickness {
  THICK = 'thick',
  THIN = 'thin'
}

export enum BarStyle {
  DEFAULT = 'default',
  LIGHT = 'light',
  DARK = 'dark'
}

export enum Orientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}
