import {css} from 'styled-components';
import dimensions from '../../../sass/dimensions';
import {LinkSize} from '../types';
import {LinkProps} from '../types';

interface SizeSchema {
  fontSize: string;
  lineHeight: string;
}

const getStylingForSizeSchema = ({fontSize, lineHeight}: SizeSchema) => css`
  font-size: ${fontSize};
  line-height: ${lineHeight};
`;

const sizeMap: Record<LinkSize, any> = {
  [LinkSize.m]: getStylingForSizeSchema({
    fontSize: dimensions.fontSizeM,
    lineHeight: dimensions.lineHeightM2
  }),
  [LinkSize.s]: getStylingForSizeSchema({
    fontSize: dimensions.fontSizeS,
    lineHeight: dimensions.lineHeightXs
  }),
  [LinkSize.xs]: getStylingForSizeSchema({
    fontSize: dimensions.fontSizeXs,
    lineHeight: dimensions.lineHeightXxs
  }),
  [LinkSize.xxs]: getStylingForSizeSchema({
    fontSize: dimensions.fontSizeXxs,
    lineHeight: dimensions.lineHeightXxxs
  })
};

export const applyDecoration =
  () =>
  ({size}: LinkProps) =>
    sizeMap[size || LinkSize.m];
