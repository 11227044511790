import {motion} from 'framer-motion';
import styled, {FlattenInterpolation} from 'styled-components';
import dimensions from '../../sass/dimensions';
import {addDataCy} from '../../utils';
import {ValidationType, PropsWithStyledProps} from '../../types';
import {typeBorderColorMap} from './styleMaps';
import {FormValidationMessageExtended} from './FormValidationMessageExtended';

export type ValidationDecorationBorderProps = PropsWithStyledProps<{
  validationType?: ValidationType;
  borderWidth?: string;
  borderRadius?: string;
  customCss?: FlattenInterpolation<ValidationDecorationBorderProps> | string;
}>;

export const ValidationDecorationBorder = styled(motion.div).attrs(
  addDataCy
)<ValidationDecorationBorderProps>`
  border-style: solid;
  border-width: ${p => p.borderWidth || dimensions.borderWidthS};
  border-radius: ${p => p.borderRadius || dimensions.borderRadiusS};
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  ${FormValidationMessageExtended} {
    box-shadow: inset 0 ${dimensions.borderWidthM} 0 0
      ${p => typeBorderColorMap[p.validationType || ValidationType.error]};
  }

  ${p => p.customCss}
`;
