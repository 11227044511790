import defaultTheme from '../../themes/defaultTheme';
import {numberFromStyles} from '../../utils/numberFromStyles';

export const PICKER_WIDGET_PADDING = numberFromStyles('4px');
export const PICKER_WIDGET_BORDER_RADIUS = numberFromStyles('8px');
export const PICKER_WIDGET_BACKGROUND_COLOR = defaultTheme.colors.cGray300;
export const PICKER_WIDGET_BACKGROUND_HOVER_COLOR =
  defaultTheme.colors.cGray400;
export const NOSE_SIZE = '8px';

export const STEPPER_BUTTON_SIZE = numberFromStyles('40px');
export const STEPPER_BUTTON_SIZE_80PCT = STEPPER_BUTTON_SIZE * 0.8;
export const STEPPER_BUTTON_BORDER_RADIUS = '4px';
export const STEPPER_BUTTON_COLOR = defaultTheme.colors.cGray100;
export const STEPPER_BUTTON_HOVER_COLOR = defaultTheme.colors.cGray200;
export const STEPPER_BUTTON_ACTIVE_COLOR = defaultTheme.colors.cGray100;

export const STEPPER_CONFIRM_BUTTON_BACKGROUND_COLOR =
  defaultTheme.colors.cGreen600;
export const STEPPER_CONFIRM_BUTTON_ACTIVE_BACKGROUND_COLOR =
  defaultTheme.colors.cGreen500;
export const STEPPER_CONFIRM_BUTTON_HOVER_BACKGROUND_COLOR =
  defaultTheme.colors.cGreen700;

export const STEPPER_DELETE_BUTTON_BACKGROUND_COLOR =
  defaultTheme.colors.cRed600;
export const STEPPER_DELETE_BUTTON_ACTIVE_BACKGROUND_COLOR =
  defaultTheme.colors.cRed500;
export const STEPPER_DELETE_BUTTON_HOVER_BACKGROUND_COLOR =
  defaultTheme.colors.cRed700;

export const LABEL_CONTAINER_FONT_SIZE = defaultTheme.dimensions.fontSizeXxl;
