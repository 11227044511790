import React from 'react';
import styled, {css} from 'styled-components';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';
import {AnimationVariants, StyledFC} from '../../types';
import {addDataCy} from '../../utils';
import {FormValidationMessage} from '../FormValidationMessage/FormValidationMessage';
import {
  InputFieldProps,
  InputFieldValidationMessageProps,
  InputProps
} from './types';
import {applyDecoration} from './decorations';
import {transition, variants} from './animations';

const InputIconWrapper = styled.div<{position: 'left' | 'right'}>`
  position: absolute;
  ${p =>
    p.position === 'left'
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `}
  bottom: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  flex: 0 0 50px;
`;

export const Input = styled.input.attrs(addDataCy)<InputProps>`
  height: 40px;
	border: 1px solid ${colors.cGray600};
	border-radius: 2px;
	padding: 0 ${dimensions.spaceS};
  ${p =>
    p.$leftIcon &&
    css`
      padding-left: 40px;
    `}
  ${p =>
    p.$rightIcon &&
    css`
      padding-right: 40px;
    `}
	font-size: ${dimensions.fontSizeM};
	outline: none; //disable chrome default focus behavior;
	transition: border-color .25s ease-in-out, color .25s ease-in-out;
	&:not(disabled):focus {
	border-color: ${p => (p.disabled ? colors.cGray400 : colors.cBlue600)};

	&::placeholder {
		color: ${p => (p.label || p.disabled ? colors.cGray400 : colors.cGray700)};
	}

	${applyDecoration}
`;

Input.displayName = 'Input';

export const Label = styled.label`
  font-size: ${dimensions.fontSizeXs};
  font-weight: bold;
  margin-bottom: ${dimensions.spaceXxs};
`;

Label.displayName = 'Label';

const InputFieldBase: StyledFC<InputFieldProps> = props => {
  const {
    onChange,
    onFocus,
    onBlur,
    children,
    className,
    dataCy,
    disabled,
    label,
    placeholder,
    type = 'text',
    value,
    name,
    validationType,
    inputRef,
    autoFocus = false,
    leftIcon,
    rightIcon
  } = props;

  return (
    <div className={className}>
      {label && <Label>{label}</Label>}
      <Input
        ref={inputRef}
        value={value}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        validationType={validationType}
        autoFocus={autoFocus}
        dataCy={dataCy}
        $leftIcon={leftIcon}
        $rightIcon={rightIcon}
      />
      {leftIcon && (
        <InputIconWrapper position='left'>{leftIcon}</InputIconWrapper>
      )}
      {rightIcon && (
        <InputIconWrapper position='right'>{rightIcon}</InputIconWrapper>
      )}
      {children}
    </div>
  );
};

export const InputField = styled(InputFieldBase)`
  &,
  & * {
    box-sizing: border-box;
  }
  position: relative;
  display: flex;
  flex-direction: column;
`;

InputField.displayName = 'InputField';

export const InputFieldValidationMessage: StyledFC<InputFieldValidationMessageProps> =
  ({show, validationType, children, dataCy}) => (
    <FormValidationMessage
      validationType={validationType}
      variants={variants}
      initial={AnimationVariants.hidden}
      animate={show ? AnimationVariants.visible : AnimationVariants.hidden}
      transition={transition}
      dataCy={dataCy}
    >
      {children}
    </FormValidationMessage>
  );

InputFieldValidationMessage.displayName = 'InputFieldValidationMessage';
