import styled from 'styled-components';
import {motion} from 'framer-motion';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';
import {ValidationType, ValidationSize} from '../../types';
import {addDataCy} from '../../utils';

export enum ExtendedType {
  standalone = 'standalone',
  boxed = 'boxed'
}

export interface FormValidationMessageProps {
  validationType?: ValidationType;
  type?: ExtendedType;
  size?: ValidationSize;
}

const typeColorMap: Record<ValidationType, string> = {
  [ValidationType.action]: colors.cBlue700,
  [ValidationType.success]: colors.cGreen700,
  [ValidationType.warning]: colors.cOrange700,
  [ValidationType.error]: colors.cRed700
};

export const formValidationFontSizeMap: Record<ValidationSize, string> = {
  [ValidationSize.s]: dimensions.fontSizeXs,
  [ValidationSize.m]: dimensions.fontSizeS,
  [ValidationSize.l]: dimensions.fontSizeM
};

export const formValidationLineHeightMap: Record<ValidationSize, string> = {
  [ValidationSize.s]: dimensions.lineHeightS,
  [ValidationSize.m]: dimensions.lineHeightM,
  [ValidationSize.l]: dimensions.lineHeightM2
};

export const FormValidationMessage = styled(motion.div).attrs(
  addDataCy
)<FormValidationMessageProps>`
  color: ${p => typeColorMap[p.validationType || ValidationType.error]};
  font-size: ${p => formValidationFontSizeMap[p.size || ValidationSize.s]};
  line-height: ${p => formValidationLineHeightMap[p.size || ValidationSize.s]};
`;
