import styled, {css} from 'styled-components';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';

export enum Shape {
  rounded = 'rounded',
  circular = 'circular'
}

export enum ImageFit {
  contain = 'contain',
  cover = 'cover',
  fill = 'fill',
  none = 'none'
}

export interface IconBadgeProps {
  shape?: Shape;
  imageFit?: ImageFit;
  imgSrc?: string;
  size?: number;
}

export const IconBadge = styled.div<IconBadgeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1px white;
  border: 1px solid ${colors.cGray400};
  ${p =>
    p.imgSrc
      ? css`
          background-image: url(${p.imgSrc});
          background-repeat: no-repeat;
          background-position: center;
        `
      : css`
          overflow: hidden;
          position: relative;
          z-index: 0;
          img {
            z-index: -1;
            max-width: 100%;
          }
        `};
  &,
  & img {
    width: ${p => (p.size || 32) + 'px'};
    height: ${p => (p.size || 32) + 'px'};
  }
  img {
    object-fit: ${p => {
      switch (p.imageFit) {
        case ImageFit.contain:
          return 'contain';
        case ImageFit.cover:
          return 'cover';
        case ImageFit.fill:
          return 'fill';
        case ImageFit.none:
          return 'none';
        default:
          return 'cover';
      }
    }};
  }
  background-size: ${p => {
    switch (p.imageFit) {
      case ImageFit.contain:
        return 'contain';
      case ImageFit.cover:
        return 'cover';
      case ImageFit.fill:
        return 'fill';
      case ImageFit.none:
        return 'none';
      default:
        return 'cover';
    }
  }};
  ${p => {
    switch (p.shape) {
      case Shape.rounded:
        return css`
          border-radius: ${dimensions.spaceXxs};
        `;
      case Shape.circular:
        return css`
          border-radius: 50%;
        `;
      default:
        return '';
    }
  }}
`;
