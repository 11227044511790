import styled from 'styled-components';
import colors from '../../sass/colors';
import {applyDecoration} from './decorations/sizeDecorations';
import {LinkProps} from './types';
import {addDataCy} from '../../utils';

export const Link = styled.a.attrs(addDataCy)<LinkProps>`
  cursor: pointer;
  text-decoration: none;
  transition: color, stroke, 0.25s ease-in-out;

  color: ${p => p.customColors?.normal ?? colors.cBlue600};

  &:hover {
    color: ${p => p.customColors?.hover ?? colors.cBlue800};
    path {
      fill: ${p => p.customColors?.hover ?? colors.cBlue800};
    }
  }

  ${applyDecoration}
`;

Link.displayName = 'Link';
