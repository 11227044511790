import React from 'react';
import styled from 'styled-components';
import {StyledFC} from 'types';
import hexToRgba from 'hex-to-rgba';

import dimensions from '../../sass/dimensions';
import colors from '../../sass/colors';
import {Pattern} from './Pattern';

export interface ThematicBannerProps {
  title: string;
  image: string;
  width: number;
  height: number;
  pattern?: string;
  color?: string;
}

const Headline = styled.h1`
  font-size: 36px;
  line-height: 44px;
  font-weight: normal;
  margin: 0;
  padding: 0;
  flex: 1 1;
`;

const _ThematicBanner: StyledFC<ThematicBannerProps> = ({
  className,
  title,
  image,
  width,
  height
}) => {
  return (
    <div className={className}>
      <Headline>{title}</Headline>
      {image && <img src={image} alt={title} width={width} height={height} />}
    </div>
  );
};

export const ThematicBanner = styled(_ThematicBanner)`
  img {
    max-width: 100%;
  }
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background: linear-gradient(
      270deg,
      ${colors.cGray400} 0%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${dimensions.spaceM};
  background: linear-gradient(
      90deg,
      #ffffff 25.61%,
      rgba(255, 255, 255, 0) 100.34%
    ),
    linear-gradient(
      0deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.78) 17%,
      rgba(255, 255, 255, 0.5) 40%,
      rgba(255, 255, 255, 0.29) 60%,
      rgba(255, 255, 255, 0.13) 78%,
      rgba(255, 255, 255, 0.04) 92%,
      rgba(255, 255, 255, 0) 100%
    ),
    url('data:image/png;base64,${Pattern}'),
    ${p => (p.color && hexToRgba(p.color, 0.45)) || 'rgba(238, 102, 68, 0.2)'};
  background-blend-mode: normal, normal, screen, normal;
`;
