import React from 'react';

export const AngleRightIcon: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg
    width='8'
    height='14'
    viewBox='0 0 8 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M1 1L7 7L1 13' stroke='white' strokeMiterlimit='10' />
  </svg>
);
