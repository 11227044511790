import {css, FlattenInterpolation} from 'styled-components';
import colors from '../../sass/colors';
import {InputProps} from './types';
import {ValidationType} from '../../types';

const decorate = (primaryColor: string, secondaryColor: string) => css`
  color: ${primaryColor};
  border-color: ${primaryColor};
  &:focus {
    border-color: ${secondaryColor};
  }
  &::placeholder {
    color: ${primaryColor};
  }
`;

const decorationMap: Record<ValidationType, FlattenInterpolation<any>> = {
  [ValidationType.action]: decorate(colors.cBlue700, colors.cBlue800),
  [ValidationType.success]: decorate(colors.cGreen700, colors.cGreen800),
  [ValidationType.warning]: decorate(colors.cOrange700, colors.cOrange800),
  [ValidationType.error]: decorate(colors.cRed700, colors.cRed800)
};

export const applyDecoration = ({validationType}: InputProps) =>
  validationType ? decorationMap[validationType] : '';
