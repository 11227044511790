export enum LinkSize {
  m = 'm',
  s = 's',
  xs = 'xs',
  xxs = 'xxs'
}

export enum IconPosition {
  left = 'left',
  right = 'right'
}

export interface LinkProps {
  size?: LinkSize;
  asButton?: boolean;
  customColors?: {
    normal: string;
    hover: string;
  };
}
