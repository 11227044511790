export enum VerticalMenuItemType {
  error = 'error',
  warning = 'warning',
  confirm = 'confirm',
  neutral = 'neutral'
}

export interface VerticalMenuItemProps {
  bold?: boolean;
  onClick?: () => void;
  type?: VerticalMenuItemType;
  selected?: boolean;
}
