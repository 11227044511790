import React from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import styled, {css} from 'styled-components';
import {StyledFC} from '../../types';
import colors from '../../sass/colors';
import {addDataCy, getStretchCssValue, Stretch} from '../../utils';

export interface TabularListProps {
  width?: Stretch | number;
  height?: any;
  scrollLocked?: boolean;
}

export interface TabularListCellProps {
  align?: string;
  width?: string;
  noVerticalPadding?: boolean;
}

export interface TabularListRowProps {
  isSelected?: boolean;
}

export interface TabularListBodyProps {
  height?: any;
}

export interface TabularListHeaderProps {
  isScrolled?: boolean;
}

export const TabularListCell = styled.div.attrs(
  addDataCy
)<TabularListCellProps>`
  padding: ${p => (p.noVerticalPadding ? 0 : '12.5px')} 8px;
  align-self: baseline;
  text-align: ${p => p.align || 'left'};
  ${p => (p.width ? `width: ${p.width};` : 'flex: 1')}
`;

export const TabularListRow = styled.div.attrs(addDataCy)<TabularListRowProps>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border: none;
  border-bottom: 1px solid ${colors.cGray200};
  ${p => p.isSelected && `background-color: ${colors.cBlue100};`}
  /* the following solves the content-height bug on the child elements in safari: 
  https://stackoverflow.com/questions/33636796/chrome-safari-not-filling-100-height-of-flex-parent */
  flex: 0 0 auto;
`;
TabularListRow.displayName = 'TabularListRow';

const _TabularListHeader: StyledFC<TabularListHeaderProps> = ({
  children,
  className,
  dataCy
}) => (
  <div className={className} data-cy={dataCy}>
    <TabularListRow>{children}</TabularListRow>
  </div>
);

export const TabularListHeader = styled(_TabularListHeader)`
  display: flex;
  width: 100%;
  border-bottom: 2px solid ${colors.cGray500};
  position: relative;
  z-index: 4;
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.2);
    transition: opacity 0.25s ease-in-out;
    ${p => (p.isScrolled ? 'opacity: 1;' : '')}
  }

  ${TabularListCell} {
    font-weight: bold;
  }

  ${TabularListRow} {
    border: none;
  }
`;
TabularListHeader.displayName = 'TabularListHeader';

export const TabularListBodyScroller = styled(Scrollbars).attrs(
  addDataCy
)<TabularListProps>`
  ${p =>
    p.height
      ? css`
          height: ${p.height};
        `
      : ''}
`;
TabularListBodyScroller.displayName = 'TabularListBodyScroller';

export const TabularListBody = styled.div.attrs(
  addDataCy
)<TabularListBodyProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
`;
TabularListBody.displayName = 'TabularListBody';

export const TabularList = styled.div.attrs(addDataCy)<TabularListProps>`
  position: relative;
  width: ${p => getStretchCssValue(p.width || Stretch.auto)};
  height: ${p => p.height};
  overflow-x: hidden;
`;
TabularList.displayName = 'TabularList';
