import React from 'react';
import styled, {keyframes} from 'styled-components';
import {LoadingAnimationIcon, SmallLoadingAnimationIcon} from '../Icons';
import dimensions from '../../sass/dimensions';

const SPINNER_DIMENSION = `68px`;

export const animation = keyframes`
  100% {transform: rotate(360deg);}
`;

export const StyledSpinner = styled.div`
  position: absolute;
  z-index: ${dimensions.zIndexOvertop};
  top: calc(50% - ${SPINNER_DIMENSION} / 2);
  left: calc(50% - ${SPINNER_DIMENSION} / 2);
  svg {
    animation: ${animation} 1s linear infinite;
  }
`;

export const InputSpinnerAnimationWrapper = styled.div`
  height: 21px;
  svg {
    animation: ${animation} 1s linear infinite;
  }
`;

export const InputSpinner = (
  <InputSpinnerAnimationWrapper>
    <SmallLoadingAnimationIcon />
  </InputSpinnerAnimationWrapper>
);

export const Spinner = (
  <StyledSpinner>
    <LoadingAnimationIcon />
  </StyledSpinner>
);
