import React from 'react';
import styled from 'styled-components';
import {StyledFC} from '../../types';
import dimensions from '../../sass/dimensions';
import {QRCodeImg} from '@cheprasov/react-qrcode';

type QRCodeProps = {systemLogin: {label: string; value: string}};

const _QRCode: StyledFC<QRCodeProps> = ({className, systemLogin}) => (
  <div className={className}>
    <QRCodeImg size={48} level='H' value={systemLogin.value} />
  </div>
);

export const QRCode = styled(_QRCode)`
  height: 58px;
  width: 58px;
  border: ${dimensions.borderWidthS} solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    align-self: center;
  }
`;

QRCode.displayName = 'QRCode';
