export enum LineWeight {
  medium = 'medium',
  thick = 'thick',
  thin = 'thin'
}

export enum LineStyle {
  dashed = 'dashed',
  dashDot = 'dash-dot',
  solid = 'solid'
}

export enum LineStrokeWidth {
  medium = 2,
  thick = 3,
  thin = 1
}
