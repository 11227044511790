import styled from 'styled-components';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';
import {addDataCy} from '../../utils';
import {Link} from '../Link';
import {applyDecoration} from '../Link/decorations/sizeDecorations';
import {LinkProps} from '../Link/types';

export interface BreadcrumbSeparatorProps extends LinkProps {
  active?: boolean;
}

export const DisabledBreadcrumbLink = styled.span.attrs(
  addDataCy
)<BreadcrumbSeparatorProps>`
  & + svg {
    margin: 0px ${dimensions.spaceXs};
    fill: ${props => (props.active ? colors.cBlue600 : colors.cGray400)};
  }
  cursor: default;
  color: ${props => (props.active ? colors.cBlue600 : colors.cGray400)};

  ${applyDecoration}
`;

DisabledBreadcrumbLink.displayName = 'DisabledBreadcrumbLink';

export const BreadcrumbLink = styled(Link)<BreadcrumbSeparatorProps>`
  & + svg {
    transition: fill, 0.25s ease-in-out;
    fill: ${props => (props.active ? colors.cBlue600 : colors.cGray700)};
    margin: 0px ${dimensions.spaceXs};
  }
  &:hover + svg {
    fill: ${props => (props.active ? colors.cBlue700 : colors.cGray600)};
  }
`;

export const Breadcrumb = styled.div`
  padding: ${dimensions.spaceS} 0px;
`;

BreadcrumbLink.displayName = 'BreadcrumbLink';
Breadcrumb.displayName = 'Breadcrumb';
