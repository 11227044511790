import {ValidationType} from '../../types';
import colors from '../../sass/colors';

export const typeBoxBorderColorMap: Record<ValidationType, string> = {
  [ValidationType.action]: colors.cBlue700,
  [ValidationType.success]: colors.cGreen700,
  [ValidationType.warning]: colors.cOrange700,
  [ValidationType.error]: colors.cRed700
};

export const typeBorderColorMap: Record<ValidationType, string> = {
  [ValidationType.action]: colors.cBlue200,
  [ValidationType.success]: colors.cGreen200,
  [ValidationType.warning]: colors.cOrange200,
  [ValidationType.error]: colors.cRed200
};

export const typeBackgroundColorMap: Record<ValidationType, string> = {
  [ValidationType.action]: colors.cBlue100,
  [ValidationType.success]: colors.cGreen100,
  [ValidationType.warning]: colors.cOrange100,
  [ValidationType.error]: colors.cRed100
};
