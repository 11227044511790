export enum ActivityType {
  ExerciseList = 'ExerciseList',
  FileList = 'FileList',
  TextList = 'TextList',
  FemList = 'FemList',
  KemList = 'KemList'
}

export interface LobTypeProps {
  activityType: ActivityType;
}

export interface LobProps extends LobTypeProps {
  caption: string;
  title: string;
  metaInfo?: string;
  actionPreview: () => void;
  actionStart: () => void;
}
