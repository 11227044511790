import React from 'react';
import styled, {css} from 'styled-components';
import {PropsWithStyledProps} from '../../types';

export enum VAlign {
  top = 'top',
  middle = 'middle',
  bottom = 'bottom'
}

export interface BoxProps {
  id?: string;
  scrollable?: boolean;
  stretch?: boolean;

  /* browser's default vertical alignment is `baseline`, this `vAlign` doesn't
  force any default value */
  vAlign?: VAlign;
  children?: React.ReactNode;
}
/**
 * Box component
 * A container that is used to display the content with scroll, stretch behaviour
 * and to align vertically
 * ### Properties
 | Name          | Type          | Default    | Description                                      |
 |---            | ---           |---         | ---                                              |
 | `className`   | `string`      | Optional   | Any custom class to Box component              |
 | `scrollable`  | `boolean`     | false      | If true, box adds scroll for the children        |
 | `stretch`     | `boolean`     | false      | If true, box stretches to full width             |
 | `vAlign`      | one of `'top', 'middle' or 'bottom'` | Optional | align the children vertically|
 */
export const Box = React.forwardRef<
  HTMLDivElement,
  PropsWithStyledProps<BoxProps>
>((props, ref) => {
  const {children, className, id, dataCy, scrollable} = props;
  return (
    <div id={id} data-cy={dataCy} ref={ref} className={className}>
      {
        /* To make the content as scrollable, Box has to be nested div element,
        parent wrapper needs to have styling of `scrollWrapper` and  the style
        `scrollable` makes to inner content with overflow-y:auto.
        This can not be achieved with single div element */
        scrollable ? <div className='scrollable'>{children}</div> : children
      }
    </div>
  );
});

export const StyledBox = styled(Box)`
  ${props => {
    switch (props.vAlign) {
      case VAlign.top:
        return 'align-self: flex-start;';
      case VAlign.middle:
        return 'align-self: center;';
      case VAlign.bottom:
        return 'align-self: flex-end;';
      default:
        return '';
    }
  }}

  ${props =>
    props.stretch &&
    `
    flex: 1 1 auto;
    display: flex;
  `}
  
  .scrollable > .vLayout {
    height: 100%;
  }

  ${props =>
    props.scrollable &&
    css`
      display: flex;
      max-height: 100%;
      max-width: 100%;
      overflow-y: auto;
      // To enable smooth scrolling
      scroll-behavior: smooth;
      &.animated {
        // turn off smooth scrolling during animation
        scroll-behavior: auto;
      }
      .scrollable {
        width: 100%;
      }
    `}
`;
