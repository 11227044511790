import styled from 'styled-components';
import dimensions from '../../sass/dimensions';
import {addDataCy} from '../../utils';

export const Heading = styled.h1.attrs(addDataCy)`
  font-size: ${dimensions.fontSizeXxl};
  margin: 0 0 ${dimensions.spaceS};
  text-align: left;
  font-weight: ${props => (props.as === 'h1' ? 'normal' : 300)};
`;

Heading.displayName = 'Heading';
