import styled from 'styled-components';
import {getSafeStyling} from '../../utils/stylingHelpers';

export const Nose = styled.div`
  ${({theme}) =>
    theme &&
    `
    width: 0;
    height: 0;
    border: 0 solid transparent;
    position: absolute;
    ${getSafeStyling('border-top-width')(theme.borderTopWidth)};
    ${getSafeStyling('border-right-width')(theme.borderRightWidth)};
    ${getSafeStyling('border-bottom-width')(theme.borderBottomWidth)};
    ${getSafeStyling('border-left-width')(theme.borderLeftWidth)};
    ${getSafeStyling('border-top')(theme.borderTop)};
    ${getSafeStyling('border-right')(theme.borderRight)};
    ${getSafeStyling('border-bottom')(theme.borderBottom)};
    ${getSafeStyling('border-left')(theme.borderLeft)};
    ${getSafeStyling('top')(theme.top)};
    ${getSafeStyling('left')(theme.left)};
    ${getSafeStyling('transform')(theme.transform)};
    ${getSafeStyling('margin-left')(theme.marginLeft)};
    ${getSafeStyling('margin-right')(theme.marginRight)};
  `}
`;

export const InnerArrow = styled.div`
  ${({theme}) =>
    theme &&
    `
    position: absolute;
    border: 0 solid transparent;
    ${getSafeStyling('top')(theme.innerArrow.top)};
    ${getSafeStyling('left')(theme.innerArrow.left)};
    ${getSafeStyling('right')(theme.innerArrow.right)};
    ${getSafeStyling('border-top')(theme.innerArrow.borderTop)};
    ${getSafeStyling('border-right')(theme.innerArrow.borderRight)};
    ${getSafeStyling('border-bottom')(theme.innerArrow.borderBottom)};
    ${getSafeStyling('border-left')(theme.innerArrow.borderLeft)};
    ${getSafeStyling('border-left-width')(theme.innerArrow.borderLeftWidth)};
    ${getSafeStyling('border-right-width')(theme.innerArrow.borderRightWidth)};
    ${getSafeStyling('border-top-width')(theme.innerArrow.borderTopWidth)};
    ${getSafeStyling('border-bottom-width')(
      theme.innerArrow.borderBottomWidth
    )};
    ${getSafeStyling('margin-top')(theme.innerArrow.marginTop)};
  `}
`;
