import styled from 'styled-components';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';
import {addDataCy, getStretchCssValue, Stretch} from '../../utils';

export enum BorderOrientation {
  left = 'left',
  right = 'right'
}

export interface SidebarProps {
  width: Stretch | number;
  borderOrientation?: BorderOrientation;
  heightAdjustment?: string;
}

export const SidebarPrimaryContent = styled.section.attrs(addDataCy)`
  padding: ${dimensions.spaceM} ${dimensions.spaceL} ${dimensions.spaceM}
    ${dimensions.spaceM};
  overflow-y: auto;
  overflow-x: hidden;
  flex: auto;
  flex-grow: 0;
`;

SidebarPrimaryContent.displayName = 'SidebarPrimaryContent';

export const SidebarSecondaryContent = styled(SidebarPrimaryContent)`
  border-top: ${dimensions.borderWidthM} solid ${colors.cGray500};
  flex: none;
  flex-grow: 4;
`;

SidebarSecondaryContent.displayName = 'SidebarSecondaryContent';

export const Sidebar = styled.aside.attrs(addDataCy)<SidebarProps>`
  width: ${p => getStretchCssValue(p.width)};
  height: ${p =>
    p.heightAdjustment ? `calc(100vh ${p.heightAdjustment})` : '100vh'};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${p =>
    `border-${p.borderOrientation || BorderOrientation.right}: ${
      dimensions.borderWidthM
    } solid ${colors.cGray500};`}
`;

Sidebar.displayName = 'Sidebar';
