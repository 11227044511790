import {BoundingRect} from 'react-measure';

export type Bounds = {
  top?: number | string;
  left?: number | string;
};

export type PopupBounds = {
  bounds?: Bounds;
  noseBounds?: Bounds;
  popupHeight: number | string;
};

export enum TooltipPlace {
  bottom = 'bottom',
  top = 'top'
}

export enum PopupPlace {
  left = 'left',
  bottom = 'bottom',
  right = 'right',
  top = 'top'
}

export type PopupKind = 'small' | 'large';

export type PopupProps = {
  place: PopupPlace;
  kind: PopupKind;
  still?: boolean;
  show: boolean;
  elementBounds?: BoundingRect;
  onClickOverlay: () => void;
};
