import React from 'react';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';
import styled from 'styled-components';
import {Stretch, StyledFC} from '../../types';
import {getStretchCssValue} from '../../utils/stylingHelpers';

export interface DescriptionListWrapperProps {
  width: Stretch | number;
  fixedLayout?: boolean;
}

export interface DescriptionListProps extends DescriptionListWrapperProps {
  items?: Array<any>;
  stretched?: boolean;
}

const DescriptionListWrapper = styled.div<DescriptionListWrapperProps>`
  width: ${p => getStretchCssValue(p.width)};
  display: table;
  table-layout: ${props => (props.fixedLayout ? 'fixed' : 'auto')};
  border-top: 1px solid ${colors.cGray200};
  border-bottom: 1px solid ${colors.cGray200};
`;

export const DescriptionTerm = styled.dt`
  display: table-cell;
  line-height: ${dimensions.lineHeightM};
  padding-right: ${dimensions.spaceS};
  color: ${colors.cGray700};
`;

DescriptionTerm.displayName = 'DescriptionTerm';

export const Description = styled.dd`
  font-weight: bold;
  margin: 0;
  display: table-cell;
  line-height: ${dimensions.lineHeightM};
`;

const TableRow = styled.div`
  display: table-row;
  &:not(:last-child) {
    ${DescriptionTerm},
    ${Description} {
      padding-bottom: ${dimensions.spaceS};
    }
  }
`;

Description.displayName = 'Description';

const _DescriptionList: StyledFC<DescriptionListProps> = ({
  children,
  items,
  className,
  dataCy,
  width,
  fixedLayout
}) => (
  <DescriptionListWrapper width={width} fixedLayout={fixedLayout}>
    <dl className={className} data-cy={dataCy}>
      {items
        ? items.map((item: any, i: number) => (
            <TableRow key={i}>
              <DescriptionTerm>{item.term}</DescriptionTerm>
              <Description>{item.desc}</Description>
            </TableRow>
          ))
        : children}
    </dl>
  </DescriptionListWrapper>
);

export const DescriptionList = styled(_DescriptionList)`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: ${dimensions.fontSizeM};
  margin: 0;
  padding: ${dimensions.spaceM} 0;
  line-height: 1;
`;

DescriptionList.displayName = 'DescriptionList';
