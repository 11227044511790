import React from 'react';

export const CloseIcon: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.87871 6.70711L0.585815 1.41421L2.00003 0L7.29292 5.29289L12.5858 0L14 1.41421L8.70714 6.70711L14 12L12.5858 13.4142L7.29292 8.12132L2.00003 13.4142L0.585815 12L5.87871 6.70711Z'
      fill='#1188EE'
    />
  </svg>
);
