import {css, FlattenInterpolation} from 'styled-components';
import colors from '../../sass/colors';
import {VerticalMenuItemProps, VerticalMenuItemType} from './types';

const errorCss = css`
  color: ${colors.cRed700};
  fill: ${colors.cRed700};
`;

const warningCss = css<VerticalMenuItemProps>`
  color: ${({bold}) => (bold ? colors.cYellow800 : colors.cYellow700)};
  fill: ${colors.cYellow700};
`;

const confirmCss = css`
  color: ${colors.cGreen700};
  fill: ${colors.cGreen700};
`;

const neutralCss = css<VerticalMenuItemProps>`
  color: ${({bold}) => (bold ? colors.cTextDark : colors.cTextMedium)};
  fill: ${colors.cTextMedium};
`;

const decorationMap: Record<
  VerticalMenuItemType,
  FlattenInterpolation<VerticalMenuItemProps>
> = {
  [VerticalMenuItemType.error]: errorCss,
  [VerticalMenuItemType.warning]: warningCss,
  [VerticalMenuItemType.confirm]: confirmCss,
  [VerticalMenuItemType.neutral]: neutralCss
};

export const applyDecoration = ({type}: VerticalMenuItemProps) =>
  type ? decorationMap[type] : '';
