import React from 'react';
import dimensions from '../../sass/dimensions';
import responsive from '../../sass/responsive';
import styled, {css} from 'styled-components';
import {StyledBox, VAlign} from '../Box';
import {Button} from '../Button';
import {StyledButton} from '../Button/Button';
import {applyDecoration} from './decorations';
import {ContextNotificationProps} from './types';
import {BorderDirection, StyledFC} from '../../types';

const resolvePadding = ({
  padding,
  increasedPadding
}: ContextNotificationProps) =>
  padding
    ? css`
        padding: ${padding};
      `
    : increasedPadding
    ? css`
        padding: ${dimensions.spaceS};
      `
    : css`
        padding: ${dimensions.spaceXs};
      `;

export const Box = styled(StyledBox)<
  {customStretch?: boolean} & ContextNotificationProps
>`
  display: ${p => (p.displayFlex ? 'flex' : 'inline-block')};
  width: ${p => (p.width ? p.width + 'px' : 'auto')};
  font-size: ${dimensions.fontSizeM};
  line-height: normal;
  ${props => props.customStretch && 'display: block; width: 100%;'}
  align-items: center;

  ${resolvePadding}
  ${applyDecoration}
 
  @media (max-width: ${responsive.maxDeviceWidth}) {
    flex-direction: column;
  }
  ${props => {
    switch (props.borderOrientation) {
      case BorderDirection.TOP:
        return css`
          border-top: ${dimensions.borderWidthS} solid;
        `;
      case BorderDirection.RIGHT:
        return css`
          border-right: ${dimensions.borderWidthS} solid;
        `;
      case BorderDirection.BOTTOM:
        return css`
          border-bottom: ${dimensions.borderWidthS} solid;
        `;
      case BorderDirection.LEFT:
        return css`
          border-left: ${dimensions.borderWidthS} solid;
        `;
      default:
        return css`
          border: ${dimensions.borderWidthS} solid;
        `;
    }
  }};
`;

export const ContextNotificationLabel = styled.div`
  flex: 0;
  flex-basis: auto;
`;

export const ContextNotificationContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${dimensions.spaceXl};
`;

export const ContextNotificationIcon = styled.div`
  flex: 0 0 auto;
  align-self: flex-start;
  align-items: flex-start;
  @media (max-width: ${responsive.maxDeviceWidth}) {
    margin: 0 auto;
    padding: 0 0 4px;
  }
  @media (min-width: ${responsive.minDeviceWidth}) {
    padding: 5px 12px 0 0;
  }
`;

export const ContextNotificationBody = styled.div<{asRow?: boolean}>`
  flex: 1 1 0;
  display: flex;
  @media (max-width: ${responsive.maxDeviceWidth}) {
    flex-direction: column;
  }
  ${({asRow}) =>
    asRow
      ? css`
          @media (min-width: ${responsive.minDeviceWidth}) {
            flex-direction: row;
            align-items: center;
          }
        `
      : css`
          @media (min-width: ${responsive.minDeviceWidth}) {
            flex-direction: column;
          }
        `};
`;

export const ContentWrapper = styled.div<{withMarginLeft: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${p => p.withMarginLeft && `margin-left: ${dimensions.spaceXl};`}
`;

export const OutsideContentWrapper = styled(ContentWrapper)`
  flex: 1;
`;

export const ContextNotificationButton = styled(Button)`
  ${StyledButton} {
    padding: ${dimensions.spaceXs};
    ${dimensions.spaceS};
  }
`;

export const ContextNotification: StyledFC<ContextNotificationProps> = ({
  children,
  kind,
  increasedPadding,
  stretch,
  className,
  padding,
  dataCy,
  width,
  displayFlex,
  preserveFontColor,
  borderOrientation
}) => (
  <Box
    vAlign={VAlign.middle}
    dataCy={dataCy}
    className={className}
    kind={kind}
    increasedPadding={increasedPadding}
    customStretch={stretch}
    displayFlex={displayFlex}
    width={width}
    padding={padding}
    preserveFontColor={preserveFontColor}
    borderOrientation={borderOrientation}
  >
    {children}
  </Box>
);

ContextNotification.displayName = 'ContextNotification';
