import React from 'react';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';
import styled from 'styled-components';
import {Stretch, StyledFC} from '../../types';
import {getStretchCssValue} from '../../utils';
import {Button} from '../Button';
import {Heading} from '../Heading';
import {Link} from '../Link';
import {Paragraph} from '../Paragraph';

interface FeatureLayoutProps {
  width: Stretch | number;
  image?: React.ReactNode;
  imageUrl?: any;
  title?: string;
  description?: string;
}

export const FeatureLayoutIllustration = styled.div`
  display: ${p => (p.children ? 'flex' : 'none')};
  flex-flow: column wrap;
  margin-right: 12px;
`;

const Description = styled.div`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  ${Paragraph} + ${Button},
  ${Paragraph} + ${Link} {
    margin-top: ${dimensions.spaceS};
  }

  ${Paragraph} + ${Link} {
    display: block;
  }
`;

export const FeatureLayout: StyledFC<FeatureLayoutProps> = ({
  image,
  imageUrl,
  title,
  description,
  className,
  dataCy,
  children
}) => (
  <div className={className} data-cy={dataCy}>
    <FeatureLayoutIllustration>
      {image || (imageUrl && <img src={imageUrl} alt={title} />)}
    </FeatureLayoutIllustration>

    <Description>
      {title && <Heading as='h2'>{title}</Heading>}
      {description && (
        <Paragraph customColor={colors.cGray700}>{description}</Paragraph>
      )}
      {children}
    </Description>
  </div>
);

export const StyledFeatureLayout = styled(FeatureLayout)`
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-items: center;
  width: ${p => getStretchCssValue(p.width)};
  img {
    align-self: center;
  }
  ${Paragraph} {
    text-align: left;
  }
`;
