import React from 'react';
import styled from 'styled-components';
import {FlexAlign, StyledFC} from '../../types';
import {DEFAULT_GAP} from './constants';
import {VLayoutContext} from './context';
import {LayoutChildWrapperProps, LayoutDecoration} from './types';

export interface VLayoutProps {
  align?: FlexAlign;
  gap?: number;
  stretch?: boolean;
  decorations?: LayoutDecoration[];
  width?: number;
  maxWidth?: number;
}

export const VLayout: StyledFC<VLayoutProps> = ({
  children,
  dataCy,
  gap = DEFAULT_GAP,
  width,
  className
}) => (
  <div className={className} data-cy={dataCy}>
    <VLayoutContext.Provider
      value={{
        wrap: gap !== DEFAULT_GAP,
        marginTop: gap,
        maxWidth: width
      }}
    >
      {children}
    </VLayoutContext.Provider>
  </div>
);

interface VLayoutChildWrapperProps extends LayoutChildWrapperProps {
  noMargin?: boolean;
}

export const VLayoutChildWrapper: StyledFC<VLayoutChildWrapperProps> = ({
  children,
  decoration,
  noMargin = false
}) => {
  const {wrap, marginTop, maxWidth} =
    React.useContext<VLayoutContext>(VLayoutContext);
  return wrap ? (
    <div
      style={{
        ...decoration,
        marginTop: noMargin ? 0 : marginTop,
        maxWidth
      }}
    >
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

export const StyledVLayout = styled(VLayout)`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || FlexAlign.flexStart};
  max-width: ${props => props.maxWidth};
  width: ${props =>
    props.width ? `${props.width}px` : props.stretch ? '100%' : 'fit-content'};
  & > :nth-child(n) {
    margin-top: ${DEFAULT_GAP}px;
  }
  & > :first-child {
    margin-top: 0;
  }
`;
