import {css} from 'styled-components';
import dimensions from '../../../sass/dimensions';
import {ButtonSize} from '../types';
import {StyledButtonProps} from '../Button';

interface SizeSchema {
  minHeight: string;
  fontSize: string;
  lineHeight: string;
  svgDimension: string;
  padding?: string;
  childPadding?: string;
}

const getDecorationsForSizeSchema =
  ({
    minHeight,
    fontSize,
    lineHeight,
    svgDimension,
    padding,
    childPadding
  }: SizeSchema) =>
  (childWrapper: any) =>
    css`
      min-height: ${minHeight};
      font-size: ${fontSize};
      line-height: ${lineHeight};
      padding: ${padding || dimensions.btnPaddingTiny};

      ${childPadding &&
      childWrapper &&
      css`
        ${childWrapper} {
          padding: 0 ${childPadding};
        }
      `}

      svg {
        height: ${svgDimension};
        width: ${svgDimension};
      }
    `;

const decorationMap: Record<ButtonSize, any> = {
  [ButtonSize.xs]: getDecorationsForSizeSchema({
    minHeight: dimensions.btnMinHeightXs,
    fontSize: dimensions.fontSizeXxs,
    lineHeight: dimensions.btnLineHeightXs,
    padding: dimensions.btnPaddingTiny,
    svgDimension: dimensions.btnSvgS
  }),
  [ButtonSize.s]: getDecorationsForSizeSchema({
    minHeight: dimensions.btnMinHeightS,
    fontSize: dimensions.fontSizeXxs,
    lineHeight: dimensions.lineHeightXxs,
    padding: dimensions.btnPaddingTiny,
    svgDimension: dimensions.btnSvgS
  }),
  [ButtonSize.s2]: getDecorationsForSizeSchema({
    minHeight: dimensions.btnMinHeightM,
    fontSize: dimensions.fontSizeS,
    lineHeight: dimensions.lineHeightS,
    padding: dimensions.btnPaddingTiny,
    svgDimension: dimensions.btnSvgS
  }),
  [ButtonSize.m]: getDecorationsForSizeSchema({
    minHeight: dimensions.btnMinHeightM,
    fontSize: dimensions.fontSizeM,
    lineHeight: dimensions.lineHeightM,
    padding: `${dimensions.spaceXxs} ${dimensions.btnPaddingTiny}`,
    svgDimension: dimensions.btnSvgM
  }),
  [ButtonSize.m2]: getDecorationsForSizeSchema({
    minHeight: dimensions.btnMinHeightL,
    fontSize: dimensions.fontSizeM,
    lineHeight: dimensions.lineHeightM,
    padding: `${dimensions.spaceXxs} ${dimensions.btnPaddingTiny}`,
    svgDimension: dimensions.btnSvgM
  }),
  [ButtonSize.l]: getDecorationsForSizeSchema({
    minHeight: dimensions.btnMinHeightL,
    fontSize: dimensions.fontSizeL,
    lineHeight: dimensions.lineHeightM,
    padding: `${dimensions.spaceXxs} ${dimensions.btnPaddingTiny}`,
    svgDimension: dimensions.btnSvgL
  }),
  [ButtonSize.l2]: getDecorationsForSizeSchema({
    minHeight: dimensions.btnMinHeightL2,
    fontSize: dimensions.fontSizeL,
    lineHeight: dimensions.btnLineHeightL,
    padding: `${dimensions.spaceXxs} ${dimensions.btnPaddingTiny}`,
    svgDimension: dimensions.btnSvgM
  }),
  [ButtonSize.xl]: getDecorationsForSizeSchema({
    minHeight: dimensions.btnMinHeightXl,
    fontSize: dimensions.fontSizeXxl,
    lineHeight: dimensions.btnLineHeightXl,
    padding: `${dimensions.spaceXs} ${dimensions.spaceXxs}`,
    svgDimension: dimensions.btnSvgL,
    childPadding: dimensions.spaceXxs
  }),
  [ButtonSize.xl2]: getDecorationsForSizeSchema({
    minHeight: dimensions.btnMinHeightXl2,
    fontSize: dimensions.fontSizeXxl,
    lineHeight: dimensions.btnLineHeightXl,
    padding: `${dimensions.spaceXs} ${dimensions.spaceXxs}`,
    svgDimension: dimensions.btnSvgM,
    childPadding: dimensions.spaceXxs
  }),
  [ButtonSize.xxl]: getDecorationsForSizeSchema({
    minHeight: dimensions.btnMinHeightXxl,
    fontSize: dimensions.fontSizeXxxl,
    lineHeight: dimensions.btnLineHeightXl,
    padding: `${dimensions.spaceXs} ${dimensions.spaceXxs}`,
    svgDimension: dimensions.btnSvgL,
    childPadding: dimensions.spaceXxs
  })
};

export const applyDecoration =
  (childWrapper: any) =>
  ({size}: StyledButtonProps) =>
    decorationMap[size || ButtonSize.m](childWrapper);
