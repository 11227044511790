import React from 'react';
import styled, {css} from 'styled-components';
import {StyledFC} from '../../types';
import {OptionList} from '../OptionList/OptionList';
import {DropdownContext} from './DropdownContext';
import {getBottom, getHeight, getTop} from './utils';

export interface DropdownProps {
  zIndex: number;
  overlapTrigger?: boolean;
  direction?: DropdownDirection;
  track?: boolean;
  stickToViewport?: boolean;
  useOverlay?: boolean;
  isOpen?: boolean;
}

const _DropdownTrigger: StyledFC = ({children, className, dataCy}) => {
  const {toggle, triggerRef} = React.useContext(DropdownContext);

  return (
    <div
      className={className}
      data-cy={dataCy}
      onClick={toggle}
      ref={triggerRef}
    >
      {children}
    </div>
  );
};

export const DropdownTrigger = styled(_DropdownTrigger)`
  cursor: pointer;
  position: relative;
`;

DropdownTrigger.displayName = 'DropdownTrigger';

const _DropdownContent: StyledFC = ({className, dataCy, children}) => {
  const {contentDynamicStyles, contentRef} = React.useContext(DropdownContext);
  return (
    <div
      className={className}
      data-cy={dataCy}
      style={contentDynamicStyles}
      ref={contentRef}
    >
      {children}
    </div>
  );
};

export const DropdownContent = styled(_DropdownContent)`
  position: absolute;
  width: 100%;
`;

DropdownContent.displayName = 'DropdownContent';

interface OverlayProps {
  show: boolean;
  zIndex: number;
}

const Overlay = styled.div<OverlayProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${p => p.zIndex};
  width: 100vw;
  height: 100vh;
  display: ${p => (p.show ? 'block' : 'none')};
`;

Overlay.displayName = 'Overlay';

export enum DropdownDirection {
  top = 'top',
  bottom = 'bottom'
}

const directionMap: Record<DropdownDirection, DropdownDirection> = {
  [DropdownDirection.bottom]: DropdownDirection.top,
  [DropdownDirection.top]: DropdownDirection.bottom
};

const _Dropdown: StyledFC<DropdownProps> = props => {
  const {
    children,
    className,
    dataCy,
    direction = DropdownDirection.top,
    track,
    stickToViewport,
    zIndex,
    useOverlay = true,
    isOpen = false
  } = props;

  const [dynamicDirection, setDirection] = React.useState(direction);
  const [show, setShow] = React.useState(isOpen);
  const [additionalOffset, setAdditionalOffset] = React.useState(0);
  const [triggerHeight, setTriggerHeight] = React.useState(0);
  const triggerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  const getDirection = React.useCallback(
    () =>
      (direction === DropdownDirection.top
        ? window.innerHeight - getBottom(triggerRef.current)
        : getTop(triggerRef.current)) > getHeight(contentRef.current)
        ? directionMap[directionMap[direction]]
        : directionMap[direction],
    [direction]
  );

  React.useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  React.useEffect(() => {
    const triggerHeight = getHeight(triggerRef.current);
    setTriggerHeight(triggerHeight);

    let newDir = getDirection();

    setDirection(newDir);

    if (track) {
      const updateContentPosition = () => {
        newDir = getDirection();

        if (stickToViewport) {
          setAdditionalOffset(
            Math.min(
              0,
              getTop(triggerRef.current) + triggerHeight,
              window.innerHeight - getBottom(triggerRef.current) + triggerHeight
            )
          );
        }

        setDirection(newDir);
      };

      document.addEventListener('scroll', updateContentPosition);

      return () =>
        document.removeEventListener('scroll', updateContentPosition);
    }
  }, [show, direction, track, stickToViewport, getDirection]);

  const toggle = React.useCallback(() => {
    setShow(s => !s);
  }, []);

  return (
    <div className={className} data-cy={dataCy}>
      <DropdownContext.Provider
        value={{
          triggerRef,
          contentRef,
          toggle,
          isOpened: show,
          contentDynamicStyles: {
            [dynamicDirection]: `${triggerHeight + -additionalOffset}px`,
            visibility: show ? 'visible' : 'hidden'
          }
        }}
      >
        {children}
      </DropdownContext.Provider>
      {useOverlay && (
        <Overlay
          zIndex={zIndex - 1}
          show={show}
          onClick={() => setShow(false)}
        />
      )}
    </div>
  );
};

export const Dropdown = styled(_Dropdown)`
  position: relative;
  z-index: ${p => p.zIndex};

  ${DropdownTrigger} {
    z-index: ${p => p.zIndex};
  }

  ${DropdownContent} {
    z-index: ${p => p.zIndex};
  }
  ${p =>
    p.overlapTrigger
      ? css`
          ${DropdownContent} {
            margin-top: -52px;
          }
        `
      : css`
          ${OptionList} {
            margin-top: -1px;
            border-radius: 0;
          }
        `};
`;

Dropdown.displayName = 'Dropdown';
