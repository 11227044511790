import React from 'react';
import styled, {css} from 'styled-components';
import {identity} from 'lodash/fp';

import {IconBadge, Shape} from '../IconBadge';
import {useIsScrolled} from '../../hooks';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';
import {StyledFC, ValidationType} from '../../types';
import {addDataCy, getStretchCssValue, Stretch} from '../../utils';
import {handleHover} from '../../utils';

import {HeaderWrapper, List} from './components';

/**
 * The configuration for each individual item.
 * There are currently two ways to add additional data:
 * 1. (Recommended):
 *   Use the generic `OptionListItemMeta<Meta>` type to add custom typed data to the `meta` field.
 * 2. (Avoid if possible: Deprecated!):
 *   Use the first generic argument to add any custom typed fields to every item.
 *   It can lead to very confusing code, where each item also "is" an instance of a different type,
 *   e.g. a Student/Teacher, which can easily lead to POSTing a whole to a service
 *   instead of just the required entity.
 *
 * If you need custom data please use `OptionListItemMeta`,
 * so we can eventually get rid of the first generic type argument without breaking your code.
 */
export type OptionListItem<
  /**
   * @deprecated use `OptionListItemMeta` instead
   * @see OptionListItem
   * @see OptionListItemMeta
   */
  T = {},
  Meta = unknown
> = {
  onClick?: () => void;
  active?: boolean;
  /**
   * Custom typed data assigned with each item.
   * Use `value` to store the string value!
   * Use `OptionListItemMeta<Meta>` instead of `OptionListItem<{}, Meta>` to type it!
   * @see OptionListItem
   * @see OptionListItemMeta
   */
  meta?: Meta;
  label?: string;
  subLabel?: string;
  validationType?: ValidationType;
  value?: string;
  dataCy?: string;
  /**
   * from iconSrc and IconComponent, only one of them should be provided.
   * If both are given, the iconSrc will be used and IconComponent will be disregarded.
   */
  iconSrc?: string;
  /**
   * IconComponent that returns a svg element.
   */
  IconComponent?: React.FC;
} & T;

/**
 * An OptionListItem that has a typed `meta` field,
 * (and no additional fields coming from the first generic argument).
 *
 * This type only exists to make the usage of the `meta` field for additional data
 * more convenient and to offer a migration strategy,
 * to not break code when we will remove the first generic argument.
 *
 * @see OptionListItem
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OptionListItemMeta<Meta> extends OptionListItem<{}, Meta> {}

export interface OptionListProps {
  items: Array<OptionListItem>;
  width?: Stretch | number;
  height?: Stretch | number;
  onClick?: () => void;
  itemComponent?: React.ComponentType<OptionListItem>;
  mapToItem?: (_: any) => OptionListItem;
  renderItem?: (props: OptionListItem, index: number) => React.ReactNode;
  header?: React.ReactNode;
  validationType?: ValidationType;
  itemPlaceholder?: React.ReactNode;
  innerRef?: React.RefObject<HTMLUListElement>;
  additionalListProps?: unknown;
}

export const OptionLabel = styled.span`
  font-size: ${dimensions.fontSizeM};
  line-height: ${dimensions.lineHeightM};
  color: ${colors.cGray800};
`;

OptionLabel.displayName = 'OptionLabel';

export const OptionSubLabel = styled.span`
  font-size: ${dimensions.fontSizeS};
  line-height: ${dimensions.lineHeightXs};
  color: ${colors.cGray700};
  margin: ${dimensions.spaceXxs} 0 0;
`;

OptionSubLabel.displayName = 'OptionSubLabel';

export const OptionItem = styled.li.attrs(addDataCy)<OptionListItem>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${dimensions.spaceXs};
  min-height: ${dimensions.minClickableHeight};
  line-height: ${dimensions.lineHeightM};
  cursor: pointer;
  user-select: none;
  transition: background-color 0.25s ease-in-out;
  background-color: ${p =>
    p.active
      ? p.validationType === ValidationType.error
        ? colors.cErrorBackground
        : colors.cBlue100
      : 'transparent'};

  border: ${p =>
    p.active && p.validationType === ValidationType.error
      ? `solid 1px ${colors.cErrorBorder}`
      : 'inherit'};

  &:not(:last-of-type) {
    border-bottom: ${p =>
      p.active && p.validationType === ValidationType.error
        ? `solid 1px ${colors.cErrorBorder}`
        : `${dimensions.borderWidthS} solid ${colors.cGray500}`};
  }

  & > span {
    color: ${p =>
      p.active && p.validationType === ValidationType.error
        ? colors.cRed700
        : 'inherit'};
  }

  ${p =>
    p.active
      ? p.validationType == ValidationType.error
        ? handleHover(`background-color: ${colors.cErrorBackgroundHover}`)
        : handleHover(`background-color: ${colors.cBlue200}`)
      : handleHover(`background-color: ${colors.cGray100}`)};
`;

OptionItem.displayName = 'OptionItem';

const DefaultOptionItem: StyledFC<OptionListItem> = ({
  onClick,
  active,
  subLabel,
  dataCy,
  children,
  validationType
}) => (
  <OptionItem
    onClick={onClick}
    active={active}
    dataCy={dataCy}
    validationType={validationType}
  >
    <OptionLabel>{children}</OptionLabel>
    {subLabel && <OptionSubLabel>{subLabel}</OptionSubLabel>}
  </OptionItem>
);

type InnerItemListProps = Required<
  Pick<OptionListProps, 'mapToItem' | 'items' | 'itemComponent'>
> &
  Pick<OptionListProps, 'renderItem' | 'validationType'>;

export const OptionIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 100%;
`;

const OptionInnerWrapper = styled.div`
  display: flex;
  ${OptionIcon} {
    flex: 0 0 auto;
  }
  ${OptionLabel} {
    display: flex;
    flex: 1 1 auto;
    margin: 0 0 0 12px;
    align-items: center;
  }
`;

const InnerItemList = React.memo<InnerItemListProps>(
  ({
    mapToItem,
    renderItem,
    items,
    validationType,
    itemComponent: ItemComponent
  }) => (
    <>
      {items.map((item, i: number) => {
        const mappedItem = mapToItem(item);
        const IconComponent = mappedItem.IconComponent;
        return renderItem ? (
          renderItem(mappedItem, i)
        ) : (
          <ItemComponent
            key={i}
            {...(item.dataCy && {dataCy: item.dataCy})}
            onClick={mappedItem.onClick}
            label={mappedItem.label}
            subLabel={mappedItem.subLabel}
            active={mappedItem.active}
            validationType={validationType}
            value={mappedItem.value}
            iconSrc={mappedItem.iconSrc}
            IconComponent={IconComponent}
          >
            {mappedItem.iconSrc || IconComponent ? (
              <OptionInnerWrapper>
                <OptionIcon>
                  <IconBadge shape={Shape.circular} imgSrc={mappedItem.iconSrc}>
                    {IconComponent && !mappedItem.iconSrc && <IconComponent />}
                  </IconBadge>
                </OptionIcon>
                <OptionLabel>{mappedItem.label}</OptionLabel>
              </OptionInnerWrapper>
            ) : (
              mappedItem.label
            )}
          </ItemComponent>
        );
      })}
    </>
  ),
  (prevProps, nextPros) =>
    prevProps.items === nextPros.items &&
    prevProps.validationType === nextPros.validationType
);

const _OptionList: StyledFC<OptionListProps> = ({
  items,
  className,
  dataCy,
  itemComponent = DefaultOptionItem,
  renderItem,
  mapToItem = identity,
  height,
  header,
  validationType,
  innerRef,
  additionalListProps,
  itemPlaceholder = null
}) => {
  const listRef = React.useRef<HTMLUListElement>(null);
  const [hasShadow, handleShadow] = useIsScrolled(innerRef || listRef);

  return (
    <div className={className} data-cy={dataCy}>
      {header && <HeaderWrapper hasShadow={hasShadow}>{header}</HeaderWrapper>}
      <List
        ref={innerRef || listRef}
        withHeader={!!header}
        height={height}
        onScroll={header ? handleShadow : undefined}
        {...additionalListProps}
      >
        <InnerItemList
          items={items}
          itemComponent={itemComponent}
          renderItem={renderItem}
          mapToItem={mapToItem}
          validationType={validationType}
        />
        {itemPlaceholder}
      </List>
    </div>
  );
};

export const OptionList = styled(_OptionList)`
  ${p =>
    p.items.length &&
    css`
      border: ${dimensions.borderWidthM} solid ${colors.cGray500};
    `}
  border-radius: ${dimensions.borderRadiusM};
  flex-shrink: 0;
  overflow: hidden;
  ${p =>
    p.width
      ? css`
          width: ${getStretchCssValue(p.width)};
        `
      : ''}
  ${p =>
    p.height
      ? css`
          max-height: ${getStretchCssValue(p.height)};
        `
      : ''}
`;

OptionList.displayName = 'OptionList';
