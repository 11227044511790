import {AnimationVariants} from '../../types';

export const variants = {
  [AnimationVariants.hidden]: {
    opacity: 0,
    scale: 0
  },
  [AnimationVariants.visible]: {
    opacity: 1,
    scale: 1
  }
};
