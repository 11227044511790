export enum ButtonBackground {
  border = 'border',
  filled = 'filled',
  transparent = 'transparent'
}

export enum ButtonKind {
  abort = 'abort',
  action = 'action',
  confirm = 'confirm',
  disabled = 'disabled',
  final = 'final',
  view = 'view',
  warning = 'warning'
}

export enum ButtonSize {
  xs = 'xs',
  s = 's',
  s2 = 's2',
  m = 'm',
  m2 = 'm2',
  l = 'l',
  l2 = 'l2',
  xl = 'xl',
  xl2 = 'xl2',
  xxl = 'xxl'
}

export enum ButtonStretch {
  stretch = 'stretch',
  responsive = 'responsive'
}
