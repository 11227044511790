import React from 'react';
import colors from '../../sass/colors';

export const StartIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  fill
}) => (
  <svg
    width='39'
    height='39'
    viewBox='0 0 39 39'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M29.4308 4.63748C26.4913 2.67335 23.0353 1.625 19.5 1.625C14.7593 1.625 10.2127 3.50825 6.86048 6.86046C3.50827 10.2127 1.62501 14.7593 1.62501 19.5C1.62501 23.0353 2.67336 26.4913 4.63749 29.4308C6.60162 32.3703 9.39331 34.6614 12.6595 36.0143C15.9258 37.3673 19.5198 37.7212 22.9872 37.0315C26.4547 36.3418 29.6397 34.6394 32.1395 32.1395C34.6394 29.6397 36.3418 26.4547 37.0315 22.9872C37.7212 19.5198 37.3673 15.9258 36.0143 12.6595C34.6614 9.3933 32.3703 6.60161 29.4308 4.63748ZM8.66639 3.28634C11.8731 1.14365 15.6433 0 19.5 0C24.6717 0 29.6316 2.05446 33.2886 5.71141C36.9455 9.36837 39 14.3283 39 19.5C39 23.3567 37.8563 27.1269 35.7137 30.3336C33.571 33.5404 30.5255 36.0397 26.9623 37.5157C23.3992 38.9916 19.4784 39.3777 15.6958 38.6253C11.9131 37.8729 8.43856 36.0157 5.71143 33.2886C2.98431 30.5615 1.1271 27.0869 0.374693 23.3043C-0.377719 19.5216 0.00845826 15.6008 1.48437 12.0377C2.96028 8.47451 5.45963 5.42903 8.66639 3.28634ZM29.25 19.5731L14.625 11.0419V28.1044L29.25 19.5731Z'
      fill={fill || `${colors.cGreen600}`}
    />
  </svg>
);
