import React from 'react';
import styled, {css} from 'styled-components';
import {StyledFC} from '../../types';
import dimensions from '../../sass/dimensions';
import colors from '../../sass/colors';
import {VerticalMenuItem} from './VerticalMenuItem';

export type MenuProps = {
  limitHeight?: boolean; // true limits the height of the list to 7,5 items
  separator?: boolean; // true create menu with separator
  roundedCorners?: boolean; // true add 5px rounded corners
};

const List = styled.div<Pick<MenuProps, 'separator' | 'roundedCorners'>>`
  display: table;
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  ${({roundedCorners}) =>
    roundedCorners &&
    css`
      & {
        border-radius: 5px;
      }
    `};
  ${({separator}) =>
    separator &&
    css`
      ${VerticalMenuItem} {
        border-bottom: ${dimensions.borderWidthS} solid ${colors.cElementBorder};
      }

      ${VerticalMenuItem}:last-of-type {
        border: none;
      }
    `}
`;

const _VerticalMenu: StyledFC<MenuProps> = ({
  separator,
  roundedCorners,
  children,
  className,
  dataCy
}) => (
  <div className={className} role='menu' data-cy={dataCy}>
    <List separator={separator} roundedCorners={roundedCorners}>
      {children}
    </List>
  </div>
);

export const VerticalMenu = styled(_VerticalMenu)`
  width: 100%;
  overflow-y: auto;
  ${({limitHeight}) =>
    limitHeight && `max-height: ${dimensions.menuMaxHeight};`}
`;
