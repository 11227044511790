const callIfOr =
  <T, U>(methodCaller: (_: T) => U, defaultValue: U) =>
  (obj: T | null | undefined) =>
    obj ? methodCaller(obj) : defaultValue;

export const getHeight = callIfOr(
  (rect: HTMLElement) => rect.getBoundingClientRect().height,
  0
);
export const getTop = callIfOr(
  (rect: HTMLElement) => rect.getBoundingClientRect().top,
  0
);
export const getBottom = callIfOr(
  (rect: HTMLElement) => rect.getBoundingClientRect().bottom,
  0
);
