import React from 'react';

export const BreadcrumbCaretSeparatorIcon: React.FC<
  React.SVGAttributes<SVGElement>
> = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='6'
      height='10'
      viewBox='0 0 6 10'
    >
      <path
        d='M121.46967,4.16135518 C121.735936,3.89508862 122.1526,3.87088257 122.446212,4.08873703 L122.53033,4.16135518 L126.03033,7.66135518 C126.296597,7.92762174 126.320803,8.34428543 126.102948,8.63789692 L126.03033,8.72201535 L122.53033,12.2220154 C122.237437,12.5149086 121.762563,12.5149086 121.46967,12.2220154 C121.203403,11.9557488 121.179197,11.5390851 121.397052,11.2454736 L121.46967,11.1613552 L124.439,8.192 L121.46967,5.22201535 C121.203403,4.95574879 121.179197,4.53908511 121.397052,4.24547362 L121.46967,4.16135518 Z'
        transform='translate(-121 -3)'
      />
    </svg>
  );
};
