import React from 'react';

export const WorksheetIcon: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg
    width='18'
    height='22'
    viewBox='0 0 18 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.5 0H0.5V22H17.5V6L11.5 0ZM11.5 1.41L16.09 6H11.5V1.41ZM16.5 21H1.5V1H10.5V7H16.5V21ZM3.5 10H14.5V9H3.5V10ZM3.5 13H14.5V12H3.5V13Z'
      fill='#1188EE'
    />
  </svg>
);
