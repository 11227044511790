import React from 'react';

export const PlanIcon: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg
    width='19'
    height='20'
    viewBox='0 0 19 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14 2V0H12V2H7V0H5V2H0V20H19V2H14ZM5 3V5H7V3H12V5H14V3H18V7H1V3H5ZM1 19V8H18V19H1Z'
      fill='#1188EE'
    />
  </svg>
);
