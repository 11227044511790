import React from 'react';
import styled from 'styled-components';
import {StyledFC} from 'types';
import {ActivityType, LobProps, LobTypeProps} from './types';
import dimensions from '../../sass/dimensions';
import colors from '../../sass/colors';
import {PreviewIcon, StartIcon, ExListIcon, FemListIcon} from '../Icons';

function switchIcon(activityType: ActivityType) {
  switch (activityType) {
    case ActivityType.ExerciseList:
      return <ExListIcon />;
    case ActivityType.FemList:
      return <FemListIcon />;
    default:
      return <ExListIcon />;
  }
}

const _LobType: StyledFC<LobTypeProps> = ({className, activityType}) => {
  return <div className={className}>{switchIcon(activityType)}</div>;
};

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Column = styled(Row)`
  flex-direction: column;
`;

const LobType = styled(_LobType)`
  flex: 0 0 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Details = styled(Column)`
  flex: 1 1 auto;
  justify-content: center;
  padding: 0 ${dimensions.spaceM};
`;

const ActionButton = styled.button<{active?: boolean}>`
  border: 0;
  cursor: pointer;
  outline: 0;
  background: transparent;
  padding: 0;
`;

const Actions = styled(Row)`
  flex: 0 0 auto;
  ${ActionButton} + ${ActionButton} {
    margin: 0 0 0 ${dimensions.spaceM};
  }
`;

const Title = styled.h6`
  font-weight: 400;
  font-size: ${dimensions.fontSizeM};
  line-height: ${dimensions.lineHeightM2};
  color: ${colors.cGray800};
  margin: 0;
`;

const MetaInfo = styled.span`
  font-size: ${dimensions.fontSizeS};
  color: ${colors.cGray600};
`;

const Caption = styled.span`
  /* display: inline-flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center; */
  margin-right: ${dimensions.spaceXxs};
`;

const _Lob: StyledFC<LobProps> = ({
  className,
  caption,
  title,

  metaInfo,
  activityType,
  actionPreview,
  actionStart
}) => {
  return (
    <div className={className}>
      <LobType activityType={activityType} />
      <Details>
        <Title>
          {caption && <Caption>{caption}</Caption>}
          {title}
        </Title>
        {metaInfo && <MetaInfo>{metaInfo}</MetaInfo>}
      </Details>
      {(actionPreview || actionStart) && (
        <Actions>
          {actionPreview && (
            <ActionButton onClick={actionPreview}>
              <PreviewIcon />
            </ActionButton>
          )}
          {actionStart && (
            <ActionButton onClick={actionStart}>
              <StartIcon />
            </ActionButton>
          )}
        </Actions>
      )}
    </div>
  );
};

export const Lob = styled(_Lob)`
  display: flex;
  padding: ${dimensions.spaceS};
  box-shadow: 0px -1px 0px 0px ${colors.cGray400} inset;
  ${Title} + ${MetaInfo} {
    margin: ${dimensions.spaceXxs} 0 0;
  }
`;
