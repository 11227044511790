import React from 'react';
import styled from 'styled-components';
import {Heading} from '../Heading';
import dimensions from '../../sass/dimensions';
import colors from '../../sass/colors';
import {FlexDirection, FlexAlign, StyledFC} from '../../types';

export interface TeaserLayoutProps {
  title: string;
  direction?: FlexDirection;
}

const Content = styled.div`
  display: flex;
  width: 100%;
`;

export const IllustrationWrapper = styled.div<{imageAlignment?: FlexAlign}>`
  display: flex;
  align-self: ${({imageAlignment}) => imageAlignment || FlexAlign.center};
  & + div {
    margin-left: ${dimensions.spaceXl};
  }

  & img {
    max-width: 100%;
    align-self: center; /* prevents flexbox image distortion */
  }
`;

IllustrationWrapper.displayName = 'IllustrationWrapper';

export const IllustrationsLayout = styled.div`
  display: flex;
  margin-bottom: ${dimensions.spaceXl};
  ${IllustrationWrapper} {
    width: calc(50% - ${dimensions.spaceS});
    display: flex; /* prevents unnecessary whitespace with img */
  }
`;

IllustrationsLayout.displayName = 'IllustrationsLayout';

const _TeaserLayout: StyledFC<TeaserLayoutProps> = ({
  className,
  children,
  title
}) => (
  <div className={className}>
    <Heading as='h2'>{title}</Heading>
    <Content>{children}</Content>
  </div>
);

export const TeaserLayout = styled(_TeaserLayout)`
  display: flex;
  width: 100%;
  flex-direction: column;
  ${Heading} {
    font-size: 36px;
    line-height: ${dimensions.lineHeightXxl};
    color: ${colors.cGray800};
    margin-bottom: ${dimensions.spaceXxl2};
  }

  ${Content} {
    flex-direction: ${p => p.direction || FlexDirection.row};
  }
`;

TeaserLayout.displayName = 'TeaserLayout';
