import {BorderDirection} from '../../types';

export enum ContextNotificationKind {
  correct = 'correct',
  error = 'error',
  remark = 'remark',
  action = 'action',
  neutral = 'neutral',
  attention = 'attention'
}

export type ContextNotificationProps = {
  kind: ContextNotificationKind;
  increasedPadding?: boolean;
  width?: number;
  stretch?: boolean;
  displayFlex?: boolean;
  padding?: string;
  preserveFontColor?: boolean;
  borderOrientation?: BorderDirection;
};
