import styled from 'styled-components';
import isNil from 'lodash/isNil';
import defaultTheme from '../../themes/defaultTheme';

/**
 * OverlayShadow box is used to provide shadow & clickHandler in background whenever some more
 * contextual information is presented to the user in front of the screen.
 * We have two primary use cases:
 * - Dialog: When user is presented with Dialog, we want to make sure maximum attention of user is
 * on the dialog and not on the background context. Hence, in this case, we show overlay with
 * lower transparency.
 * - Popup: Popups by definition are for a given context. In this case, we want to put attention
 * of user to popup but also make sure the context in which this popup is shown is visible.
 * Hence, in this case, we show overlay with higher transparency.
 *
 * ### Properties
 | Name               | Type           | Default   | Description                   |
 |---                 |---             |---        |---                            |
 | `onClick`          | `Function`     | Required  | callback to define hide/show overlay|
 | `kind`             | one of '`dialog` or `popup`| Required  | controls the style used|
 */

const OVERLAY_BACKDROP_BG_COLOR_DIALOG = 'rgba(102, 102, 102, 0.6)';
const OVERLAY_BACKDROP_BG_COLOR_POPUP = 'rgba(0, 0, 0, 0.02)';
const ANIMATION_SPEED = '0.2s';

export interface OverlayShadowProps {
  kind: 'dialog' | 'popup';
  onClick?: () => void;
}

const {dimensions} = defaultTheme;

export const OverlayShadow = styled.div.attrs({
  role: 'presentation'
})<OverlayShadowProps>`
  position: absolute;
  z-index: ${dimensions.zIndexMiddle};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity ${ANIMATION_SPEED} ease-in-out;
  background-color: ${({kind}) =>
    kind === 'dialog'
      ? OVERLAY_BACKDROP_BG_COLOR_DIALOG
      : OVERLAY_BACKDROP_BG_COLOR_POPUP};

  ${({onClick}) =>
    !isNil(onClick) &&
    `
      cursor: pointer;
    `}
`;

export default OverlayShadow;
