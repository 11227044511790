import styled from 'styled-components';
import {ValidationType} from '../../types';
import {addDataCy} from '../../utils';
import dimensions from '../../sass/dimensions';
import {
  FormValidationMessage,
  FormValidationMessageProps
} from '../FormValidationMessage/FormValidationMessage';
import {typeBackgroundColorMap} from './styleMaps';

export const FormValidationMessageExtended = styled(
  FormValidationMessage
).attrs(addDataCy)<FormValidationMessageProps>`
  display: flex;
  flex-direction: column;
  background: ${p =>
    typeBackgroundColorMap[p.validationType || ValidationType.error]};
  line-height: ${dimensions.lineHeightS};
  padding: ${dimensions.spaceS};
`;

FormValidationMessageExtended.displayName = 'FormValidationMessageExtended';
