import React from 'react';
import styled from 'styled-components';
import defaultTheme from '../../themes/defaultTheme';
import {StyledFC} from '../../types';
import {handleHover, numberFromStyles} from '../../utils';
import {
  ICON_SHIFT_IN_CLICK_AREA,
  STEPPER_BTN_BORDER_RADIUS,
  STEPPER_BTN_CLICK_AREA,
  STEPPER_PADDING
} from './constants';

export enum ClickArea {
  left = 'left',
  center = 'center',
  right = 'right'
}

export interface NavigationButtonProps {
  onClick?: () => void;
  clickArea?: ClickArea;
}

const {colors, dimensions} = defaultTheme;

const Button = styled.div<NavigationButtonProps>`
  width: ${STEPPER_BTN_CLICK_AREA - 2 * STEPPER_PADDING}px;
  height: ${STEPPER_BTN_CLICK_AREA - 2 * STEPPER_PADDING}px;
  color: ${colors.cGray800};
  border-radius: ${STEPPER_BTN_BORDER_RADIUS}px;
  outline: none;
  pointer-events: none;
  background-color: ${colors.cGray300};

  svg {
    display: block;
    margin: auto;
    height: 100%;
    fill: ${colors.cGray700};
  }

  marginLeft: ${({clickArea}) =>
    clickArea && clickArea === ClickArea.left ? ICON_SHIFT_IN_CLICK_AREA : `0`}
  marginRight:${({clickArea}) =>
    clickArea && clickArea === ClickArea.right ? ICON_SHIFT_IN_CLICK_AREA : `0`}
`;

const _NavigationButton: StyledFC<NavigationButtonProps> = ({
  children,
  onClick,
  clickArea = ClickArea.center,
  className,
  dataCy
}) => (
  <div role='button' className={className} onClick={onClick} data-cy={dataCy}>
    <Button clickArea={clickArea}>{children}</Button>
  </div>
);

export const NavigationButton = styled(_NavigationButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  width: ${STEPPER_BTN_CLICK_AREA +
  2 * numberFromStyles(dimensions.borderWidthM)}px;
  height: ${dimensions.minClickableHeight};
  background-color: transparent;
  cursor: pointer;
  ${handleHover(`background-color: ${colors.cGray400};`, Button)}
`;

NavigationButton.displayName = 'NavigationButton';
