import React from 'react';
import noop from 'lodash/noop';
import {ModalSize} from '../../types';

interface ModalContext {
  isOpen: boolean;
  size: ModalSize;
  zIndex: number;
  toggle: () => void;
  close: () => void;
  open: () => void;
  setSize: (size: ModalSize) => void;
}

export const ModalContext = React.createContext<ModalContext>({
  isOpen: false,
  size: ModalSize.m,
  zIndex: 0,
  toggle: noop,
  close: noop,
  open: noop,
  setSize: noop
});
