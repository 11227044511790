import React from 'react';
import styled, {css} from 'styled-components';
import {StyledFC} from 'types';

import {CaretDownIcon} from '../Icons';

import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';

export interface DrawerProps {
  children: any;
  isActive: boolean;
  onClose: () => void;
  onExpand: () => void;
  buttonLabel?: string;
  maxHeight?: number;
}

export interface DrawerToggleProps {
  icon?: any;
  onClick: () => void;
}

const DrawerContent = styled.div`
  flex: 1 1 auto;
  padding: ${dimensions.spaceS} 0 ${dimensions.spaceS}
    ${parseInt(dimensions.spaceM, 10) - 6}px;
  overflow: auto;
`;

const IconBox = styled.span`
  width: ${dimensions.btnMinHeightM};
  height: ${dimensions.btnMinHeightM};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${colors.cBlue700};

  svg {
    transform: rotate(-180deg);
  }

  svg path {
    fill: ${colors.cBlue700};
  }
`;

const _DrawerToggle: StyledFC<DrawerToggleProps> = ({
  className,
  icon,
  onClick,
  children
}) => {
  return (
    <button onClick={onClick} className={className}>
      {children}
      <IconBox>{icon || <CaretDownIcon />}</IconBox>
    </button>
  );
};

const DrawerToggle = styled(_DrawerToggle)`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
`;

interface TabButtonProps {
  onClick: () => void;
}

const _TabButton: StyledFC<TabButtonProps> = ({
  className,
  onClick,
  children
}) => {
  return (
    <button onClick={onClick} className={className}>
      {children}
      <CaretDownIcon />
    </button>
  );
};

const TabButton = styled(_TabButton)<TabButtonProps>`
  font-family: 'Roboto';
  font-size: ${dimensions.fontSizeM};
  padding: ${dimensions.spaceXs} ${dimensions.spaceM};
  background: ${colors.cBlue700};
  color: ${colors.cWhite};
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 0 0 ${dimensions.spaceM} ${dimensions.spaceM};

  svg {
    margin-left: ${dimensions.spaceM};
  }

  polygon {
    fill: ${colors.cWhite};
  }
`;

const _Drawer: StyledFC<DrawerProps> = ({
  className,
  children,
  buttonLabel,
  maxHeight,
  isActive,
  onClose,
  onExpand
}) => {
  return (
    <div className={className}>
      <DrawerOuter isActive={isActive}>
        <DrawerWrapper isActive={isActive} maxHeight={maxHeight}>
          <DrawerContent>{children}</DrawerContent>
          <DrawerToggle onClick={onClose} />
        </DrawerWrapper>
        <DrawerCompact isActive={isActive} maxHeight={maxHeight}>
          <TabButton onClick={onExpand}>{buttonLabel}</TabButton>
        </DrawerCompact>
      </DrawerOuter>
    </div>
  );
};
const DrawerOuter = styled.div<Pick<DrawerProps, 'isActive'>>`
  ${p =>
    !p.isActive
      ? css`
          transition: margin-bottom 0.4s ease-in;
          margin-bottom: -35px;
        `
      : css`
          transition: margin-bottom 0.5s ease-in;
          margin-bottom: 0;
        `};
  flex: 1;
`;
const DrawerWrapper = styled.div<Pick<DrawerProps, 'isActive' | 'maxHeight'>>`
  box-shadow: inset 0 -1px 0 ${colors.cGray400};
  display: flex;
  height: auto;
  ${p =>
    !p.isActive
      ? css`
          transition: max-height 0.4s ease-in;
          button {
            visibility: hidden;
          }
        `
      : css`
          transition: max-height 0.5s ease-in;
        `};
  max-height: ${p => (!p.isActive ? 0 : 300 + 'px')};
  overflow: hidden;
  background: ${colors.cWhite};

  @media (min-width: 610px) {
    max-height: ${p => (!p.isActive ? 0 : 160 + 'px')};
  }

  @media (min-width: ${dimensions.largeScreenWidth}) {
    max-height: ${p => (!p.isActive ? 0 : 120 + 'px')};
  }
`;

const DrawerCompact = styled.div<Pick<DrawerProps, 'isActive' | 'maxHeight'>>`
  height: auto;
  // max-height: ${p => (!p.isActive ? (p.maxHeight || 35) + 'px' : 0)};
  max-height: ${p => (!p.isActive ? '35px' : 0)};
  ${p =>
    !p.isActive
      ? css`
          transition: max-height 0.4s ease-in;
        `
      : css`
          transition: max-height 0.25s ease-out 0.25s;
        `};
  overflow: hidden;
  display: flex;
  justify-content: center;

  ${TabButton} {
    ${p =>
      !p.isActive
        ? css`
            transition: transform 0.3s ease-out 0.15s;
          `
        : css`
            transition: transform 0.25s ease-in 0.25s;
          `};
    transform: translateY(${p => (!p.isActive ? 0 : '-40px')});
  }
`;

export const Drawer = styled(_Drawer)<DrawerProps>`
  display: flex;

  ${DrawerToggle} {
    margin: 0 10px;

    polygon {
      fill: ${colors.cBlue700};
    }
  }
`;
