import React from 'react';
import styled from 'styled-components';
import {StyledFC} from '../../types';
import dimensions from '../../sass/dimensions';
import {Betty} from './Betty';
import {QRCode} from './QRCode';
import {addDataCy} from '../../utils';

export interface AccountSnippetProps {
  title: string;
  firstName?: string;
  lastName?: string;
  userName: LineProps;
  nameOfClass: LineProps;
  systemLogin: {label: string; value: string};
  temporaryPassword: LineProps;
  myPassword: string;
}

const Section = styled.section`
  display: flex;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Title = styled.h2`
  font-size: ${dimensions.fontSizeS};
  margin: 0 0 2px;
`;

const PersonalData = styled.p`
  font-size: 8px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Label = styled.strong`
  font-size: 7px;
`;

const Value = styled.span.attrs(addDataCy)`
  font-size: 7px;
`;

const TopSection = styled(Section)`
  flex-direction: column;
  ${Row} {
    margin-top: ${dimensions.spaceXxs};
  }
`;

const QRCodeSection = styled(Section)`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const BottomSection = styled(Section)`
  margin-top: auto;
  ${Section} {
    flex: 1;
    flex-direction: column;
    max-width: 151px;
  }
  ${QRCodeSection} {
    flex: 0 0 58px;
    align-items: center;
    margin-left: 10px;
  }
`;

interface LineProps {
  dataCy?: string;
  label: string;
  value?: string;
}

const Line: StyledFC<LineProps> = ({dataCy, label, value}) => (
  <Row>
    <Label>{label}</Label>
    <Value dataCy={dataCy}>{value}</Value>
  </Row>
);

const MyPassword = styled(Label)`
  height: 28px;
  border-bottom: ${dimensions.borderWidthS} solid black;
  margin-top: auto;
`;

const _AccountSnippet: StyledFC<AccountSnippetProps> = ({
  title,
  firstName,
  lastName,
  userName,
  nameOfClass,
  systemLogin,
  temporaryPassword,
  myPassword,
  className
}) => (
  <div className={className}>
    <TopSection>
      <Title>{title}</Title>
      <PersonalData>
        {firstName} {lastName}
      </PersonalData>
      <Line {...nameOfClass} />
    </TopSection>
    <BottomSection>
      <Section>
        <Line {...systemLogin} />
        <Line {...userName} />
        <Line {...temporaryPassword} />
        <MyPassword>{myPassword}</MyPassword>
      </Section>
      <QRCodeSection>
        <Betty />
        <QRCode systemLogin={systemLogin} />
      </QRCodeSection>
    </BottomSection>
  </div>
);

export const AccountSnippet = styled(_AccountSnippet)`
  font-family: 'Helvetica', 'sans-serif';
  display: flex;
  flex-direction: column;
  width: 244px;
  height: 154px;
  padding: ${dimensions.spaceS};
  border: 0.5px dashed black;
  ${BottomSection} ${Row}:first-child {
    margin-top: 2px;
  }
  ${Row} + ${Row} {
    margin-top: ${dimensions.spaceXxs};
  }
  ${Label} {
    margin-right: ${dimensions.spaceXxs};
  }
`;

AccountSnippet.displayName = 'AccountSnippet';
