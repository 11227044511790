/**
 * TODO: NOTE for possible improvement:
 * we can avoid using this enum by providing input component from the outside.
 * It'd allow us to use any input component needed e.g. number or datepicker...
 */
export enum BoxedInputType {
  text = 'text',
  password = 'password',
  select = 'select',
  search = 'search'
}
