import React from 'react';
import {FlexDirection, Stretch, StyledFC} from '../../types';
import {StyledFeatureLayout} from '../FeatureLayout';
import {
  IllustrationsLayout,
  IllustrationWrapper,
  TeaserLayout
} from './TeaserLayout';

export interface TeaserColumnLayoutCombinedProps {
  title: string;
  description: string;
  primaryIllustration: React.ReactNode;
  secondaryIllustration?: React.ReactNode;
}

export const TeaserColumnLayoutCombined: StyledFC<TeaserColumnLayoutCombinedProps> =
  ({
    title,
    description,
    children,
    primaryIllustration,
    secondaryIllustration
  }) => (
    <TeaserLayout title={title} direction={FlexDirection.column}>
      <IllustrationsLayout>
        <IllustrationWrapper>{primaryIllustration}</IllustrationWrapper>
        {secondaryIllustration && (
          <IllustrationWrapper>{secondaryIllustration}</IllustrationWrapper>
        )}
      </IllustrationsLayout>
      <StyledFeatureLayout width={Stretch.stretched} description={description}>
        {children}
      </StyledFeatureLayout>
    </TeaserLayout>
  );

TeaserColumnLayoutCombined.displayName = 'TeaserColumnLayoutCombined';
