import React from 'react';
import styled, {css} from 'styled-components';
import {StyledFC} from 'types';

import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';

export enum KnobType {
  link = 'link',
  ghost = 'ghost',
  button = 'button',
  iconBtn = 'iconBtn'
}

export enum IconBtnStyle {
  border = 'border',
  fill = 'fill'
}

export enum KnobSize {
  tiny = 'tiny',
  small = 'small',
  regular = 'regular',
  medium = 'medium',
  large = 'large'
}

export interface KnobProps {
  label?: string;
  type: KnobType;
  size?: KnobSize;
  iconPrepended?: any;
  iconAppended?: any;
  adjustIconXPosition?: number;
  width?: number;
  height?: number;
  colorizeIcon?: boolean;
  iconBtnStyle?: IconBtnStyle;
  onClick?: (...args: any[]) => void;
}

const Label = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const _Knob: StyledFC<KnobProps> = ({
  className,
  label,
  iconPrepended,
  iconAppended,
  onClick
}) => {
  return (
    <button onClick={onClick} className={className}>
      {iconPrepended && <Icon>{iconPrepended}</Icon>}
      {label && <Label>{label}</Label>}
      {iconAppended && <Icon>{iconAppended}</Icon>}
    </button>
  );
};

export const Knob = styled(_Knob)`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-size: ${dimensions.fontSizeM};
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  &,
  path,
  polygon {
    transition: 0.25s all ease-in-out;
  }
  ${Icon} + ${Label} {
    margin-left: ${dimensions.spaceXs};
  }
  ${Label} + ${Icon} {
    margin-left: ${dimensions.spaceXs};
  }

  ${p =>
    p.type === KnobType.button
      ? css`
          background: ${colors.cBlue700};
          color: ${colors.cWhite};
          padding: 8.5px ${dimensions.spaceS};
          border-radius: 5px;
          ${p.colorizeIcon &&
          `path {
            fill: ${colors.cWhite};
          }`}

          &:hover {
            background: ${colors.cBlue600};
          }
        `
      : p.type === KnobType.ghost
      ? css`
          border-radius: 5px;
          color: ${colors.cGray700};
          padding: 8.5px ${dimensions.spaceS};
          background: transparent;
          &:hover {
            background: ${colors.cGray100};
          }
        `
      : p.type === KnobType.link
      ? css`
          color: ${colors.cBlue700};
          &:hover {
            color: ${colors.cBlue600};
            path,
            polygon {
              fill: ${colors.cBlue600};
            }
          }
          ${p.colorizeIcon &&
          `path {
            fill: ${colors.cBlue700};
          }`}
        `
      : p.type === KnobType.iconBtn &&
        css`
          cursor: pointer;
          padding: 0;
          margin: 0;
          ${p.iconBtnStyle === IconBtnStyle.border
            ? ` border: 1px solid ${colors.cBlue700};
                background: transparent;`
            : p.iconBtnStyle === IconBtnStyle.fill &&
              `
              background: ${colors.cWhite};
            `}

          outline: none;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          width: ${p.width || 28}px;
          height: ${p.height || 28}px;
          flex-shrink: 0;
          &:hover {
            background: ${colors.cBlue200};
            border-color: ${colors.cBlue200};
          }
        `}
  ${p => {
    switch (p.size) {
      case KnobSize.tiny:
        return css`
          font-size: ${dimensions.fontSizeS};
          padding: 6px ${dimensions.spaceS};
        `;
      case KnobSize.small:
        return css`
          font-size: ${dimensions.fontSizeS};
          padding: 7px ${dimensions.spaceS};
        `;
      case KnobSize.regular:
        return css`
          font-size: ${dimensions.fontSizeM};
          padding: 8.5px ${dimensions.spaceS};
        `;
      case KnobSize.medium:
        return css`
          font-size: ${dimensions.fontSizeM};
          padding: 8.5px ${dimensions.spaceS};
        `;
      case KnobSize.large:
        return css`
          font-size: ${dimensions.fontSizeM};
          padding: 8.5px ${dimensions.spaceS};
        `;
      default:
        css`
          font-size: ${dimensions.fontSizeM};
          padding: 8.5px ${dimensions.spaceS};
        `;
    }
  }}
  ${p =>
    p.adjustIconXPosition &&
    css`
      svg {
        margin-left: ${p.adjustIconXPosition}px;
      }
    `};
`;
