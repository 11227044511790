import {AnimationVariants} from '../../types';

export const initial = {opacity: 0, display: 'none'};
export const beforeAnimation = {display: 'block'};
export const afterAnimation = {display: 'none'};

export const tipVariants = {
  [AnimationVariants.visible]: {opacity: 1, translateY: 0},
  [AnimationVariants.hidden]: {opacity: 0, translateY: 45}
};

export const contentLeftVariants = {
  [AnimationVariants.visible]: {opacity: 1, translateX: 0},
  [AnimationVariants.hidden]: {
    opacity: 0,
    translateX: -45
  }
};

export const contentBottomVariants = {
  [AnimationVariants.visible]: {opacity: 1, translateY: 0},
  [AnimationVariants.hidden]: {
    opacity: 0,
    translateY: 45
  }
};
