import React from 'react';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';
import styled from 'styled-components';
import {StyledFC} from '../../types';
import {handleHover} from '../../utils/stylingHelpers';

interface SectionHeaderProps {
  title?: string;
  onClick?: () => void;
}

export const SectionHeader: StyledFC<SectionHeaderProps> = ({
  children,
  className,
  dataCy,
  title,
  onClick
}) => (
  <div className={className} onClick={onClick} data-cy={dataCy}>
    <div className='title'>{title}</div>
    {children}
  </div>
);

export const StyledSectionHeader = styled(SectionHeader)`
  display: flex;
  width: 100%;
  min-height: ${dimensions.minClickableHeight};

  ${props =>
    props.onClick &&
    handleHover(
      `
    background: ${colors.cGray200};
    border-color: ${colors.cGray200};
  `,
      '.button .toggle'
    )}

  .title {
    flex: 1 1 auto;
    align-self: center;
    padding: ${dimensions.spaceXs};
    font-weight: ${dimensions.fontWeightBold};
    color: ${props => props.theme.color};
  }
`;
