export enum TipDirection {
  top = 'top',
  left = 'left'
}

export enum MenuAlign {
  left = 'left',
  center = 'center',
  right = 'right'
}
