import React from 'react';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';
import styled from 'styled-components';
import {StyledFC} from '../../types';
import {handleHover} from '../../utils';

interface CollapseButtonProps {
  onClick?: () => void;
}

interface StyledProps {
  collapsed: boolean;
}

export const CollapseButton: StyledFC<CollapseButtonProps> = ({
  onClick,
  className,
  dataCy
}) => (
  <div className={className}>
    <div className='button' role='button' onClick={onClick} data-cy={dataCy}>
      <div className='toggle'>
        <svg width='20' height='20'>
          <line x1='0' y1='10' x2='20' y2='10' strokeWidth='2' />
          <line x1='0' y1='10' x2='20' y2='10' strokeWidth='2' />
        </svg>
      </div>
    </div>
  </div>
);
CollapseButton.displayName = 'CollapseButton';

export const StyledCollapseButton = styled(CollapseButton)<StyledProps>`
  align-self: flex-start;

  .button {
    min-width: ${dimensions.minClickableWidth};
    min-height: ${dimensions.minClickableHeight};
    position: relative;
    display: inline-block;
    top: -${dimensions.borderWidthL};
    right: -${dimensions.borderWidthL};
    margin-bottom: -${2 * parseInt(dimensions.borderWidthL, 10)}px;
    text-align: center;
    box-sizing: border-box;
    padding: ${dimensions.spaceS};
    cursor: pointer;

    ${handleHover(
      `
      background: ${colors.cGray200};
      border-color: ${colors.cGray200};
    `,
      '.toggle'
    )}
  }

  .toggle {
    width: ${dimensions.lineHeightM};
    height: ${dimensions.lineHeightM};
    background: transparent;
    border: transparent solid ${dimensions.borderWidthL};
    border-radius: ${dimensions.borderRadiusM};

    line {
      transition: transform 0.6s;
      transform: rotate(0deg);
      transform-origin: center;
      stroke: ${props => props.theme.stroke};
    }

    ${props =>
      props.collapsed &&
      `
      line:first-child {
        transform: rotate(90deg);
      }
      line:last-child {
        transform: rotate(180deg);
      }
    `}
  }
`;
