import React from 'react';
import styled from 'styled-components';
import {StyledFC} from 'types';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';

interface NavigationProps {
  menuItems: any;
}

interface NavigationItemProps {
  icon?: any;
  label?: string;
  active?: string;
  onClick?: () => void;
}

const _NavigationItem: StyledFC<NavigationItemProps> = ({
  className,
  icon,
  label,
  onClick
}) => {
  return (
    <li className={className} onClick={onClick}>
      {icon && <NavIcon>{icon}</NavIcon>}
      {label && <NavLabel>{label}</NavLabel>}
    </li>
  );
};

const NavigationItem = styled(_NavigationItem)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: ${dimensions.spaceXs} ${dimensions.spaceM};
  color: ${colors.cBlue700};
  background: ${p => (p.active ? colors.cBlue200 : colors.cWhite)};
  cursor: pointer;
  font-size: ${dimensions.fontSizeS};
`;

const NavIcon = styled.span`
  flex: 1 0 auto;
`;
const NavLabel = styled.span`
  flex: 0;
`;

const _Navigation: StyledFC<NavigationProps> = ({className, menuItems}) => {
  return (
    <ul className={className}>
      {menuItems.map((item: any) => (
        <NavigationItem
          key={item.label}
          icon={item.icon}
          label={item.label}
          active={item.active}
          onClick={item.onClick}
        />
      ))}
    </ul>
  );
};

export const Navigation = styled(_Navigation)`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
`;
