import React from 'react';
import colors from '../../sass/colors';

export const PreviewIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  fill
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='26'
    height='26'
    viewBox='0 0 26 26'
  >
    <path
      d='M19.49,17.32a10.89,10.89,0,1,0-2.17,2.17L23.83,26,26,23.83ZM10.83,19.5a8.67,8.67,0,1,1,8.67-8.67A8.67,8.67,0,0,1,10.83,19.5Z'
      fill={fill || `${colors.cBlue700}`}
    />
  </svg>
);
