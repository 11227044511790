import React from 'react';
import colors from '../../sass/colors';

export const HidePasswordIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  width,
  height,
  fill
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width || 24}
      height={height || 19}
      viewBox='0 0 24 19'
    >
      <path
        d='m12.119 12.6821c-4.55 0-8.517-3.924-9.84-5.39.843-.934 2.76-2.864 5.215-4.143-.417.756-.655 1.624-.655 2.548 0 2.916 2.364 5.28 5.28 5.28s5.28-2.364 5.28-5.28c0-.924-.238-1.792-.655-2.548 2.455 1.279 4.372 3.209 5.215 4.143-1.324 1.467-5.291 5.39-9.84 5.39zm11.692-5.934c-.212-.27-5.263-6.606-11.692-6.606s-11.48 6.336-11.692 6.606l-.427.544.427.543c.212.27 5.263 6.607 11.692 6.607s11.48-6.337 11.692-6.607l.427-.543z'
        fill={fill || colors.cBlue600}
        fillRule='evenodd'
        transform='translate(0 2)'
      />
    </svg>
  );
};
