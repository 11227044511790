import styled, {css} from 'styled-components';
import colors from '../../sass/colors';
import {addDataCy} from '../../utils';
import dimensions from '../../sass/dimensions';

enum TypographicScale {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export interface ParagraphProps {
  customColor?: any;
}

export interface TextProps extends ParagraphProps {
  size?: TypographicScale;
  inheritColor?: boolean;
}

const DEFAULT_SCALE = css`
  font-size: ${dimensions.fontSizeM};
  line-height: ${dimensions.lineHeightL};
`;

export const Paragraph = styled.p.attrs(addDataCy)<ParagraphProps>`
  ${DEFAULT_SCALE};
  margin: 0 0 ${dimensions.spaceS};
  color: ${props => props.customColor || colors.cGray800};
  em {
    font-style: normal;
    color: ${colors.cGray800};
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const handleFontSize = (size?: TypographicScale | string) => {
  switch (size) {
    case TypographicScale.small:
      return css`
        font-size: ${dimensions.fontSizeXs};
        line-height: ${dimensions.lineHeightM};
      `;
    case TypographicScale.medium:
      return DEFAULT_SCALE;
    case TypographicScale.large:
      return css`
        font-size: ${dimensions.fontSizeL};
        line-height: ${dimensions.lineHeightL2};
      `;
    default:
      return DEFAULT_SCALE;
  }
};

export const Text = styled.p.attrs(addDataCy)<TextProps>`
  ${({size}) => handleFontSize(size)};
  margin: 0; /* reset default browser styling */
  color: ${p =>
    p.inheritColor
      ? 'inherit'
      : p.customColor
      ? p.customColor
      : colors.cGray800};
`;
