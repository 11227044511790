import React from 'react';
import noop from 'lodash/noop';

export interface DropdownContext {
  triggerRef: React.Ref<HTMLDivElement>;
  contentRef: React.Ref<HTMLDivElement>;
  toggle: () => void;
  isOpened: boolean;
  contentDynamicStyles: React.CSSProperties;
}

export const DropdownContext = React.createContext<DropdownContext>({
  triggerRef: null,
  contentRef: null,
  toggle: noop,
  isOpened: false,
  contentDynamicStyles: {}
});
