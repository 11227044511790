import React from 'react';
import styled, {css} from 'styled-components';
import defaultTheme from '../../themes/defaultTheme';
import {StyledFC} from '../../types';
import {TipDirection} from './types';

interface Tip {
  direction: TipDirection;
  offset?: number | string;
}

interface ContextualMenuProps {
  width?: number;
  direction: TipDirection;
  tip?: Tip;
  innerRef?: any;
  children?: React.ReactNode;
}

const {colors} = defaultTheme;

const StyledContextualMenu = styled.div<ContextualMenuProps>`
  min-width: 180px;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: ${props => props.width}px;
  background: ${colors.cWhite};
  border: 1px solid ${colors.cGray500};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  ${({tip}) =>
    tip &&
    css`
      &:before,
      &:after {
        bottom: 100%;
        ${tip && tip.offset
          ? `${tip.direction}:${tip.offset}px;`
          : 'left: 50%;'}
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      &:before {
        border-color: rgba(170, 170, 170, 0);
        border-bottom-color: #aaa;
        border-width: 9px;
        margin-left: -9px;
      }
      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
        border-width: 8px;
        margin-left: -8px;
      }
    `}

  ${p =>
    p.direction === TipDirection.left &&
    css`
      &:before,
      &:after {
        bottom: 0%;
        position: absolute;
        top: 8px;
        right: 0;
        margin-right: -18px;
        transform: rotate(90deg);
      }
      &:before {
        border-width: 9px;
        margin-left: -9px;
      }
      &:after {
        border-width: 9px;
        margin-left: -9px;
      }
    `}
`;

export const _ContextualMenu: StyledFC<ContextualMenuProps> = ({
  tip,
  direction,
  children,
  innerRef,
  width
}) => {
  return (
    <StyledContextualMenu
      className='contextual-menu'
      direction={direction}
      width={width}
      tip={tip}
      ref={innerRef}
    >
      {children}
    </StyledContextualMenu>
  );
};

export const ContextualMenu = React.forwardRef(
  (props: ContextualMenuProps, ref: React.RefObject<HTMLDivElement>) => (
    <_ContextualMenu {...props} innerRef={ref} />
  )
);
