import React from 'react';

export const CaretLeftIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  fill,
  width,
  height
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width || 6}
      height={height || 9}
      viewBox='0 0 6 9'
    >
      <polygon
        fill={fill || '#4AF'}
        points='-.354 .354 .354 -.354 4.207 3.5 .354 7.354 -.354 6.646 2.793 3.5'
        transform='matrix(-1 0 0 1 4.854 1)'
      />
    </svg>
  );
};
