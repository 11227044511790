import React from 'react';
import colors from '../../sass/colors';

export const FemListIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  fill
}) => (
  <svg
    width='28'
    height='32'
    viewBox='0 0 28 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.47943 0V28.4444H27.091V7.11111L19.9505 0H4.47943ZM5.66951 1.18519H18.7598V8.2963H25.9009V27.2593H5.66951V1.18519ZM19.9505 1.67763L25.4064 7.11111H19.9505V1.67763ZM0.90918 2.96296V32H24.1152V29.6296H22.9257V30.8148H2.09867V4.14815H3.28875V2.96296H0.90918ZM9.23976 13.037H22.3307V11.8519H9.23976V13.037ZM9.23976 17.7778H22.3307V16.5926H9.23976V17.7778ZM9.23976 22.5185H22.3307V21.3333H9.23976V22.5185Z'
      fill={fill || `${colors.cGray700}`}
    />
  </svg>
);
