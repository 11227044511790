import {createGlobalStyle, FlattenInterpolation} from 'styled-components';
import dimensions from './sass/dimensions';
import colors from './sass/colors';

export interface GlobalStylesProps {
  mainSelector?: string;
  setGlobalColor?: string;
  setGlobalFontSize?: string;
  customStyles?: FlattenInterpolation<any>;
}

export const GlobalStyles = createGlobalStyle<GlobalStylesProps>`
  ${p => p.mainSelector} * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;

  }
  
  ${p => p.mainSelector} {
    font-size: ${p => p.setGlobalFontSize || dimensions.fontSizeM};
    color: ${p => p.setGlobalColor || colors.cGray800};
    ${p => p.customStyles}
  }
`;
