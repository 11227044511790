import React from 'react';
import styled from 'styled-components';
import {motion, AnimatePresence, useAnimation} from 'framer-motion';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';
import {overlayAnimation, transition} from '../../consts/animation';
import {addDataCy} from '../../utils';
import {AnimationVariants, ZIndexProps, StyledFC} from '../../types';
import {StickDirection, useTooltipHelper} from './useTooltipHelper';
import {
  afterAnimation,
  beforeAnimation,
  contentLeftVariants,
  contentBottomVariants,
  initial,
  tipVariants
} from './animations';
import {timeout} from '../../utils/timeout';

export enum ContentPosition {
  left = 'left',
  bottom = 'bottom'
}

interface TooltipTriggerProps extends ZIndexProps {
  isContentOpen: boolean;
  withContentTip?: boolean;
}

interface TooltipContentProps extends ZIndexProps {
  offsetX: number;
  offsetY: number;
  offsetRight: number;
  stickTo: StickDirection;
  position: ContentPosition;
}

export type Renderer = (toggle: () => void) => React.ReactNode;

export {StickDirection} from './useTooltipHelper';
export interface UniversalTooltipProps extends ZIndexProps {
  trigger?: React.ReactNode;
  renderTrigger?: Renderer;
  content?: React.ReactNode;
  renderContent?: Renderer;
  innerRef?: React.RefObject<HTMLDivElement>;
  contentStickTo?: StickDirection;
  contentPosition?: ContentPosition;
  isActive?: boolean;
  toggleCallback?: () => void;
  parentRef?: React.RefObject<HTMLElement>;
  scrollContainerRef?: React.RefObject<HTMLElement>;
  onExitComplete?: () => void;
}

export const TooltipOverlay = styled(motion.div)<
  ZIndexProps & {isOpen?: boolean}
>`
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.02);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${p => p.zIndex || -1};
`;

TooltipOverlay.displayName = 'TooltipOverlay';

const Tip = styled(motion.div)`
  &:before,
  &:after {
    display: block;
    bottom: -13px;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:before {
    border-color: rgba(170, 170, 170, 0);
    border-bottom-color: #aaa;
    border-width: 9px;
    margin-left: -9px;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 8px;
    margin-left: -8px;
  }
`;

export const TooltipTrigger = styled.div.attrs(addDataCy)<TooltipTriggerProps>`
  width: fit-content;
  height: fit-content;
  position: relative;
  z-index: ${p => p.zIndex || 1};
`;

export const TooltipContent = styled(motion.div).attrs(
  addDataCy
)<TooltipContentProps>`
  z-index: ${p => p.zIndex || -1};
  width: auto;
  height: max-content;
  position: absolute;
  background: ${colors.cWhite};
  border: 1px solid ${colors.cGray500};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
`;

const TooltipContentBottom = styled(TooltipContent)`
  ${p => p.stickTo}: ${p => p.offsetX}px;
  margin-top: ${dimensions.spaceS};
`;

const TooltipContentLeft = styled(TooltipContent)`
  ${p => p.stickTo}: ${p => p.offsetY}px;
  right: ${p => p.offsetRight}px;
  margin-right: ${dimensions.spaceS};
`;

const getTipTransition = (isOpen: boolean) => ({
  ...transition,
  delay: isOpen ? 0.1 : 0
});

export const _Tooltip: StyledFC<UniversalTooltipProps> = props => {
  const {
    className,
    dataCy,
    renderTrigger,
    trigger,
    renderContent,
    content,
    innerRef,
    contentPosition = ContentPosition.bottom,
    contentStickTo = contentPosition === ContentPosition.bottom
      ? StickDirection.left
      : StickDirection.top,
    zIndex = 1,
    isActive = false,
    toggleCallback,
    parentRef,
    scrollContainerRef,
    onExitComplete
  } = props;

  const controls = useAnimation();

  const triggerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  const {contentOffsetX, contentOffsetY, toggle, isContentOpen, triggerWidth} =
    useTooltipHelper({
      toggleCallback,
      triggerRef,
      contentRef,
      parentRef,
      scrollContainerRef,
      contentStickTo,
      isActive
    });

  const animate = async () => {
    const variants =
      contentPosition === ContentPosition.left
        ? contentLeftVariants
        : contentBottomVariants;

    if (isContentOpen) {
      await controls.set(beforeAnimation);
      await controls.set(variants.hidden);
      await timeout(100);
      await controls.start(variants.visible);
    } else {
      await controls.start(variants.hidden);
      await controls.set(afterAnimation);
    }
  };

  React.useEffect(() => {
    animate();
  }, [isContentOpen]);

  const Content =
    contentPosition === ContentPosition.bottom
      ? TooltipContentBottom
      : TooltipContentLeft;

  return (
    <>
      <div className={className} ref={innerRef} data-cy={dataCy}>
        <TooltipTrigger
          isContentOpen={isContentOpen}
          ref={triggerRef}
          zIndex={zIndex + 2}
          withContentTip={contentPosition === ContentPosition.bottom}
        >
          {renderTrigger ? renderTrigger(toggle) : trigger}
          {contentPosition === ContentPosition.bottom ? (
            <Tip
              variants={tipVariants}
              initial={AnimationVariants.hidden}
              transition={getTipTransition(isContentOpen)}
              animate={
                isContentOpen
                  ? AnimationVariants.visible
                  : AnimationVariants.hidden
              }
            />
          ) : null}
        </TooltipTrigger>
        <Content
          position={contentPosition}
          offsetX={contentOffsetX}
          offsetY={contentOffsetY}
          ref={contentRef}
          stickTo={
            contentStickTo === StickDirection.middle
              ? StickDirection.top
              : contentStickTo
          }
          zIndex={zIndex + 1}
          offsetRight={triggerWidth}
          animate={controls}
          initial={initial}
          transition={transition}
        >
          {renderContent ? renderContent(toggle) : content}
        </Content>
      </div>
      <AnimatePresence onExitComplete={onExitComplete}>
        {isContentOpen && (
          <TooltipOverlay
            zIndex={zIndex}
            onClick={toggle}
            initial={overlayAnimation.initial}
            animate={overlayAnimation.animate}
            transition={transition}
            exit={overlayAnimation.exit}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export const UniversalTooltip = styled(_Tooltip)`
  position: relative;
  width: fit-content;
  height: fit-content;
`;

UniversalTooltip.displayName = 'UniversalTooltip';
