import React from 'react';
import styled from 'styled-components';
import {FlexAlign, FlexWrap, JustifyContent, StyledFC} from '../../types';

interface HorizontalLayoutProps {
  justifyContent: JustifyContent;
  alignItems?: FlexAlign;
  wrapMargin?: boolean;
}

export const _HorizontalLayout: StyledFC<HorizontalLayoutProps> = ({
  children,
  className,
  dataCy
}) => {
  return (
    <div className={className} data-cy={dataCy}>
      {children}
    </div>
  );
};

export const HorizontalLayout = styled(_HorizontalLayout)`
  ${({alignItems, justifyContent, wrapMargin}) => `
		display: flex;
		flex-wrap: ${wrapMargin ? FlexWrap.WRAP : FlexWrap.NOWRAP};
		align-items: ${alignItems};
		justify-content: ${justifyContent};
		/* button + button {
			margin-left: 24px;
		} */
		margin: ${wrapMargin ? 'margin: -6px' : '0'};
		
		button {
			margin: ${wrapMargin ? 'margin: 6px' : '0'};
		}
	`}
`;

HorizontalLayout.displayName = 'HorizontalLayout';
