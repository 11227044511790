import React from 'react';

export const ResetIcon: React.FC<React.SVGAttributes<SVGElement>> = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      viewBox='0 0 14 14'
    >
      <g
        fill='none'
        fillRule='evenodd'
        stroke='#4AF'
        strokeLinecap='round'
        strokeLinejoin='round'
        transform='translate(.243)'
      >
        <line
          x1='6.5'
          x2='6.5'
          y1='-1'
          y2='15'
          strokeWidth='2'
          transform='rotate(-45 6.5 7)'
        />
        <line
          x1='6.5'
          x2='6.5'
          y1='-1'
          y2='15'
          strokeWidth='2'
          transform='scale(-1 1) rotate(-45 0 22.692)'
        />
      </g>
    </svg>
  );
};
