import React from 'react';
import styled from 'styled-components';

import dimensions from '../../sass/dimensions';
import {StyledFC} from '../../types';

import {BoxedOptionList} from '../BoxedDropdown';
import {
  BoxedInputCombined,
  BoxedInputProps,
  BoxedInputType
} from '../BoxedInput';
import {Dropdown, DropdownContent, DropdownContext} from '../Dropdown';
import {InputProps} from '../InputField/types';
import {OptionListItem, OptionListProps} from '../OptionList';

const _BoxedSearchInputWrapper: StyledFC = ({children, className, dataCy}) => {
  const {triggerRef} = React.useContext(DropdownContext);

  return (
    <div className={className} data-cy={dataCy} ref={triggerRef}>
      {children}
    </div>
  );
};

const StyledBoxedSearch = styled.div<Pick<OptionListProps, 'height'>>`
  ${DropdownContent} {
    & > div {
      box-shadow: none;
    }
    ul {
      max-height: calc(
        ${p => p.height || dimensions.optionListDefaultHeight}
      ); /* default 5 Items minus bottom border */
    }
    li {
      height: 50px;
    }
  }
`;

const BoxedInputCombinedSearch = styled(BoxedInputCombined)<
  Pick<InputProps, 'label'>
>`
  & input {
    padding-top: ${p => (p.label ? 16 : 8)}px;
  }
`;

const BoxedSearchInputWrapper = styled(_BoxedSearchInputWrapper)`
  position: relative;
`;

const SearchDropdown = styled(Dropdown)`
  ${BoxedSearchInputWrapper} {
    z-index: ${p => p.zIndex};
  }
`;

const getLabelOrYell = (item: OptionListItem): string =>
  item.label !== undefined ? item.label : '[NO LABEL]';

export type BoxedSearchProps = BoxedInputProps & {
  /**
   * The items should always have a `label` property,
   * since this is what is set as the value of the input
   * when a selection takes place.
   * If this is not possible, provide `getLabel` property,
   * to derive a label from the selected item.
   */
  optionListProps: OptionListProps;
  onSelectionChange: (item: OptionListItem) => void;
  onSearchChange: (inputText: string) => void;
  defaultSelection?: OptionListItem;
  /**
   * A method to derive a label from an item.
   * Defaults to getting the `label` property from an item,
   * and yells at you if your items don't have labels.
   *
   * @see getLabelOrYell
   */
  getLabel?: (item: OptionListItem) => string;
};

const _BoxedSearch: StyledFC<BoxedSearchProps> = ({
  className,
  dataCy,
  defaultSelection,
  getLabel = getLabelOrYell,
  onSearchChange,
  onSelectionChange,
  optionListProps,
  ...boxedInputProps
}) => {
  const [search, setSearch] = React.useState<string>('');
  const [showOptions, setShowOptions] = React.useState<boolean>(false);

  const applySearch = React.useCallback(
    (item: OptionListItem) => setSearch(getLabel(item)),
    [getLabel]
  );

  React.useEffect(() => {
    setShowOptions(!!(search && optionListProps.items.length));
  }, [search, optionListProps.items]);

  React.useEffect(() => defaultSelection && applySearch(defaultSelection), []);

  return (
    <StyledBoxedSearch className={className}>
      <SearchDropdown
        zIndex={20}
        className={className}
        dataCy={dataCy}
        isOpen={showOptions}
        overlapTrigger={false}
      >
        <BoxedSearchInputWrapper>
          <BoxedInputCombinedSearch
            {...boxedInputProps}
            type={BoxedInputType.search}
            value={search}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
              onSearchChange(evt.target.value);
              setSearch(evt.target.value);
            }}
          />
        </BoxedSearchInputWrapper>
        <DropdownContent>
          <BoxedOptionList
            {...optionListProps}
            setSelected={applySearch}
            onSelectionChange={onSelectionChange}
          />
        </DropdownContent>
      </SearchDropdown>
    </StyledBoxedSearch>
  );
};

export const BoxedSearch = styled(_BoxedSearch)``;

BoxedSearch.displayName = 'BoxedSearch';
