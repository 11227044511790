import styled from 'styled-components';
import {PropsWithStyledProps, BorderDirection} from '../../types';
import {addDataCy} from '../../utils';
import {BarStyle, BorderThickness, Orientation} from './types';

export type BarProps = PropsWithStyledProps<{
  orientation?: Orientation;
  barStyle?: BarStyle;
  borderDirection?: BorderDirection;
  borderThickness?: BorderThickness;
}>;

const getBgColor = (barStyle: BarStyle, theme: any) =>
  ({
    [BarStyle.LIGHT]: theme.colors.cGray100,
    [BarStyle.DARK]: theme.colors.cGray200,
    [BarStyle.DEFAULT]: 'transparent'
  }[barStyle]);

export const Bar = styled.div.attrs(addDataCy)<BarProps>`
  ${props =>
    props.orientation === Orientation.VERTICAL
      ? 'height: 100%;'
      : 'width: 100%;'}
  border-${props =>
    props.borderDirection || BorderDirection.BOTTOM}-style: solid;
  border-width: ${props =>
    props.borderThickness === BorderThickness.THICK
      ? props.theme.dimensions.borderWidthM
      : props.theme.dimensions.borderWidthS};
  background-color: ${props =>
    getBgColor(props.barStyle || BarStyle.DEFAULT, props.theme)};
  border-color: ${props => props.theme.colors.cAreaBorder};
`;

Bar.displayName = 'Bar';
