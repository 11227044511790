import React, {useCallback, useState} from 'react';
import styled, {css} from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import {StyledFC} from 'types';
import {
  AddIcon,
  AngleRightIcon,
  CalendarIcon,
  CaretRightIcon,
  CloseIcon,
  Decoration,
  ListIcon
} from '../Icons';

import {
  ActionTipProps,
  ButtonLayout,
  DetailProps,
  LibraryTileProps,
  ModuleDetailProps,
  ModuleProps,
  TileProps,
  TipPosition
} from './types';
import {IconBtnStyle, Knob, KnobType} from '../Knob';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';
import {ModuleHeader, ModuleHeaderProps} from '../Module/ModuleHeader';

export const SuperTopicTile: StyledFC<TileProps> = ({color, title}) => {
  return (
    <_SuperTopicTile color={color}>
      <SuperTopicTitle>{title}</SuperTopicTitle>
    </_SuperTopicTile>
  );
};

const ModuleTileBase = styled.a<Pick<TileProps, 'maxTileWidth'>>`
  display: flex;
  max-width: ${p => p.maxTileWidth || 'unset'};
  position: relative;
`;

const TileBase = styled.div<Pick<TileProps, 'maxTileWidth'>>`
  display: flex;
  max-width: ${p => p.maxTileWidth || 'unset'};
  position: relative;
`;

const SuperTopicTitle = styled.h2`
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: ${colors.cGray800};
  font-size: ${dimensions.fontSizeXxl};
  line-height: ${dimensions.lineHeightXl};
`;

const _SuperTopicTile = styled(TileBase)`
  justify-content: flex-start;
  padding: ${dimensions.spaceL};
  box-shadow: inset 4px 0 0 ${p => p.color};
  background: linear-gradient(
    90deg,
    ${p => (p.color ? hexToRgba(p.color, 0.2) : colors.cWhite)} 0%,
    ${p => (p.color ? hexToRgba(p.color, 0) : colors.cWhite)} 100%
  );
`;

const ModuleImage = styled.span`
  img {
    max-width: 100%;
    cursor: pointer;
  }

  flex: 0 0 80px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${p => p.color && hexToRgba(p.color, 0.2)};
`;

const ActionButton = styled(Knob)`
  flex: 1;
  display: flex;
  &:nth-child(1) {
    align-items: flex-end;
    padding-bottom: ${dimensions.spaceXs};
  }
  &:nth-child(2) {
    align-items: flex-start;
    padding-top: ${dimensions.spaceXs};
  }
  padding: ${dimensions.spaceS};
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;

  ${Knob} {
    flex: 1;
  }
`;

const ModuleDetails = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  padding: ${dimensions.spaceS};
`;

const ModuleTitle = styled.h3`
  font-size: ${dimensions.fontSizeM};
  font-weight: normal;
  line-height: ${dimensions.lineHeightM2};
  color: ${colors.cGray800};
  padding: 0;
  display: flex;
  align-items: center;
  word-break: break-word;
`;

const ModuleSuperTopic = styled.h6`
  font-size: ${dimensions.fontSizeXs};
  line-height: ${dimensions.lineHeightXs};
  color: ${colors.cGray700};
  font-weight: normal;
  margin: 0;
  padding: 0;
`;

const DetailContent = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${dimensions.spaceXl} ${dimensions.spaceM};
  position: relative;
  z-index: 0;
`;

const Examples = styled.section`
  overflow-y: auto;
  /* margin: ${dimensions.spaceL} 0 0; */
`;

const DetailHeadline = styled.h6`
  font-size: ${dimensions.fontSizeM};
  font-weight: 500;
  margin: 0 0 ${dimensions.spaceS};
  color: ${colors.cGray800};
`;

const List = styled.ul`
  line-height: ${dimensions.lineHeightL};
  color: ${colors.cGray800};
`;
const Item = styled.li``;

const DefinitionList = styled.dl`
  margin: 0;
  padding: 0;
  span + span {
    margin: ${dimensions.spaceL} 0 0;
    display: flex;
    flex-direction: column;
  }
`;

const Term = styled.dt`
  font-size: ${dimensions.fontSizeS};
  font-weight: 500;
  margin: 0 0 ${dimensions.spaceXs};
  padding: 0;
`;
const Desc = styled.dd`
  margin: 0;
  padding: 0;
  overflow-x: scroll;
`;

const DetailFooter = styled.footer`
  padding: ${dimensions.spaceM};
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dddddd;
`;

const _DetailHeader: StyledFC<{modalTitle?: string; onClose: () => void}> = ({
  className,
  modalTitle,
  onClose
}) => {
  return (
    <header className={className}>
      {modalTitle}
      <Knob
        type={KnobType.iconBtn}
        iconBtnStyle={IconBtnStyle.fill}
        iconAppended={<CloseIcon />}
        onClick={onClose}
      />
    </header>
  );
};

export const DetailHeader = styled(_DetailHeader)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${dimensions.spaceS};
  position: relative;
  border-bottom: 1px solid #dddddd;
  min-height: 52px;
  ${Knob} {
    position: absolute;
    top: ${dimensions.spaceS};
    right: ${dimensions.spaceS};
  }
`;

const _DetailTile: StyledFC<
  Omit<DetailProps, 'onOpenModuleDetails' | 'onToggleActionTip'>
> = ({
  className,
  isActive,
  color,
  modalTitle,
  title,
  image,
  objectives,
  preknowledge,
  examples,
  onClick,
  onClose,
  isPlanned,
  onAddToPlanning,
  onOpenModule,
  onOpenPlanning,
  context
}) => {
  return isActive ? (
    <div className={className} onClick={onClick}>
      <DetailHeader modalTitle={modalTitle} onClose={onClose} />

      <DetailContent>
        <ModuleHeader
          color={color}
          title={title}
          image={image}
          objectives={objectives}
          preknowledge={preknowledge}
          context={context}
        />

        {examples && (
          <Examples>
            <DetailHeadline>Beispielaufgaben Vorschau</DetailHeadline>
            <DefinitionList>
              {examples.map((example: any, i: number) => (
                <span key={i}>
                  <Term>Aufgabe {i + 1}</Term>
                  <Desc>
                    <img
                      draggable='false'
                      src={example.image}
                      alt={`Aufgabe-${i + 1}`}
                    />
                  </Desc>
                </span>
              ))}
            </DefinitionList>
          </Examples>
        )}
      </DetailContent>
      <DetailFooter>
        <Knob
          {...((isPlanned && {
            label: 'In der Planung ansehen',
            iconPrepended: <CalendarIcon decoration={Decoration.checkmark} />,
            iconAppended: (
              <CaretRightIcon width='10' height='16' fill={colors.cBlue700} />
            ),
            colorizeIcon: true,
            onClick: onOpenPlanning
          }) || {
            label: 'Zur Planung hinzufügen',
            iconPrepended: <CalendarIcon decoration={Decoration.plus} />,
            onClick: onAddToPlanning
          })}
          type={KnobType.link}
        />
        <Knob
          label='Öffnen'
          type={KnobType.button}
          iconAppended={<AngleRightIcon />}
          onClick={onOpenModule}
        />
      </DetailFooter>
    </div>
  ) : (
    <></>
  );
};

export const DetailTile = styled(_DetailTile)`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
  background: ${colors.cWhite};
  transition: all 0.5s ease-in-out;
  position: fixed;

  @media (max-height: 600px) {
    max-height: 90vh;
  }

  @media (min-height: 601px) {
    max-height: 560px;
  }

  @media (min-width: 768px) {
    width: 480px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media (max-width: 767px) {
    min-width: 100%;
    min-height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  ${p =>
    p.isActive
      ? css`
          opacity: 1;
          visibility: visible;
          z-index: 10;
        `
      : css`
          background-color: #fff;
          opacity: 0;
          visibility: hidden;
        `};
`;

const Dialogue = styled.p`
  line-height: ${dimensions.lineHeightM2};
`;

const ROW_LAYOUT = css`
  ${Knob} + ${Knob} {
    margin-left: 6px;
  }
  justify-content: flex-end;
`;

export const Footer = styled.footer<Pick<ActionTipProps, 'buttonLayout'>>`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  ${p => {
    switch (p.buttonLayout) {
      case ButtonLayout.row:
        return ROW_LAYOUT;
      case ButtonLayout.column:
        return css`
          flex-direction: column;
          align-items: flex-start;
          ${Knob} {
            padding: 8.5px 12px;
            justify-content: flex-start;
            width: 100%;
          }
          ${Knob} + ${Knob} {
            margin-bottom: 6px;
          }
          ${Knob}:nth-child(1) {
            order: 2;
          }
          ${Knob}:nth-child(2) {
            order: 1;
          }
        `;
      default:
        return ROW_LAYOUT;
    }
  }};
`;

const _ActionTip: StyledFC<ActionTipProps> = ({
  className,
  dialogue,
  closeLabel,
  onCloseTip,
  actionButton,
  buttonLayout
}) => {
  return (
    <div className={className}>
      <Dialogue>{dialogue}</Dialogue>
      <Footer buttonLayout={buttonLayout}>
        <Knob type={KnobType.ghost} label={closeLabel} onClick={onCloseTip} />
        {actionButton}
      </Footer>
    </div>
  );
};

export const ActionTip = styled(_ActionTip)`
  &::before,
  &::after {
    position: absolute;

    ${p => {
      if (typeof p.tipPosition === 'number') {
        return css`
          left: ${p.tipPosition}px;
        `;
      }
      switch (p.tipPosition) {
        case TipPosition.left:
          return css`
            left: 0;
          `;
        case TipPosition.center:
          return css`
            left: calc(50% - 12px);
          `;
        case TipPosition.right:
          return css`
            right: 0;
          `;
      }
    }};
  }
  &::before {
    top: -12px;
    border-style: solid;
    border-color: transparent transparent ${colors.cWhite} transparent;
    border-width: 0 12px 12px 12px;
    content: '';
    display: inline-block;
    height: 0;
    width: 0;
    z-index: 1;
  }
  &::after {
    top: -13px;
    border-style: solid;
    border-color: transparent transparent ${colors.cGray400} transparent;
    border-width: 0 12px 12px 12px;
    content: '';
    display: inline-block;
    height: 0;
    width: 0;
  }
  border-radius: ${dimensions.spaceXxs};
  background: ${colors.cWhite};
  padding: ${dimensions.spaceS};
  border: 1px solid ${colors.cGray400};
  width: calc(100% + 2px);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
  position: absolute;
  left: -1px;
  right: -1px;
  display: flex;
  flex-direction: column;
  transition: all 0.25s ease-in-out;
  ${p =>
    p.activeTip
      ? css`
          transform: translate3d(0, 18px, 0);
          opacity: 1;
          z-index: 1;
          visibility: visible;
        `
      : css`
          transform: translate3d(0, 90px, 0);
          opacity: 0;
          z-index: 1;
          visibility: hidden;
        `};
  & p {
    color: ${colors.cGray800};
  }
`;

export const ModuleTile: StyledFC<ModuleProps & ActionTipProps> = ({
  id,
  modalTitle,
  title,
  image,
  color,
  onOpenModuleDetails,
  onOpenModule,
  dialogue,
  closeLabel,
  actionButton,
  onToggleActionTip,
  isPlanned,
  customPreviewIcon,
  customAddToPlaningIcon,
  url,
  onOpenPlanning,
  onAddToPlanning,
  buttonLayout,
  tipPosition
}) => {
  const [isActive, setActive] = React.useState(false);
  const [modalDetailProps, setModuleDetailProps] =
    React.useState<ModuleDetailProps>({
      objectives: [],
      preknowledge: [],
      examples: []
    });

  const setModalDetailsAndOpen = (modalDetails: ModuleDetailProps) => {
    setModuleDetailProps(modalDetails);
    setActive(true);
    return () => setActive(false);
  };

  const onModalToggled = (id: string) =>
    onOpenModuleDetails(id, setModalDetailsAndOpen);

  const stopProp = useCallback((e: Event | React.MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const [tipVisibility, setTipVisibilityTo] = useState(false);

  const {objectives, preknowledge, examples} = modalDetailProps;
  return (
    <_ModuleTile
      color={color}
      {...((url && {href: url}) || {})}
      onClick={e => {
        onOpenModule(id);
        return stopProp(e);
      }}
    >
      <ModuleDetails>
        <ModuleImage color={color}>
          {image && <img src={image} alt={title} />}
        </ModuleImage>
        <ModuleTitle>{title}</ModuleTitle>
      </ModuleDetails>
      <ActionButtons>
        <ActionButton
          type={customPreviewIcon ? KnobType.link : KnobType.iconBtn}
          iconBtnStyle={IconBtnStyle.border}
          iconAppended={customPreviewIcon || <ListIcon />}
          onClick={e => {
            onModalToggled(id);
            return stopProp(e);
          }}
        />

        <ActionButton
          type={customAddToPlaningIcon ? KnobType.link : KnobType.iconBtn}
          iconBtnStyle={IconBtnStyle.border}
          iconAppended={customAddToPlaningIcon || <AddIcon />}
          onClick={(e: Event) => {
            onToggleActionTip(id, !tipVisibility, setTipVisibilityTo);
            return stopProp(e);
          }}
        />
      </ActionButtons>
      <ActionTip
        dialogue={dialogue}
        activeTip={tipVisibility}
        closeLabel={closeLabel}
        onCloseTip={(e: Event) => {
          onToggleActionTip(id, false, setTipVisibilityTo);
          return stopProp(e);
        }}
        actionButton={actionButton}
        buttonLayout={buttonLayout}
        tipPosition={tipPosition}
      />
      <DetailTile
        id={id}
        color={color}
        modalTitle={modalTitle}
        title={title}
        image={image}
        objectives={objectives}
        preknowledge={preknowledge}
        examples={examples}
        isActive={isActive}
        isPlanned={isPlanned}
        onClose={(e: Event) => {
          onModalToggled(id);
          return stopProp(e);
        }}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => stopProp(e)}
        onAddToPlanning={onAddToPlanning}
        onOpenPlanning={onOpenPlanning}
        onOpenModule={() => onOpenModule(id)}
        context='modal'
      />
    </_ModuleTile>
  );
};

const _ModuleTile = styled(ModuleTileBase)`
  cursor: pointer;
  border: 1px solid ${colors.cGray400};
  border-radius: ${dimensions.borderRadiusM};
  position: relative;
  text-decoration: none;
  transition: box-shadow 0.35s ease-in-out;
  position: relative;
  ${ModuleTitle} {
    margin: 0 0 0 ${dimensions.spaceM};
    cursor: pointer;
  }

  &:hover {
    box-shadow: 0px 6px 14px #e6e6e6;
  }
  ${ActionTip} {
    top: 100%;
  }
`;

const TileRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;
`;

const LibIcon = styled.div<Pick<LibraryTileProps, 'iconWidth' | 'iconPadding'>>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${p => (p.iconPadding ? `${dimensions.spaceS}` : 0)};
  height: 100%;
  flex-basis: ${p => (p.iconWidth ? p.iconWidth + 'px' : 'auto')};
  flex-shrink: 0;

  img {
    max-width: 100%;
  }
`;

const Tag = styled.span`
  font-weight: normal;
  color: ${colors.cGray700};
`;

const SubInfo = styled.h5`
  display: flex;
  align-items: center;
  margin: ${dimensions.spaceXxs} 0 0;
  ${Tag} + ${Tag} {
    margin: 0 0 0 ${dimensions.spaceXs};
  }
`;

const BetaBadge = styled.span`
  font-size: ${dimensions.fontSizeXs};
  color: ${colors.cWhite};
  border-radius: ${dimensions.spaceS};
  background: ${colors.cGray700};
  padding: 0 ${dimensions.spaceXs};
  display: inline-flex;
`;

const WordWrapper = styled.span`
  ${BetaBadge} {
    margin-left: ${dimensions.spaceXxs};
  }
`;

const LibDetails = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  ${ModuleTitle} {
    flex-wrap: wrap;
    margin: 0;
  }
`;

const MetaInfo = styled.p`
  margin: 0;
  font-size: ${dimensions.fontSizeS};
  line-height: ${dimensions.lineHeightS};
  color: ${colors.cGray700};
`;

const _LibraryTile: StyledFC<LibraryTileProps> = ({
  className,
  icon,
  iconWidth,
  iconPadding,
  title,
  themeCount,
  classRange,
  meta,
  beta
}) => {
  return (
    <div className={className}>
      <TileRow>
        {icon && (
          <LibIcon iconPadding={iconPadding} iconWidth={iconWidth}>
            {icon}
          </LibIcon>
        )}
        <LibDetails>
          <ModuleTitle>
            <WordWrapper>
              {title} {beta && <BetaBadge>beta</BetaBadge>}
            </WordWrapper>
          </ModuleTitle>
          <SubInfo>
            {themeCount && (
              <Tag>
                {themeCount + ' ' + (themeCount > 1 ? 'Themen' : 'Thema')}
              </Tag>
            )}
            {classRange && <Tag>{classRange}</Tag>}
          </SubInfo>
        </LibDetails>
      </TileRow>
      {meta && (
        <TileRow>
          <MetaInfo>{meta}</MetaInfo>
        </TileRow>
      )}
    </div>
  );
};

export const LibraryTile = styled(_LibraryTile)`
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: ${dimensions.spaceM};
  border: 1px solid;
  position: relative;
  transition: box-shadow 0.35s ease-in-out;
  path {
    transition: all 0.5s ease-in;
    fill: ${p => (p.isActive ? `${colors.cBlue700}` : `${colors.cGray700}`)};
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0px 6px 14px #e6e6e6;
  }

  border-color: ${p =>
    p.isActive ? `${colors.cBlue700}` : `${colors.cGray400}`};
  background-color: ${p =>
    p.isActive ? `${colors.cBlue200}` : `${colors.cWhite}`};
  border-radius: 5px;

  ${LibIcon} + ${LibDetails} {
    margin-left: ${dimensions.spaceM};
  }

  ${TileRow} + ${TileRow} {
    margin-top: ${dimensions.spaceXs};
  }
`;
