import {transition} from '../../consts/animation';
import {AnimatePresence, motion} from 'framer-motion';
import React from 'react';
import ReactDOM from 'react-dom';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';
import styled, {css} from 'styled-components';
import {
  AnimationVariants,
  ModalSize,
  StyledFC,
  StyledProps,
  ZIndexProps
} from '../../types';
import {addDataCy, addDataCyAndMergePropsAttrs} from '../../utils';
import {Button} from '../Button';
import {modalVariants, overlayTransition, overlayVariants} from './animations';
import {ModalContext} from './ModalContext';

export const MODAL_ID = 'bm_universal_modal';

const sizeToPixelMap: Record<ModalSize, string> = {
  [ModalSize.s]: '460px',
  [ModalSize.m]: '620px',
  [ModalSize.l]: '780px',
  [ModalSize.xl]: '940px'
};

export const ModalHeader = styled.header.attrs(addDataCy)`
  height: ${dimensions.sectionHeightS};
  max-height: ${dimensions.sectionHeightS};
  font-size: ${dimensions.fontSizeM};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 -2px 0 0 ${colors.cGray500};
  flex: 0 0 auto;
`;

export interface ModalContentProps {
  noPadding?: boolean;
  maxHeight?: {
    breakpoint: number;
    height: number;
  };
}

export const ModalFooter = styled.footer.attrs(addDataCy)`
  display: flex;
  justify-content: flex-end;
  flex: 0 0 auto;
  padding: ${dimensions.spaceXl};
  ${Button} + ${Button} {
    margin-left: ${dimensions.spaceS};
  }
`;

export const ModalContent = styled.section.attrs(addDataCy)<ModalContentProps>`
  text-align: left;
  font-size: ${dimensions.fontSizeM};
  line-height: ${dimensions.lineHeightL};
  display: flex;
  overflow: auto;
  padding: ${props => (props.noPadding ? 0 : dimensions.spaceXl)};
  padding-bottom: 0;
  ${p =>
    p.maxHeight &&
    css`
      @media (max-height: ${p.maxHeight.breakpoint + 'px'}) {
        max-height: ${p.maxHeight.height + 'px'};
        overflow-y: auto;
      }
    `}
`;

export interface ModalProps extends ZIndexProps, StyledProps {
  size?: ModalSize;
  isOpen?: boolean;
  asPortal?: boolean;
  distanceFromBorder?: number;
}

export const Modal = styled.div.attrs<ModalProps>(
  addDataCyAndMergePropsAttrs<ModalProps>(({asPortal}) =>
    asPortal ? {id: MODAL_ID} : null
  )
)<ModalProps>`
  display: flex;
  flex-direction: column;
  color: ${colors.cGray800};
  box-shadow: 0 7px 17px rgba(0, 0, 0, 0.1);
  border-radius: ${dimensions.borderRadiusM};
  position: relative;
  z-index: ${p => p.zIndex || 1};
  background: white;
  @media (min-width: 768px) {
    width: ${p => (p.size ? sizeToPixelMap[p.size] : '100%')};
    max-height: calc(100% - ${p => p.distanceFromBorder || 0}px);
  }
  @media (max-width: 767px) {
    width: 100%;
    max-height: 100%;
  }
`;

Modal.displayName = 'Modal';

export interface ModalOverlayProps extends ZIndexProps {
  isOpen: boolean;
  asPortal?: boolean;
}

export const ModalOverlay = styled.div.attrs<ModalOverlayProps>(({asPortal}) =>
  asPortal ? {id: MODAL_ID} : null
)<ModalOverlayProps>`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${p => (p.isOpen ? p.zIndex || -1 : -9999)};
  ${p =>
    !p.isOpen &&
    css`
      transition: z-index 0.25s linear 0.5s;
    `}
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Modal} {
    z-index: ${p => (p.zIndex ? p.zIndex + 1 : 1)};
  }
`;

const useAnimationStartCompleteHandlers = (
  isOpen: boolean,
  onEnterAnimationStart?: () => void,
  onExitAnimationStart?: () => void,
  onEnterAnimationComplete?: () => void,
  onExitAnimationComplete?: () => void
) => {
  const onAnimationStart = React.useCallback(() => {
    if (isOpen && onEnterAnimationStart) {
      onEnterAnimationStart();
    }
    if (!isOpen && onExitAnimationStart) {
      onExitAnimationStart();
    }
  }, [isOpen, onEnterAnimationStart, onExitAnimationStart]);

  const onAnimationComplete = React.useCallback(() => {
    if (isOpen && onEnterAnimationComplete) {
      onEnterAnimationComplete();
    }
    if (!isOpen && onExitAnimationComplete) {
      onExitAnimationComplete();
    }
  }, [isOpen, onEnterAnimationComplete, onExitAnimationComplete]);

  return {onAnimationStart, onAnimationComplete};
};

export interface ModalSystemProps {
  animated?: boolean;
  useContextSize?: boolean;
  onEnterAnimationStart?: () => void;
  onExitAnimationStart?: () => void;
  onEnterAnimationComplete?: () => void;
  onExitAnimationComplete?: () => void;
}

export const ModalSystem: StyledFC<
  ModalSystemProps & Omit<ModalProps, 'isOpen'>
> = props => {
  const {
    children,
    size: propsSize,
    animated,
    zIndex,
    onEnterAnimationStart,
    onExitAnimationStart,
    onEnterAnimationComplete,
    onExitAnimationComplete,
    distanceFromBorder,
    useContextSize = false
  } = props;

  const {isOpen, size: contextSize} = React.useContext(ModalContext);

  const size = useContextSize ? contextSize : propsSize;

  const {onAnimationStart, onAnimationComplete} =
    useAnimationStartCompleteHandlers(
      isOpen,
      onEnterAnimationStart,
      onExitAnimationStart,
      onEnterAnimationComplete,
      onExitAnimationComplete
    );

  return animated ? (
    <ModalOverlay
      as={motion.div}
      isOpen={isOpen}
      zIndex={zIndex}
      variants={overlayVariants}
      animate={isOpen ? AnimationVariants.visible : AnimationVariants.hidden}
      initial={AnimationVariants.hidden}
      transition={overlayTransition}
      onAnimationStart={onAnimationStart}
      onAnimationComplete={onAnimationComplete}
    >
      <Modal
        as={motion.div}
        size={size}
        isOpen={isOpen}
        zIndex={zIndex}
        variants={modalVariants}
        animate={isOpen ? AnimationVariants.visible : AnimationVariants.hidden}
        initial={AnimationVariants.hidden}
        transition={transition}
        distanceFromBorder={distanceFromBorder}
        asPortal
      >
        {children}
      </Modal>
    </ModalOverlay>
  ) : (
    <ModalOverlay isOpen={isOpen} zIndex={zIndex}>
      <Modal
        size={size}
        isOpen={isOpen}
        zIndex={zIndex}
        distanceFromBorder={distanceFromBorder}
      >
        {children}
      </Modal>
    </ModalOverlay>
  );
};

export const PortalModalSystem: StyledFC<
  ModalSystemProps & Omit<ModalProps, 'isOpen'>
> = props => {
  const {children, animated, zIndex} = props;

  const {isOpen} = React.useContext(ModalContext);

  return animated ? (
    <ModalOverlay
      as={motion.div}
      isOpen={isOpen}
      zIndex={zIndex}
      variants={overlayVariants}
      animate={isOpen ? AnimationVariants.visible : AnimationVariants.hidden}
      initial={AnimationVariants.hidden}
      transition={overlayTransition}
      asPortal
    >
      {children}
    </ModalOverlay>
  ) : (
    <ModalOverlay isOpen={isOpen} zIndex={zIndex} asPortal>
      {children}
    </ModalOverlay>
  );
};

export interface AnimatedModalPortalProps {
  portalId?: string;
  size?: ModalSize;
  isOpen: boolean;
  onEnterAnimationStart?: () => void;
  onExitAnimationStart?: () => void;
  onEnterAnimationComplete?: () => void;
  onExitAnimationComplete?: () => void;
  onExitComplete?: () => void;
}

export const AnimatedModalPortal: StyledFC<AnimatedModalPortalProps> = ({
  portalId = MODAL_ID,
  isOpen,
  dataCy,
  size = ModalSize.m,
  onEnterAnimationStart,
  onExitAnimationStart,
  onEnterAnimationComplete,
  onExitAnimationComplete,
  onExitComplete,
  children
}) => {
  const {zIndex} = React.useContext(ModalContext);
  const [portalNode, setPortalNode] = React.useState<HTMLElement | null>(null);

  React.useEffect(() => {
    const portal = document.getElementById(portalId);
    setPortalNode(portal);
  }, [portalId]);

  const {onAnimationStart, onAnimationComplete} =
    useAnimationStartCompleteHandlers(
      isOpen,
      onEnterAnimationStart,
      onExitAnimationStart,
      onEnterAnimationComplete,
      onExitAnimationComplete
    );

  if (!portalNode) return null;

  return ReactDOM.createPortal(
    <AnimatePresence onExitComplete={onExitComplete}>
      {isOpen && (
        <Modal
          as={motion.div}
          size={size}
          dataCy={dataCy}
          zIndex={zIndex}
          variants={modalVariants}
          initial={AnimationVariants.hidden}
          animate={AnimationVariants.visible}
          exit={AnimationVariants.hidden}
          transition={transition}
          onAnimationStart={onAnimationStart}
          onAnimationComplete={onAnimationComplete}
        >
          {children}
        </Modal>
      )}
    </AnimatePresence>,
    portalNode
  );
};

export const ModalPortal: StyledFC<{portalId?: string}> = ({
  children,
  dataCy,
  portalId = MODAL_ID
}) => {
  const [portalNode, setPortalNode] = React.useState<HTMLElement | null>(null);

  React.useEffect(() => {
    const portal = document.getElementById(portalId);
    if (portal && dataCy) {
      portal.setAttribute('data-cy', dataCy);
    }
    setPortalNode(portal);
  }, [portalId, dataCy]);

  return portalNode ? ReactDOM.createPortal(children, portalNode) : null;
};

export interface ModalSystemSimple {
  isOpen: boolean;
  zIndex: number;
  onAnimationStart?: () => void;
  onAnimationComplete?: () => void;
  onExitComplete?: () => void;
  distanceFromBorder?: number;
  dataCy?: string;
}

export const ModalSystemSimple: React.FC<ModalSystemSimple> = ({
  isOpen,
  zIndex,
  onAnimationStart,
  onAnimationComplete,
  onExitComplete,
  distanceFromBorder,
  dataCy,
  children
}) => {
  const {size} = React.useContext(ModalContext);

  return (
    <ModalOverlay
      as={motion.div}
      isOpen={isOpen}
      zIndex={zIndex}
      variants={overlayVariants}
      animate={isOpen ? AnimationVariants.visible : AnimationVariants.hidden}
      initial={AnimationVariants.hidden}
      transition={overlayTransition}
      onAnimationStart={onAnimationStart}
      onAnimationComplete={onAnimationComplete}
    >
      <AnimatePresence onExitComplete={onExitComplete}>
        {isOpen && (
          <Modal
            as={motion.div}
            size={size}
            dataCy={dataCy}
            zIndex={zIndex}
            variants={modalVariants}
            initial={AnimationVariants.hidden}
            animate={AnimationVariants.visible}
            exit={AnimationVariants.hidden}
            transition={transition}
            distanceFromBorder={distanceFromBorder}
            onAnimationStart={onAnimationStart}
            onAnimationComplete={onAnimationComplete}
          >
            {children}
          </Modal>
        )}
      </AnimatePresence>
    </ModalOverlay>
  );
};
