import React from 'react';
import styled, {css} from 'styled-components';
import get from 'lodash/get';
import {
  Input,
  InputField as BaseInputField,
  InputFieldValidationMessage
} from '../InputField';
import {StyledFC, ValidationType} from '../../types';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';
import {CircularButton} from '../CircularButton';
import {StepperErrorTypes, useStepper} from './useStepper';

const INPUT_HIGHLIGHT_TIMEOUT = 2000;

export interface StepperErrorMessages {
  [StepperErrorTypes.minExceeded]?: string;
  [StepperErrorTypes.maxExceeded]?: string;
  [StepperErrorTypes.wrongFormat]?: string;
}

export interface StepperProps {
  min: number;
  max: number;
  initial: number;
  increaseBtnIcon?: any;
  decreaseBtnIcon?: any;
  errorMessages?: StepperErrorMessages;
  inputRef?: any;
  handleChange?: (_: number) => void;
  handleError?: (_: StepperErrorTypes | null) => void;
  increaseDataCy?: string;
  decreaseDataCy?: string;
}

const InputField = styled(BaseInputField)<{highlighted?: boolean}>`
  width: calc(${dimensions.minClickableSize} - ${dimensions.spaceXxs});
  height: ${dimensions.minClickableHeight};
  padding: 6px 0;
  margin-right: 6px;

  ${Input} {
    text-align: center;
    padding: 0 6px;
    border-radius: ${dimensions.borderRadiusM};
    ${p =>
      p.highlighted
        ? css`
            border-color: ${colors.cBlue700};
          `
        : ''}
  }
`;

export const StepperControlsContainer = styled.div`
  display: flex;
  width: fit-content;
`;

StepperControlsContainer.displayName = 'StepperControlsContainer';

const _Stepper: StyledFC<StepperProps> = props => {
  const {
    min,
    max,
    initial,
    inputRef,
    increaseBtnIcon,
    decreaseBtnIcon,
    className,
    dataCy,
    handleChange,
    handleError,
    errorMessages,
    increaseDataCy,
    decreaseDataCy
  } = props;

  const [inputHighlighted, setInputHighlighted] = React.useState(false);

  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();

  const {value, inputValue, increase, decrease, setValue, error} = useStepper(
    initial,
    min,
    max
  );

  React.useEffect(() => {
    if (handleChange) {
      handleChange(value);
    }
  }, [value, handleChange]);

  React.useEffect(() => {
    if (handleError) {
      handleError(error);
    }
  }, [error, handleError]);

  const handleMouseDown = React.useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setInputHighlighted(true);
  }, []);

  const handleMouseUp = React.useCallback(() => {
    timeoutRef.current = setTimeout(
      setInputHighlighted,
      INPUT_HIGHLIGHT_TIMEOUT,
      false
    );
  }, []);

  const hasError = error && errorMessages && error in errorMessages;

  return (
    <div className={className} data-cy={dataCy}>
      <StepperControlsContainer>
        <InputField
          value={inputValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValue(e.target.value)
          }
          inputRef={inputRef}
          validationType={hasError ? ValidationType.error : undefined}
          highlighted={inputHighlighted}
        />
        <CircularButton
          dataCy={decreaseDataCy}
          onClick={decrease}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          enabled={value !== min}
        >
          {decreaseBtnIcon}
        </CircularButton>
        <CircularButton
          dataCy={increaseDataCy}
          onClick={increase}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          enabled={value !== max}
        >
          {increaseBtnIcon}
        </CircularButton>
      </StepperControlsContainer>
      {!!hasError && (
        <InputFieldValidationMessage validationType={ValidationType.error} show>
          {get(errorMessages, error || '', 'error')}
        </InputFieldValidationMessage>
      )}
    </div>
  );
};

export const Stepper = styled(_Stepper)``;

Stepper.displayName = 'Stepper';
