import styled from 'styled-components';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';
import {handleHover} from '../../utils/stylingHelpers';
import {VerticalMenuItemProps} from './types';
import {applyDecoration} from './decorations';
import {addDataCy, addDataCyAndMergeWith} from '../../utils';

const ROLE_ATTR = {role: 'menuitem'};

export const MenuItemElement = styled.div.attrs(addDataCy)`
  width: 0;
  display: table-cell;
  padding: ${dimensions.spaceXxs};
  vertical-align: middle;
  line-height: ${dimensions.lineHeightM};
  height: ${dimensions.minClickableHeight};
  padding-left: ${dimensions.spaceXs};
`;

export const MenuItemElementLabel = styled(MenuItemElement)`
  width: 100%;
  text-align: left;
  white-space: normal;
  padding: ${dimensions.spaceXxs};
  padding-right: ${dimensions.spaceXs};
`;

export const VerticalMenuItem = styled.div.attrs(
  addDataCyAndMergeWith(ROLE_ATTR)
)<VerticalMenuItemProps>`
  position: relative;
  display: table-row;
  overflow: auto;
  white-space: nowrap;
  text-align: center;
  background-color: ${({selected}) =>
    selected ? colors.cSelectedBackground : 'transparent'};
  color: ${colors.cBlue700};
  fill: ${colors.cBlue700};
  height: ${dimensions.minClickableHeight};
  transition: background-color 0.25s ease-in-out;
  ${({bold}) => bold && 'font-weight: bold;'}

  ${applyDecoration}
  
 
  ${({selected}) =>
    selected
      ? handleHover(`background-color: ${colors.cBlue300}`)
      : handleHover(`background-color: ${colors.cGray200}`)}
`;
