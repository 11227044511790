import React, {useState} from 'react';
import styled from 'styled-components';
import {StyledFC} from '../../types';
import defaultTheme from '../../themes/defaultTheme';
import {handleActive, handleHover} from '../../utils';
import {
  LABEL_CONTAINER_FONT_SIZE,
  NOSE_SIZE,
  PICKER_WIDGET_BACKGROUND_COLOR,
  PICKER_WIDGET_BORDER_RADIUS,
  PICKER_WIDGET_PADDING,
  STEPPER_BUTTON_ACTIVE_COLOR,
  STEPPER_BUTTON_BORDER_RADIUS,
  STEPPER_BUTTON_COLOR,
  STEPPER_BUTTON_HOVER_COLOR,
  STEPPER_BUTTON_SIZE,
  STEPPER_BUTTON_SIZE_80PCT,
  STEPPER_CONFIRM_BUTTON_ACTIVE_BACKGROUND_COLOR,
  STEPPER_CONFIRM_BUTTON_BACKGROUND_COLOR,
  STEPPER_CONFIRM_BUTTON_HOVER_BACKGROUND_COLOR,
  STEPPER_DELETE_BUTTON_ACTIVE_BACKGROUND_COLOR,
  STEPPER_DELETE_BUTTON_BACKGROUND_COLOR,
  STEPPER_DELETE_BUTTON_HOVER_BACKGROUND_COLOR
} from './constants';
import {AdditionalButton, AdditionalButtonProps} from './types';

export type PickerWidgetProps = {
  initialIndex: number;
  list: ReadonlyArray<string | number | JSX.Element>;
  nose?: {xOffset?: number; up: boolean};
  id?: string;
  measureRef?: any;
  onClick?: (index: number) => void;
  onConfirm?: (index: number, labelAlignTop?: boolean) => void;
  additionalButton?: AdditionalButtonProps;
  icons: PickerWidgetIcons;
};

export type PickerWidgetIcons = {
  left: React.ReactNode;
  right: React.ReactNode;
  confirm: React.ReactNode;
  delete?: React.ReactNode;
  close?: React.ReactNode;
};

// tslint:disable:max-line-length
/**
 *1 The `PickerWidget` is a special component for selecting something from a list of options.
 *
 * ### Process State
 *
 * ### Properties
 | Name               | Type                                  | Default   | Description               |
 |---                 |---                                    |---        |---                        |
 | `initialIndex`     | `number`                              | Required  | initially selected element|
 | `list`             | `Array`                               | Required  | a list of elements to add |
 | `id`               | `string`                              | Optional  |                           |
 | `onClick`          | `Function`                            | Optional  | click handler             |
 | `onConfirm`        | `Function`                            | Optional  | confirm handler           |
 | `additionalButton` | `AdditionalButtonProps`               | Optional  |                           |
 | `nose`             | `{xOffset: number, up: boolean}`      | Optional  | a little nose to point to the object that attaches the pickerWidget|
 | `icons`            | `PickerWidgetIcons`                   | Required  | object with icons         |
 */

const {colors} = defaultTheme;

const PickerWidgetContainer = styled.div`
  display: flex;
  padding: ${PICKER_WIDGET_PADDING}px;
  background-color: ${PICKER_WIDGET_BACKGROUND_COLOR};
  border-radius: ${PICKER_WIDGET_BORDER_RADIUS}px;
  border: none;
`;
const NoseContainer = styled.div`
  pointer-events: none;
  margin-left: calc(50% - ${NOSE_SIZE});
`;
const Nose = styled.div<Partial<PickerWidgetProps>>`
  width: 0;
  height: 0;
  border: 0;
  border-left: ${NOSE_SIZE} solid transparent;
  border-right: ${NOSE_SIZE} solid transparent;
  ${props =>
    props.nose?.up
      ? `border-bottom: ${NOSE_SIZE} solid ${PICKER_WIDGET_BACKGROUND_COLOR};`
      : `border-top: ${NOSE_SIZE} solid ${PICKER_WIDGET_BACKGROUND_COLOR};`}
  margin-left: ${props => props.nose?.xOffset || 0}px;
`;
const ButtonStepper = styled.button<{position?: 'left' | 'right'}>`
  width: ${STEPPER_BUTTON_SIZE_80PCT}px;
  min-height: ${STEPPER_BUTTON_SIZE}px;
  background-color: ${STEPPER_BUTTON_COLOR};
  border-radius: ${STEPPER_BUTTON_BORDER_RADIUS};
  border: none;
  outline: none;
  cursor: pointer;
  color: ${colors.cGray800};
  pointer-events: none;
  margin-left: ${props =>
    props.position &&
    (props.position === 'left'
      ? `${-STEPPER_BUTTON_SIZE - PICKER_WIDGET_PADDING}px;`
      : `${-STEPPER_BUTTON_SIZE + PICKER_WIDGET_PADDING}px;`)};
`;
const ButtonClickArea = styled.button<Partial<PickerWidgetProps>>`
  border: none;
  outline: none;
  width: ${STEPPER_BUTTON_SIZE + 2 * PICKER_WIDGET_PADDING}px;
  min-height: ${STEPPER_BUTTON_SIZE + 2 * PICKER_WIDGET_PADDING}px;
  pointer-events: all;
  cursor: pointer;
  margin-top: -${PICKER_WIDGET_PADDING}px;
  margin-left: -${PICKER_WIDGET_PADDING}px;
  margin-bottom: -${PICKER_WIDGET_PADDING}px;
  background-color: transparent;
  ${handleHover(
    `background-color: ${STEPPER_BUTTON_HOVER_COLOR};`,
    `+ ${ButtonStepper}`
  )}
  ${handleActive(
    `background-color: ${STEPPER_BUTTON_ACTIVE_COLOR};`,
    `+ ${ButtonStepper}`
  )}
`;
const ButtonAdditional = styled(ButtonStepper)`
  pointer-events: all;
  width: ${STEPPER_BUTTON_SIZE}px;
  padding: ${PICKER_WIDGET_PADDING}px;
  margin-left: ${PICKER_WIDGET_PADDING}px;
`;
const ButtonConfirm = styled(ButtonAdditional)`
  background-color: ${STEPPER_CONFIRM_BUTTON_BACKGROUND_COLOR};
  ${handleHover(
    `background-color: ${STEPPER_CONFIRM_BUTTON_HOVER_BACKGROUND_COLOR};`
  )}
  ${handleActive(
    `background-color: ${STEPPER_CONFIRM_BUTTON_ACTIVE_BACKGROUND_COLOR};`
  )}
`;
const ButtonDelete = styled(ButtonAdditional)`
  background-color: ${STEPPER_DELETE_BUTTON_BACKGROUND_COLOR};
  ${handleHover(
    `background-color: ${STEPPER_DELETE_BUTTON_HOVER_BACKGROUND_COLOR};`
  )}
  ${handleActive(
    `background-color: ${STEPPER_DELETE_BUTTON_ACTIVE_BACKGROUND_COLOR};`
  )}
`;
const LabelContainer = styled.div`
  pointer-events: none;
  min-width: ${STEPPER_BUTTON_SIZE_80PCT}px;
  background-color: ${colors.cGray300};
  color: ${colors.cGray800};
  text-align: center;
  line-height: normal;
  margin-right: ${-2 * PICKER_WIDGET_PADDING}px;
  margin-top: auto;
  margin-bottom: auto;

  div {
    font-size: ${LABEL_CONTAINER_FONT_SIZE};
    cursor: default;
  }
`;

const _PickerWidget: StyledFC<PickerWidgetProps> = ({
  initialIndex,
  additionalButton,
  children,
  id,
  list,
  measureRef,
  nose,
  icons,
  onClick,
  onConfirm,
  className,
  dataCy
}) => {
  const [index, setIndex] = useState(initialIndex);

  const clickLeft = () => {
    const newIndex = index > 0 ? index - 1 : list.length - 1;
    if (onClick) {
      onClick(newIndex);
    }
    return setIndex(newIndex);
  };

  const clickRight = () => {
    const newIndex = index < list.length - 1 ? index + 1 : 0;
    if (onClick) {
      onClick(newIndex);
    }
    return setIndex(newIndex);
  };

  const onInputConfirm = () => {
    // when the nose is above the picker (up=true) the label is located below
    onConfirm && onConfirm(index, nose && !nose.up);
  };

  return (
    <div id={id} ref={measureRef} className={className} data-cy={dataCy}>
      {children}
      {nose?.up && (
        <NoseContainer>
          <Nose nose={nose} />
        </NoseContainer>
      )}
      <PickerWidgetContainer>
        <ButtonClickArea onClick={clickLeft} />
        <ButtonStepper position='left'>{icons.left}</ButtonStepper>
        <LabelContainer>
          <div>{list[index]}</div>
        </LabelContainer>
        <ButtonClickArea onClick={clickRight} />
        <ButtonStepper position='right'>{icons.right}</ButtonStepper>
        <ButtonConfirm onClick={onInputConfirm}>{icons.confirm}</ButtonConfirm>
        {additionalButton &&
          additionalButton.mode === AdditionalButton.trash && (
            <ButtonDelete onClick={additionalButton.onDelete}>
              {icons.delete}
            </ButtonDelete>
          )}
        {additionalButton &&
          additionalButton.mode === AdditionalButton.close && (
            <ButtonDelete onClick={additionalButton.onClose}>
              {icons.close}
            </ButtonDelete>
          )}
      </PickerWidgetContainer>
      {!nose?.up && (
        <NoseContainer>
          <Nose nose={nose} />
        </NoseContainer>
      )}
    </div>
  );
};

export const PickerWidget = styled(_PickerWidget)`
  vertical-align: middle;
  display: inline-block;
  pointer-events: all;
`;

PickerWidget.displayName = 'PickerWidget';
