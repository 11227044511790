import React from 'react';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';
import styled from 'styled-components';
import {StyledFC} from '../../types';
import {handleHover} from '../../utils/stylingHelpers';

interface InnerCircularContainerProps {
  enabled?: boolean;
  deleteStyle?: boolean;
}

interface CircularButtonProps extends InnerCircularContainerProps {
  onClick?: () => void;
  onMouseUp?: () => void;
  onMouseDown?: () => void;
}

const InnerCircularContainer = styled.div<InnerCircularContainerProps>`
  width: ${parseInt(dimensions.stepperBtnSize, 10) -
  2 * parseInt(dimensions.stepperPadding, 10)}px;
  height: ${parseInt(dimensions.stepperBtnSize, 10) -
  2 * parseInt(dimensions.stepperPadding, 10)}px;
  color: ${colors.cGray800};
  background-color: transparent;
  border: ${dimensions.borderWidthM} solid
    ${props => (props.deleteStyle ? colors.cErrorBorder : colors.cBlue700)};
  border-radius: ${dimensions.stepperBtnBorderRadius};
  outline: none;
  pointer-events: none;
  ${props => (!props.enabled ? `border-color: ${colors.cGray400};` : '')}

  svg {
    // center the svg
    display: block;
    margin: auto;
    height: 100%;
    fill: ${props =>
      props.deleteStyle ? colors.cErrorBorder : colors.cBlue700};
    ${props => (!props.enabled ? `fill: ${colors.cGray400};` : '')}
  }
`;

/**
 * The `CircularButton` is a component that...
 * ...wraps an SVG into a circular button with special hover + disabled styles
 * ...and a minimum clickable area
 *
 * ### Properties
 | Name               | Type           | Default   | Description                    |
 |---                 |---             |---        |---                             |
 | `enabled`          | `boolean`      | Required  | enabled?                       |
 | `onClick`          | `Function`     | Optional  | Callback for click interaction |
 | `deleteStyle`      | `boolean`      | Optional  | colored in red?                |
 */
const CircularButtonBase: StyledFC<CircularButtonProps> = ({
  className,
  dataCy,
  children,
  enabled = true,
  deleteStyle = false,
  onClick,
  onMouseDown,
  onMouseUp
}) => (
  <div
    className={className}
    data-cy={dataCy}
    role='button'
    onClick={enabled ? onClick : undefined}
    onMouseDown={enabled ? onMouseDown : undefined}
    onMouseUp={enabled ? onMouseUp : undefined}
  >
    <InnerCircularContainer enabled={enabled} deleteStyle={deleteStyle}>
      {children}
    </InnerCircularContainer>
  </div>
);

export const CircularButton = styled(CircularButtonBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  width: ${parseInt(dimensions.stepperBtnSize, 10) +
  2 * parseInt(dimensions.borderWidthM, 10)}px;
  height: ${dimensions.minClickableHeight};
  background-color: transparent;

  ${props =>
    props.enabled &&
    !props.deleteStyle &&
    handleHover(
      `
    background-color: ${colors.cBlue700};

    svg {
      fill: ${colors.cWhite};
    }
  `,
      InnerCircularContainer
    )}
`;
