import {AnimationVariants} from '../../types';

export const overlayVariants = {
  [AnimationVariants.hidden]: {opacity: 0},
  [AnimationVariants.visible]: {opacity: 1}
};

export const modalVariants = {
  [AnimationVariants.hidden]: {
    opacity: 0,
    translateY: 60
  },
  [AnimationVariants.visible]: {
    opacity: 1,
    translateY: 0
  }
};

export const overlayTransition = {duration: 0.45};
