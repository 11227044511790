import React from 'react';

export const CaretDownIcon: React.FC<React.SVGAttributes<SVGElement>> = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='8'
      viewBox='0 0 12 8'
    >
      <polygon
        fill='#666'
        points='9 17.363 9.725 16.637 15 21.913 20.275 16.637 21 17.363 15 23.363'
        transform='translate(-9 -16)'
      />
    </svg>
  );
};
