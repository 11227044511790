import {css, FlattenInterpolation} from 'styled-components';
import colors from '../../sass/colors';
import {ContextNotificationKind, ContextNotificationProps} from './types';

const actionCss = css<ContextNotificationProps>`
  ${p => !p.preserveFontColor && `color: ${colors.cBlue700};`}
  background-color: ${colors.cSelectedBackground};
  border-color: ${colors.cSelectedBorder};
`;

const attentionCss = css<ContextNotificationProps>`
  ${p => !p.preserveFontColor && `color: ${colors.cOrange700};`}
  background-color: ${colors.cOrange100};
  border-color: ${colors.cOrange600};
`;

const correctCss = css<ContextNotificationProps>`
  ${p => !p.preserveFontColor && `color: ${colors.cTextCorrect};`}
  background-color: ${colors.cCorrectBackground};
  border-color: ${colors.cCorrectBorder};
`;

const errorCss = css<ContextNotificationProps>`
  ${p => !p.preserveFontColor && `color: ${colors.cTextError};`}
  background-color: ${colors.cErrorBackground};
  border-color: ${colors.cErrorBorder};
`;

const neutralCss = css<ContextNotificationProps>`
  ${p => !p.preserveFontColor && `color: ${colors.cTextDark};`}
  background-color: ${colors.cNeutralBackground};
  border-color: ${colors.cNeutralBorder};
`;

const remarkCss = css<ContextNotificationProps>`
  ${p => !p.preserveFontColor && `color: ${colors.cTextRemark};`}
  background-color: ${colors.cRemarkBackground};
  border-color: ${colors.cRemarkBorder};
`;

const decorationMap: Record<
  ContextNotificationKind,
  FlattenInterpolation<ContextNotificationProps>
> = {
  [ContextNotificationKind.action]: actionCss,
  [ContextNotificationKind.attention]: attentionCss,
  [ContextNotificationKind.correct]: correctCss,
  [ContextNotificationKind.error]: errorCss,
  [ContextNotificationKind.neutral]: neutralCss,
  [ContextNotificationKind.remark]: remarkCss
};

export const applyDecoration = ({kind}: ContextNotificationProps) =>
  decorationMap[kind];
