import React from 'react';
import colors from '../../sass/colors';

export const DragHandleIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  fill
}) => {
  return (
    <svg
      width='24'
      height='15'
      viewBox='0 0 24 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.5 0H1.5C0.67157 0 0 0.67157 0 1.5C0 2.32843 0.67157 3 1.5 3H22.5C23.3284 3 24 2.32843 24 1.5C24 0.67157 23.3284 0 22.5 0Z'
        fill={fill || colors.cGray400}
      />
      <path
        d='M22.5 6H1.5C0.67157 6 0 6.67157 0 7.5C0 8.32843 0.67157 9 1.5 9H22.5C23.3284 9 24 8.32843 24 7.5C24 6.67157 23.3284 6 22.5 6Z'
        fill={fill || colors.cGray400}
      />
      <path
        d='M1.5 12H22.5C23.3284 12 24 12.6716 24 13.5C24 14.3284 23.3284 15 22.5 15H1.5C0.67157 15 0 14.3284 0 13.5C0 12.6716 0.67157 12 1.5 12Z'
        fill={fill || colors.cGray400}
      />
    </svg>
  );
};
