import React from 'react';

export const LibraryIcon: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg
    width='17'
    height='22'
    viewBox='0 0 17 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.3301 6.5H4.33008V12H13.3301V6.5ZM12.3301 11H5.33008V7.5H12.3301V11ZM1.58008 1H16.9201V0H1.58008C1.18225 0 0.800722 0.158035 0.519418 0.43934C0.238113 0.720644 0.0800781 1.10218 0.0800781 1.5L0.0800781 20.5C0.138582 20.9205 0.348782 21.305 0.671098 21.5813C0.993415 21.8576 1.40561 22.0065 1.83008 22H16.5801V2H1.70008C1.31008 2 1.08008 1.81 1.08008 1.5C1.08008 1.36739 1.13276 1.24021 1.22652 1.14645C1.32029 1.05268 1.44747 1 1.58008 1ZM1.70008 3H15.5801V21H1.83008C1.48008 21 1.08008 20.7 1.08008 20.5V2.9C1.28042 2.96482 1.48952 2.99855 1.70008 3Z'
      fill='#1188EE'
    />
  </svg>
);
