import React from 'react';
import colors from '../../sass/colors';

export const ShowPasswordIcon: React.FC<React.SVGAttributes<SVGElement>> = ({
  width,
  height,
  fill
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width || 24}
      height={height || 19}
      viewBox='0 0 24 19'
    >
      <g fill='none' fillRule='evenodd'>
        <path
          fill={fill || `${colors.cBlue600}`}
          d='M12.119,14.6821 C7.569,14.6821 3.602,10.7581 2.279,9.2921 C3.122,8.3581 5.039,6.4281 7.494,5.1491 C7.077,5.9051 6.839,6.7731 6.839,7.6971 C6.839,10.6131 9.203,12.9771 12.119,12.9771 C15.035,12.9771 17.399,10.6131 17.399,7.6971 C17.399,6.7731 17.161,5.9051 16.744,5.1491 C19.199,6.4281 21.116,8.3581 21.959,9.2921 C20.635,10.7591 16.668,14.6821 12.119,14.6821 L12.119,14.6821 Z M23.811,8.7481 C23.599,8.4781 18.548,2.1421 12.119,2.1421 C5.69,2.1421 0.639,8.4781 0.427,8.7481 L0,9.2921 L0.427,9.8351 C0.639,10.1051 5.69,16.4421 12.119,16.4421 C18.548,16.4421 23.599,10.1051 23.811,9.8351 L24.238,9.2921 L23.811,8.7481 Z'
        />
        <g transform='translate(-.871)'>
          <polygon
            fill={fill || colors.cBlue600}
            points='4.225 17.715 5.489 18.979 22.979 1.49 21.716 .225'
            mask='url(#show-b)'
          />
          <polygon
            fill='#FFF'
            points='20.491 -1 3 16.491 4.264 17.754 21.755 .264'
            mask='url(#show-b)'
          />
        </g>
      </g>
    </svg>
  );
};
