import React from 'react';
import styled from 'styled-components';
import {HUDIconContainer} from './HUDIconContainer';
import {StyledFC} from '../../types';

export interface CopyToClipBoardProps {
  renderTrigger: (_: () => void) => React.ReactNode;
  clipBoardId: string;
  value: string;
  callback?: () => void;
  timeout?: number;
}

export const copyToClipboard = (value: string, clipBoardId: string) => {
  const cb = document.getElementById(clipBoardId) as HTMLInputElement;
  cb.hidden = false;
  cb.value = value;
  cb.select();
  cb.setSelectionRange(0, 99999); /*For mobile devices*/
  document.execCommand('copy');
  cb.hidden = true;
};

const _CopyToClipBoard: StyledFC<CopyToClipBoardProps> = ({
  renderTrigger,
  callback,
  clipBoardId,
  value,
  children,
  className,
  timeout = 3500,
  dataCy
}) => {
  const [showHUD, setShowHUD] = React.useState(false);
  const timeoutRef = React.useRef<number>();

  React.useEffect(() => {
    if (showHUD) {
      timeoutRef.current = window.setTimeout(() => {
        setShowHUD(false);
      }, timeout);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [showHUD, timeout]);

  const handleClick = () => {
    copyToClipboard(value, clipBoardId);
    setShowHUD(v => !v);
    if (callback) {
      callback();
    }
  };

  return (
    <>
      {renderTrigger(handleClick)}
      <input id={clipBoardId} type='text' hidden />
      <HUDIconContainer show={showHUD} className={className} dataCy={dataCy}>
        {children}
      </HUDIconContainer>
    </>
  );
};

export const CopyToClipBoard = styled(_CopyToClipBoard)``;
CopyToClipBoard.displayName = 'CopyToClipBoard';
