import defaultTheme from '../../themes/defaultTheme';
import {numberFromStyles} from '../../utils/numberFromStyles';

export const STEPPER_PADDING = numberFromStyles(
  defaultTheme.dimensions.spaceXxs
);
export const STEPPER_BTN_CLICK_AREA = numberFromStyles('32px');
export const STEPPER_BTN_BORDER_RADIUS =
  3 * numberFromStyles(defaultTheme.dimensions.borderRadiusL);
export const ICON_SHIFT_IN_CLICK_AREA = '4px';
