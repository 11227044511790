import {transition} from '../../consts/animation';
import {motion} from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import {AnimationVariants, StyledFC} from '../../types';
import {addDataCy} from '../../utils';
import {variants} from './animations';

interface HUDIconContainerProps {
  show?: boolean;
}

const StyledHUDWrapper = styled(motion.div).attrs(addDataCy)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
`;

const StyledHUDIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0.6;
  background: #000000;
  border: 0 solid #979797;
  width: 125px;
  height: 125px;
  border-radius: 10px;
`;

export const HUDIconContainer: StyledFC<HUDIconContainerProps> = ({
  children,
  show,
  dataCy
}) => {
  return (
    <StyledHUDWrapper
      variants={variants}
      initial={AnimationVariants.hidden}
      animate={show ? AnimationVariants.visible : AnimationVariants.hidden}
      transition={transition}
      dataCy={dataCy}
    >
      <StyledHUDIconContainer>{children}</StyledHUDIconContainer>
    </StyledHUDWrapper>
  );
};
