import isEmpty from 'lodash/isEmpty';
import React from 'react';
import dimensions from '../../sass/dimensions';
import styled from 'styled-components';
import {StyledFC} from '../../types';
import {
  LINE_DASH_LENGTH,
  LINE_GAP_LENGTH,
  LINE_SVG_HEIGHT,
  LINE_Y_SHIFT,
  NO_OF_DASHES
} from './constants';
import {LineStrokeWidth, LineStyle, LineWeight} from './types';

export interface ContentHeadlineProps {
  /**
   * The optional headline text. **Only use children of type `string`!**
   */
  children?: string;
  lineStyle: LineStyle;
  lineWeight: LineWeight;
  /** the maximum width to use */
  availableWidth?: number;
}

const Line = styled.line.attrs(({x2 = '100%', ...rest}) => ({
  x1: '0',
  x2,
  ...rest
}))`
  stroke: currentColor;
`;

/**
 * The `ContentHeadline` component will create dashed or solid headline with optional text.
 *
 * ### Process State
 *
 * - Visuals: Draft from DEV
 * - Behaviour: none
 */
const _ContentHeadline: StyledFC<ContentHeadlineProps> = ({
  children,
  lineStyle,
  lineWeight,
  availableWidth,
  className,
  dataCy
}) => {
  // If the linestyle is dash, dashArray defines dash length and gap
  const strokeDasharray =
    lineStyle === LineStyle.dashed
      ? `${LINE_DASH_LENGTH}, ${LINE_GAP_LENGTH}`
      : 'none';

  const lineVerticalShift = lineWeight === LineWeight.medium ? 0 : LINE_Y_SHIFT;

  const lineY = LINE_SVG_HEIGHT * LINE_Y_SHIFT + lineVerticalShift;

  const strokeWidth =
    lineWeight === LineWeight.thin
      ? LineStrokeWidth.thin
      : lineWeight === LineWeight.medium
      ? LineStrokeWidth.medium
      : LineStrokeWidth.thick;

  const fullWidthLine = (
    <svg width='100%' height={LINE_SVG_HEIGHT}>
      <Line
        y1={lineY}
        y2={lineY}
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDasharray}
      />
    </svg>
  );

  if (isEmpty(children)) {
    return (
      <div className={className} data-cy={dataCy}>
        {fullWidthLine}
      </div>
    );
  } else {
    const frontLineWidth =
      NO_OF_DASHES * LINE_DASH_LENGTH + (NO_OF_DASHES - 1) * LINE_GAP_LENGTH;
    return (
      <div
        className={className}
        data-cy={dataCy}
        style={{width: (availableWidth as number) - 2 * strokeWidth}}
      >
        <svg width={frontLineWidth} height={LINE_SVG_HEIGHT}>
          <Line
            x2={frontLineWidth}
            y1={lineY}
            y2={lineY}
            strokeWidth={strokeWidth}
            strokeDasharray={strokeDasharray}
          />
        </svg>
        <span className='text'>{children}</span>
        {fullWidthLine}
      </div>
    );
  }
};

export const ContentHeadline = styled(_ContentHeadline)`
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;

  .text {
    padding: 0 5px;
    font-size: ${dimensions.fontSizeM};
  }
`;

ContentHeadline.displayName = 'ContentHeadline';
