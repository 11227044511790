import React from 'react';
import colors from '../../sass/colors';

export const RemoveCalendarItemIcon: React.FC<React.SVGAttributes<SVGElement>> =
  ({fill}) => (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.2288 0V2.00375H27.3013V28H0V2.00375H8.08125V0H10.1112V2.00375H17.1987V0H19.2288ZM1.11125 26.9062H26.2075V7.98H1.11125V26.9062ZM1.11125 7.02625H26.2075V3.10625H19.2288V5.01375H17.1987V3.10625H10.1112V5.01375H8.09875V3.10625H1.11125V7.02625ZM8.55026 13.2574L12.7929 17.5L8.55026 21.7426L9.25736 22.4497L13.5 18.2071L17.7426 22.4497L18.4498 21.7426L14.2071 17.5L18.4498 13.2574L17.7426 12.5503L13.5 16.7929L9.25736 12.5503L8.55026 13.2574Z'
        fill={fill || `${colors.cBlue700}`}
      />
    </svg>
  );
