import React from 'react';

export const SearchIcon: React.FC<React.SVGAttributes<SVGElement>> = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='20'
      viewBox='0 0 21 20'
    >
      <path
        fill='#4AF'
        d='M448.242641,33.900603 L442.483528,28.1414122 C445.081782,24.9879401 444.909032,20.3016923 441.961387,17.3536277 C440.44321,15.8356351 438.423902,15 436.276977,15 C434.130052,15 432.110744,15.8356351 430.592567,17.3536277 C427.458943,20.4884264 427.459721,25.5870453 430.592567,28.7202879 C432.110744,30.2382805 434.130052,31.0739156 436.276977,31.0739156 C438.162442,31.0739156 439.948304,30.4281268 441.383997,29.2423653 L447.141554,35 L448.242641,33.900603 Z M431.692875,27.6201128 C429.166211,25.092978 429.166211,20.9809376 431.692875,18.4538028 C432.916911,17.2306944 434.545586,16.5561175 436.276977,16.5561175 C438.008368,16.5561175 439.636265,17.2306944 440.861078,18.4538028 C443.387742,20.9809376 443.387742,25.092978 440.861078,27.6201128 C439.637043,28.8432212 438.008368,29.5177981 436.276977,29.5177981 C434.545586,29.5177981 432.916911,28.8432212 431.692875,27.6201128 Z'
        transform='translate(-427.5 -15)'
      />
    </svg>
  );
};
