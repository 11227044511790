import {AnimationVariants} from '../../types';

export const validationMessageVariants = {
  [AnimationVariants.visible]: {
    height: 'auto',
    opacity: 1
  },
  [AnimationVariants.hidden]: {
    height: 0,
    opacity: 0
  }
};

export const getDecorationBorderVariants = (
  visibleBorderColor: string,
  hiddenBorderColor: string
) => ({
  [AnimationVariants.visible]: {borderColor: visibleBorderColor},
  [AnimationVariants.hidden]: {borderColor: hiddenBorderColor}
});
