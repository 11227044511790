import styled, {css} from 'styled-components';
import dimensions from '../../sass/dimensions';
import colors from '../../sass/colors';
import {getStretchCssValue, numberFromStyles, Stretch} from '../../utils';

interface HeaderWrapperProps {
  hasShadow: boolean;
}

export const HeaderWrapper = styled.div<HeaderWrapperProps>`
  z-index: 4;
  border-bottom: ${dimensions.borderWidthS} solid ${colors.cGray500};
  position: relative;
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.2);
    transition: opacity 0.25s ease-in-out;
    ${p => (p.hasShadow ? 'opacity: 1;' : '')}
  }
`;
HeaderWrapper.displayName = 'HeaderWrapper';

interface ListProps {
  withHeader: boolean;
  height?: Stretch | number;
}

const LIST_HEIGHT_WHEN_HEADER =
  numberFromStyles(dimensions.minClickableHeight) + 5;

export const List = styled.ul<ListProps>`
  z-index: 3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  box-sizing: border-box;
  ${p =>
    p.height
      ? css`
          height: calc(
            ${getStretchCssValue(p.height)} -
              ${p.withHeader ? `${LIST_HEIGHT_WHEN_HEADER}px` : '0px'}
          );
        `
      : ''}
`;
List.displayName = 'List';
