import React from 'react';
import styled from 'styled-components';
import {FlexAlign, FlexWrap, StyledFC} from '../../types';
import {HLayoutContext} from './context';
import {LayoutChildWrapperProps, LayoutDecoration} from './types';

const DEFAULT_GAP = 8;

export type HLayoutProps = {
  align?: FlexAlign;
  gap?: number;
  decorations?: LayoutDecoration[];
  wrap?: FlexWrap;
};

export const HLayout: StyledFC<HLayoutProps> = ({
  className,
  dataCy,
  children,
  gap = DEFAULT_GAP
}) => (
  // need this extra wrapper div to be able to use negative margins
  // need to use negative margins
  //
  // - for line wrapping children that appear at beginning of next line
  //   |----| margin |---| margin |----|
  //   NO margin |----| margin |--|
  //
  // - for the gap between the lines that appear after wrapping
  //   -------line--------------
  //   _______bottom margin_____
  //   -------line--------------
  //   .......NO bottom margin..
  <div>
    <div className={className} data-cy={dataCy}>
      <HLayoutContext.Provider value={gap}>{children}</HLayoutContext.Provider>
    </div>
  </div>
);

export const HLayoutChildWrapper: StyledFC<LayoutChildWrapperProps> = ({
  children,
  decoration
}) => {
  const margin = React.useContext<number>(HLayoutContext);

  return (
    <div style={{...decoration, marginLeft: margin, marginBottom: margin}}>
      {children}
    </div>
  );
};

export const StyledHLayout = styled(HLayout)`
  display: flex;
  flex-direction: row;
  align-items: ${props => props.align || FlexAlign.flexStart};
  margin-left: -${props => props.gap}px;
  margin-bottom: -${props => props.gap}px;
  flex-wrap: ${props => props.wrap || FlexWrap.WRAP};
`;
