import {ButtonBackground} from '../types';
import {compose, defaultTo, get, negate} from 'lodash/fp';

// Maybe we can still consider using ramdajs instead of lodash since
// ramdajs has eq, or and many more other built-in functions
// ramdajs compose typing issue has to be checked before usage.
export const eq =
  <T>(a: T) =>
  (b: T) =>
    a === b;
export const or = (left: any, right: any) => (cond: boolean) =>
  cond ? left : right;
export const orEmpty = (left: any) => or(left, '');

export const isFilled = eq<ButtonBackground>(ButtonBackground.filled);
export const isTransparent = eq<ButtonBackground>(ButtonBackground.transparent);
export const isBorder = eq<ButtonBackground>(ButtonBackground.border);
export const isNotTransparent = negate(isTransparent);
export const getBackgroundType = compose(
  defaultTo(ButtonBackground.filled),
  get('background')
);

export const resolveColors = (
  orFn: typeof or,
  predicate: (_: any) => boolean
) => compose(orFn, predicate, getBackgroundType);
export const resolveCursor = compose(
  orEmpty('cursor: default;'),
  defaultTo(false),
  get('disabled')
);

export const applyCssForBorderType = (css: string) =>
  compose(orEmpty(css), isBorder, getBackgroundType);
