import React from 'react';
import styled from 'styled-components';
import {StyledFC} from 'types';
import dimensions from '../../sass/dimensions';
import colors from '../../sass/colors';

export enum ToolBarType {
  Primary = 'Primary',
  Secondary = 'Secondary'
}

interface ToolBarProps {
  toolBarType: ToolBarType;
  height?: string;
  customPadding?: string;
  leftArea?: any;
  middleArea?: any;
  rightArea?: any;
}

const SideArea = styled.div`
  /**
    This helper container makes sure the MiddleArea always stays centered in the middle relative to its parent container without the SideAreas (Left, Right) affecting its position
   */
  height: 100%;
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
`;

const LeftArea = styled(SideArea)`
  justify-content: flex-start;
`;

const MiddleArea = styled.div`
  /**
    this is to leave some whitespace left and right when the Navigation shrinks
  */
  height: 100%;
  margin: 0 ${dimensions.spaceM};
  align-items: center;
  display: flex;
`;

const RightArea = styled(SideArea)`
  justify-content: flex-end;
`;

const _ToolBar: StyledFC<ToolBarProps> = ({
  className,
  leftArea,
  middleArea,
  rightArea
}) => {
  return (
    <div className={className}>
      {leftArea && <LeftArea>{leftArea}</LeftArea>}
      {middleArea && <MiddleArea>{middleArea}</MiddleArea>}
      {rightArea && <RightArea>{rightArea}</RightArea>}
    </div>
  );
};

export const ToolBar = styled(_ToolBar)`
  padding: ${p => p.customPadding || `0 ${dimensions.spaceM}`};
  height: ${p => p.height || '60px'};
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 2px solid
    ${p => {
      switch (p.toolBarType) {
        case ToolBarType.Primary:
          return colors.cBlue700;
        case ToolBarType.Secondary:
          return colors.cGray600;
        default:
          return colors.cGray600;
      }
    }};
`;
