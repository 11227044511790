export interface TileProps {
  color: any;
  title: string;
  maxTileWidth?: string;
}

export interface ModuleProps extends TileProps {
  id: string;
  modalTitle?: string;
  image?: string;
  onOpenModuleDetails: ModuleDetailOpenHandler;
  onToggleActionTip: (
    id: string,
    isOen: boolean,
    stateChangeFn: (isOen: boolean) => void
  ) => void;
  onOpenModule: (id: string) => void;
  onAddToPlanning: () => void;
  onOpenPlanning: (event: Event) => void;
  customPreviewIcon?: React.ReactSVGElement;
  customAddToPlaningIcon?: React.ReactSVGElement;
  url?: string;
  isPlanned?: boolean;
}

export enum ButtonLayout {
  row = 'row',
  column = 'column'
}

export enum TipPosition {
  left = 'left',
  center = 'center',
  right = 'right'
}

export interface ActionTipProps {
  dialogue: string;
  activeTip: boolean;
  closeLabel: string;
  onCloseTip: (e: Event) => void | boolean;
  actionButton: any;
  alternateButtonLayout?: boolean;
  buttonLayout?: ButtonLayout;
  tipPosition?: TipPosition | number;
}

export interface ModuleDetailProps {
  objectives: TileTextItem[];
  preknowledge: TileTextItem[];
  examples: TileImageItem[];
}

export type ModuleDetailOpenHandler = (
  id: string,
  updateFn: ({
    objectives,
    preknowledge,
    examples
  }: ModuleDetailProps) => () => void
) => void;

export interface DetailProps extends ModuleProps {
  isActive?: boolean;
  detailWidth?: string;
  isPlanned?: boolean;
  onClose: (...args: any[]) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void | boolean;
  objectives: TileTextItem[];
  preknowledge: TileTextItem[];
  examples: TileImageItem[];
  context?: 'module' | 'modal';
}

export interface LibraryTileProps {
  isActive?: boolean;
  icon?: any;
  iconPadding?: boolean;
  iconWidth?: number;
  title: string;
  themeCount: number;
  classRange?: string;
  meta?: string;
  beta?: boolean;
}

export interface TileTextItem {
  label: string;
}

export interface TileImageItem {
  image: string;
  alt?: string;
}

export interface TileTextItem {
  label: string;
}

export interface TileImageItem {
  image: string;
  alt?: string;
}
