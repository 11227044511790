import {motion} from 'framer-motion';
import React from 'react';
import colors from '../../sass/colors';
import styled from 'styled-components';
import {AnimationVariants, StyledFC, ValidationType} from '../../types';
import {
  getDecorationBorderVariants,
  validationMessageVariants
} from './animations';
import {BoxedInput, BoxedInputProps} from './BoxedInput';
import {FormValidationMessageExtended} from './FormValidationMessageExtended';
import {typeBoxBorderColorMap} from './styleMaps';
import {ValidationDecorationBorder} from './ValidationDecorationBorder';

const _BoxedInputCombined: StyledFC<BoxedInputProps> = ({
  className,
  dataCy,
  innerDataCyMap = {},
  ...props
}) => {
  const currentAnimationVariant = props.validationType
    ? AnimationVariants.visible
    : AnimationVariants.hidden;

  const decorationBorderVariants = React.useMemo(
    () =>
      getDecorationBorderVariants(
        typeBoxBorderColorMap[props.validationType || ValidationType.error],
        colors.cGray600
      ),
    [props.validationType]
  );

  return (
    <ValidationDecorationBorder
      validationType={props.validationType}
      variants={decorationBorderVariants}
      initial={AnimationVariants.hidden}
      animate={currentAnimationVariant}
      className={className}
      dataCy={dataCy}
    >
      <BoxedInput
        {...props}
        dataCy={innerDataCyMap[BoxedInput.displayName as string]}
      />
      <motion.div
        variants={validationMessageVariants}
        initial={AnimationVariants.hidden}
        animate={currentAnimationVariant}
      >
        <FormValidationMessageExtended
          validationType={props.validationType}
          dataCy={
            innerDataCyMap[FormValidationMessageExtended.displayName as string]
          }
        >
          {props.children}
        </FormValidationMessageExtended>
      </motion.div>
    </ValidationDecorationBorder>
  );
};

export const BoxedInputCombined = styled(_BoxedInputCombined)``;
BoxedInputCombined.displayName = 'BoxedInputCombined';
