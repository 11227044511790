import React from 'react';

export const useIsScrolled = (
  scrollableElementRef: React.RefObject<HTMLElement>
): [boolean, () => void] => {
  const [isScrolled, setIsScrolled] = React.useState<boolean>(false);

  const handleIsScrolled = () => {
    if (scrollableElementRef.current) {
      setIsScrolled(scrollableElementRef.current.scrollTop > 0);
    }
  };

  return [isScrolled, handleIsScrolled];
};
