import React from 'react';
import styled, {css} from 'styled-components';
import {StyledFC} from 'types';

import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';

export enum OptionStyle {
  Invisible = 'Invisible',
  Border = 'Border'
}

export enum OptionType {
  Circle = 'Circle',
  Tag = 'Tag'
}

export interface OptionSwitcherItem {
  label: string;
  parent?: string;
  active?: boolean;
  disabled?: boolean;
}
export interface OptionSwitcherProps {
  optionDefault: string;
  onClick: (item: OptionSwitcherItem) => void;
  optionItems: OptionSwitcherItem[];
  border?: boolean;
  spacing?: string;
}

export interface OptionProps {
  label: string;
  onClick: (item: OptionSwitcherItem) => void;
  active?: boolean;
  disabled?: boolean;
  border?: boolean;
}

const OptSpace = '6px';

const _Option: StyledFC<OptionProps> = ({className, label, onClick}) => {
  return (
    <div className={className} onClick={() => onClick({label})}>
      {label}
    </div>
  );
};

const Option = styled(_Option)`
  cursor: pointer;
  display: inline-flex;
  border-radius: 24px;
  border: ${p => (p.border ? ` 1px solid  ${colors.cBlue700}` : ' none')};

  margin: ${OptSpace};
  padding: ${p => (p.border ? `5.5px 10px` : `5.5px ${dimensions.spaceS}`)};
  text-align: center;
  ${p =>
    !p.active &&
    p.disabled &&
    css`
      border-color: ${colors.cGray500};
      color: ${colors.cGray500};
    `};

  ${p =>
    p.active &&
    css`
      background: ${colors.cBlue700};
      color: ${colors.cWhite};
    `};

  ${p =>
    !p.active &&
    !p.disabled &&
    css`
      color: ${colors.cBlue700};
    `};

  ${p =>
    p.border &&
    css`
      min-width: 32px;
    `};
`;

const _OptionSwitcher: StyledFC<OptionSwitcherProps> = ({
  className,
  optionDefault,
  optionItems,
  border,
  onClick
}) => {
  return (
    <div className={className}>
      <Option
        label={optionDefault}
        active={!optionItems.some(i => i.active)}
        onClick={() => onClick && onClick({label: optionDefault})}
      />
      {optionItems.map((item: OptionSwitcherItem, idx: number) => (
        <Option
          key={idx}
          label={item.label}
          active={item.active}
          onClick={() => onClick && onClick(item)}
          border={border}
          disabled={item.disabled}
        />
      ))}
    </div>
  );
};

export const OptionSwitcher = styled(_OptionSwitcher)`
  margin: ${p => (p.spacing ? `0 0 ${p.spacing}` : '0')};
`;
