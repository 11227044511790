import React from 'react';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import {StyledFC} from 'types';

import dimensions from '../../sass/dimensions';
import colors from '../../sass/colors';

import {RowItemProps} from './types';
import {
  DocumentPreviewIcon,
  DragHandleIcon,
  HidePasswordIcon,
  RemoveCalendarItemIcon,
  ShowPasswordIcon
} from '../Icons';

const ActionButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  cursor: pointer;
  background: none;
  padding: 0;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Column = styled(Row)`
  flex-direction: column;
`;

const Details = styled(Column)`
  flex: 1 1 auto;
  justify-content: center;
  margin: 0 ${dimensions.spaceM};
`;

const Topic = styled.h6<Pick<RowItemProps, 'visible'>>`
  font-weight: 400;
  font-size: ${dimensions.fontSizeM};
  font-style: ${p => (p.visible ? 'normal' : 'italic')};
  color: ${colors.cGray800};
  margin: 0;
`;

const SuperTopic = styled.span`
  font-size: ${dimensions.fontSizeS};
  color: ${colors.cGray600};
`;

const Icon = styled.span<Pick<RowItemProps, 'color' | 'visible'>>`
  flex: 0 0 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p =>
    p.color ? hexToRgba(p.color, 0.2) : colors.cGray400};
  img {
    width: 50px;
    height: 50px;
  }
  opacity: ${p => (p.visible ? 1 : 0.5)};
  transition: all 0.25s ease-in-out;
`;

const Actions = styled(Row)`
  flex: 0 0 auto;
  ${ActionButton} + ${ActionButton} {
    margin: 0 0 0 ${dimensions.spaceL};
  }
`;

const _RowItem: StyledFC<RowItemProps> = ({
  className,
  icon,
  topic,
  supertopic,
  color,
  visible,
  actionPreview,
  actionRemove,
  actionSort
}) => {
  const setVisible = (v: boolean) => {
    //
  };

  return (
    <li className={className}>
      <ActionButton onClick={() => setVisible(true)}>
        {visible ? (
          <HidePasswordIcon width={32} height={24} fill={colors.cBlue700} />
        ) : (
          <ShowPasswordIcon width={32} height={24} fill={colors.cBlue700} />
        )}
      </ActionButton>
      <Icon color={color} visible={visible}>
        <img src={icon} alt={topic} />
      </Icon>
      <Details>
        <Topic visible={visible}>{topic}</Topic>
        <SuperTopic>{supertopic}</SuperTopic>
      </Details>
      <Actions>
        <ActionButton onClick={actionPreview}>
          <DocumentPreviewIcon />
        </ActionButton>
        <ActionButton onClick={actionRemove}>
          <RemoveCalendarItemIcon />
        </ActionButton>
        <ActionButton onClick={actionSort}>
          <DragHandleIcon />
        </ActionButton>
      </Actions>
    </li>
  );
};

export const RowItem = styled(_RowItem)`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  padding: ${dimensions.spaceS};
  box-shadow: 0px -1px 0px 0px ${colors.cGray400} inset;
  ${Topic} + ${SuperTopic} {
    margin: ${dimensions.spaceXxs} 0 0;
  }
  ${Icon} {
    margin-left: ${dimensions.spaceM};
  }
`;

RowItem.displayName = 'RowItem';
