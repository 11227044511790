import React from 'react';

export const ReportIcon: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg
    width='23'
    height='18'
    viewBox='0 0 23 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.25 17V12.26L7.63 7.7L11.13 12.71L19.05 6.9L18.45 6.1L11.37 11.29L7.87 6.3L1.25 11.03V0H0.25V18H22.75V17H1.25Z'
      fill='#1188EE'
    />
  </svg>
);
