import React from 'react';

export const SmallLoadingAnimationIcon: React.FC<
  React.SVGAttributes<SVGElement>
> = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='20'
      viewBox='0 0 21 20'
    >
      <g fill='none' transform='translate(.5)'>
        <path
          fill='#E6E6E6'
          d='M10,0 C4.47511678,0 0,4.47501372 0,10 C0,15.5247847 4.47521525,20 10,20 C15.5245832,20 20,15.5246863 20,10 C20,4.47511219 15.5246817,0 10,0 Z M10,2 C14.4201328,2 18,5.57970231 18,10 C18,14.4201065 14.420024,18 10,18 C5.57978475,18 2,14.4202153 2,10 C2,5.57959353 5.57967597,2 10,2 Z'
        />
        <path
          fill='#4AF'
          d='M10,0 C10.5522847,0 11,0.44771525 11,1 C11,1.55228475 10.5522847,2 10,2 C7.69313254,2 5.54846803,2.97941188 4.03895043,4.66453147 C3.67044866,5.07590101 3.03823806,5.1106522 2.62686853,4.74215043 C2.21549899,4.37364866 2.1807478,3.74143806 2.54924957,3.33006853 C4.43423213,1.22580618 7.1184379,0 10,0 Z'
        />
      </g>
    </svg>
  );
};
